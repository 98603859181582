.theme-purple ::selection {
  color: #fff;
  background: #6462ef;
}
.theme-purple .page-loader-wrapper {
  background: #6462ef;
}
.theme-purple .navbar-fixed-top {
  background: #6462ef;
}
.theme-purple .navbar-fixed-top .form-control {
  border: 0;
}
.theme-purple .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-purple .sidebar-nav .metismenu > li.active > a {
  background: #6462ef;
  color: #fff;
}
.theme-purple .sidebar-nav .metismenu a {
  color: #292c3f;
}
.theme-purple .sidebar-nav .metismenu a:hover,
.theme-purple .sidebar-nav .metismenu a:focus {
  background: #292c3f;
  color: #e8e8e8;
}
.theme-purple #left-sidebar {
  background: #fff;
}
.theme-purple #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-purple #left-sidebar .navbar-brand span {
  color: #6462ef;
}
.theme-purple #left-sidebar .user-account {
  color: #292c3f;
}
.theme-purple #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-purple #left-sidebar .user-account .user_div .user-photo {
  border-color: #6462ef;
}
.theme-purple #left-sidebar .user-account .dropdown-menu {
  background: #6462ef;
}
.theme-purple #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-purple .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #6462ef;
  border-color: #6462ef;
}
.theme-purple .chat-widget li.right .chat-info {
  background: #6462ef;
  color: #fff;
}
.theme-purple .chat-widget li.right .chat-info:before {
  border-left: 10px solid #6462ef;
}
.theme-purple .right_chat li a:hover .media-object {
  border-color: #6462ef;
}
.theme-purple .auth-main:after {
  background: #6462ef;
}
.theme-purple .auth-main:before {
  background: #17191c;
}
.theme-purple .wizard > .steps .current a {
  background: #6462ef;
}
.theme-purple .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-purple .wizard .actions a {
  background: #00bcd2;
}
.theme-purple
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #6462ef;
  border-color: #6462ef;
  color: #fff;
}
.theme-purple .maintenance {
  background: #6462ef;
}
.theme-purple .maintenance > div {
  background: #fff;
}
.theme-purple .noUi-connect {
  background: #6462ef;
}
.theme-purple .blog-page .single_post .footer .stats li a:hover {
  color: #6462ef;
}
.theme-purple .ct-series-a .ct-line,
.theme-purple .ct-series-a .ct-bar,
.theme-purple .ct-series-a .ct-point {
  stroke: #292c3f;
}
.theme-purple .ct-series-a .ct-area,
.theme-purple .ct-series-a .ct-slice-donut-solid,
.theme-purple .ct-series-a .ct-slice-pie,
.theme-purple .ct-series-a .ct-bar {
  fill: #292c3f;
}
.theme-purple .ct-series-b .ct-line,
.theme-purple .ct-series-b .ct-bar,
.theme-purple .ct-series-b .ct-point {
  stroke: #6462ef;
}
.theme-purple .ct-series-b .ct-area,
.theme-purple .ct-series-b .ct-slice-donut-solid,
.theme-purple .ct-series-b .ct-slice-pie,
.theme-purple .ct-series-b .ct-bar {
  fill: #6462ef;
}
.theme-purple .ct-series-c .ct-line,
.theme-purple .ct-series-c .ct-bar,
.theme-purple .ct-series-c .ct-point {
  stroke: #8f8de7;
}
.theme-purple .ct-series-c .ct-area,
.theme-purple .ct-series-c .ct-slice-donut-solid,
.theme-purple .ct-series-c .ct-slice-pie,
.theme-purple .ct-series-c .ct-bar {
  fill: #8f8de7;
}
.theme-purple .ct-series-d .ct-line,
.theme-purple .ct-series-d .ct-bar,
.theme-purple .ct-series-d .ct-point {
  stroke: #b4b3e4;
}
.theme-purple .ct-series-d .ct-area,
.theme-purple .ct-series-d .ct-slice-donut-solid,
.theme-purple .ct-series-d .ct-slice-pie,
.theme-purple .ct-series-d .ct-bar {
  fill: #b4b3e4;
}
.theme-purple .ct-series-e .ct-line,
.theme-purple .ct-series-e .ct-bar,
.theme-purple .ct-series-e .ct-point {
  stroke: #00bcd2;
}
.theme-purple .ct-series-e .ct-area,
.theme-purple .ct-series-e .ct-slice-donut-solid,
.theme-purple .ct-series-e .ct-slice-pie,
.theme-purple .ct-series-e .ct-bar {
  fill: #00bcd2;
}
.theme-purple .ct-series-f .ct-line,
.theme-purple .ct-series-f .ct-bar,
.theme-purple .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-purple .ct-series-f .ct-area,
.theme-purple .ct-series-f .ct-slice-donut-solid,
.theme-purple .ct-series-f .ct-slice-pie,
.theme-purple .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-purple .ct-legend .ct-series-0:before {
  background-color: #292c3f;
  border-color: #292c3f;
}
.theme-purple .ct-legend .ct-series-1:before {
  background-color: #6462ef;
  border-color: #6462ef;
}
.theme-purple .ct-legend .ct-series-2:before {
  background-color: #8f8de7;
  border-color: #8f8de7;
}
.theme-purple .ct-legend .ct-series-3:before {
  background-color: #b4b3e4;
  border-color: #b4b3e4;
}
.theme-purple .ct-legend .ct-series-4:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-purple .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-blue ::selection {
  color: #fff;
  background: #0e9be2;
}
.theme-blue .page-loader-wrapper {
  background: #0e9be2;
}
.theme-blue .navbar-fixed-top {
  background: #0e9be2;
}
.theme-blue .navbar-fixed-top .form-control {
  border: 0;
}
.theme-blue .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li.active > a {
  background: #ffaf1d;
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li i {
  color: #9da1a7;
}
.theme-blue .sidebar-nav .metismenu > li ul li.active a {
  color: #0e9be2;
}
.theme-blue .sidebar-nav .metismenu a {
  color: #9da1a7;
}
.theme-blue .sidebar-nav .metismenu a:hover,
.theme-blue .sidebar-nav .metismenu a:focus {
  background: #2d3435;
}
.theme-blue .sidebar-nav .metismenu .has-arrow:after {
  color: #ffaf1d;
}
.theme-blue #left-sidebar {
  background: #303844;
}
.theme-blue #left-sidebar .user-account .user_div .user-photo {
  border-color: #ffaf1d;
}
.theme-blue .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #0e9be2;
  border-color: #0e9be2;
}
.theme-blue .chat-widget li.right .chat-info {
  background: #0e9be2;
  color: #fff;
}
.theme-blue .chat-widget li.right .chat-info:before {
  border-left: 10px solid #0e9be2;
}
.theme-blue .right_chat li a:hover .media-object {
  border-color: #0e9be2;
}
.theme-blue
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #0e9be2;
  border-color: #0e9be2;
  color: #fff;
}
.theme-blue .auth-main:after {
  background: #0e9be2;
}
.theme-blue .auth-main:before {
  background: #303844;
}
.theme-blue .wizard > .steps .current a {
  background: #0e9be2;
}
.theme-blue .wizard > .steps .done a {
  background: #9da1a7;
}
.theme-blue .wizard .actions a {
  background: #ffaf1d;
}
.theme-blue .maintenance {
  background: #0e9be2;
}
.theme-blue .maintenance > div {
  background: #fff;
}
.theme-blue .noUi-connect {
  background: #0e9be2;
}
.theme-blue .blog-page .single_post .footer .stats li a:hover {
  color: #0e9be2;
}
.theme-blue .ct-series-a .ct-line,
.theme-blue .ct-series-a .ct-bar,
.theme-blue .ct-series-a .ct-point {
  stroke: #303844;
}
.theme-blue .ct-series-a .ct-area,
.theme-blue .ct-series-a .ct-slice-donut-solid,
.theme-blue .ct-series-a .ct-slice-pie,
.theme-blue .ct-series-a .ct-bar {
  fill: #303844;
}
.theme-blue .ct-series-b .ct-line,
.theme-blue .ct-series-b .ct-bar,
.theme-blue .ct-series-b .ct-point {
  stroke: #0e9be2;
}
.theme-blue .ct-series-b .ct-area,
.theme-blue .ct-series-b .ct-slice-donut-solid,
.theme-blue .ct-series-b .ct-slice-pie,
.theme-blue .ct-series-b .ct-bar {
  fill: #0e9be2;
}
.theme-blue .ct-series-c .ct-line,
.theme-blue .ct-series-c .ct-bar,
.theme-blue .ct-series-c .ct-point {
  stroke: #ffaf1d;
}
.theme-blue .ct-series-c .ct-area,
.theme-blue .ct-series-c .ct-slice-donut-solid,
.theme-blue .ct-series-c .ct-slice-pie,
.theme-blue .ct-series-c .ct-bar {
  fill: #ffaf1d;
}
.theme-blue .ct-series-d .ct-line,
.theme-blue .ct-series-d .ct-bar,
.theme-blue .ct-series-d .ct-point {
  stroke: #02b5b2;
}
.theme-blue .ct-series-d .ct-area,
.theme-blue .ct-series-d .ct-slice-donut-solid,
.theme-blue .ct-series-d .ct-slice-pie,
.theme-blue .ct-series-d .ct-bar {
  fill: #02b5b2;
}
.theme-blue .ct-series-e .ct-line,
.theme-blue .ct-series-e .ct-bar,
.theme-blue .ct-series-e .ct-point {
  stroke: #767ee9;
}
.theme-blue .ct-series-e .ct-area,
.theme-blue .ct-series-e .ct-slice-donut-solid,
.theme-blue .ct-series-e .ct-slice-pie,
.theme-blue .ct-series-e .ct-bar {
  fill: #767ee9;
}
.theme-blue .ct-series-f .ct-line,
.theme-blue .ct-series-f .ct-bar,
.theme-blue .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-blue .ct-series-f .ct-area,
.theme-blue .ct-series-f .ct-slice-donut-solid,
.theme-blue .ct-series-f .ct-slice-pie,
.theme-blue .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-blue .ct-legend .ct-series-0:before {
  background-color: #303844;
  border-color: #303844;
}
.theme-blue .ct-legend .ct-series-1:before {
  background-color: #0e9be2;
  border-color: #0e9be2;
}
.theme-blue .ct-legend .ct-series-2:before {
  background-color: #ffaf1d;
  border-color: #ffaf1d;
}
.theme-blue .ct-legend .ct-series-3:before {
  background-color: #02b5b2;
  border-color: #02b5b2;
}
.theme-blue .ct-legend .ct-series-4:before {
  background-color: #767ee9;
  border-color: #767ee9;
}
.theme-blue .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-cyan ::selection {
  color: #fff;
  background: #00bcd2;
}
.theme-cyan .page-loader-wrapper {
  background: #00bcd2;
}
.theme-cyan .navbar-fixed-top {
  background: #00bcd2;
}
.theme-cyan .navbar-fixed-top .form-control {
  border: 0;
}
.theme-cyan .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a {
  background: #00bcd2;
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li i {
  color: #6f7b7d;
}
.theme-cyan .sidebar-nav .metismenu > li ul li.active a {
  color: #00bcd2;
}
.theme-cyan .sidebar-nav .metismenu a {
  color: #6f7b7d;
}
.theme-cyan .sidebar-nav .metismenu a:hover,
.theme-cyan .sidebar-nav .metismenu a:focus {
  background: #2d3435;
}
.theme-cyan #left-sidebar {
  background: #fff;
}
.theme-cyan #left-sidebar .user-account .user_div .user-photo {
  border-color: #00bcd2;
}
.theme-cyan .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #00bcd2;
  border-color: #00bcd2;
}
.theme-cyan .chat-widget li.right .chat-info {
  background: #00bcd2;
  color: #fff;
}
.theme-cyan .chat-widget li.right .chat-info:before {
  border-left: 10px solid #00bcd2;
}
.theme-cyan .right_chat li a:hover .media-object {
  border-color: #00bcd2;
}
.theme-cyan
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #00bcd2;
  border-color: #00bcd2;
  color: #fff;
}
.theme-cyan .auth-main:after {
  background: #00bcd2;
}
.theme-cyan .auth-main:before {
  background: #242a2b;
}
.theme-cyan .wizard > .steps .current a {
  background: #00bcd2;
}
.theme-cyan .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-cyan .wizard .actions a {
  background: #00bcd2;
}
.theme-cyan .maintenance {
  background: #00bcd2;
}
.theme-cyan .maintenance > div {
  background: #fff;
}
.theme-cyan .noUi-connect {
  background: #00bcd2;
}
.theme-cyan .blog-page .single_post .footer .stats li a:hover {
  color: #00bcd2;
}
.theme-cyan .ct-series-a .ct-line,
.theme-cyan .ct-series-a .ct-bar,
.theme-cyan .ct-series-a .ct-point {
  stroke: #394344;
}
.theme-cyan .ct-series-a .ct-area,
.theme-cyan .ct-series-a .ct-slice-donut-solid,
.theme-cyan .ct-series-a .ct-slice-pie,
.theme-cyan .ct-series-a .ct-bar {
  fill: #394344;
}
.theme-cyan .ct-series-b .ct-line,
.theme-cyan .ct-series-b .ct-bar,
.theme-cyan .ct-series-b .ct-point {
  stroke: #00bcd2;
}
.theme-cyan .ct-series-b .ct-area,
.theme-cyan .ct-series-b .ct-slice-donut-solid,
.theme-cyan .ct-series-b .ct-slice-pie,
.theme-cyan .ct-series-b .ct-bar {
  fill: #00bcd2;
}
.theme-cyan .ct-series-c .ct-line,
.theme-cyan .ct-series-c .ct-bar,
.theme-cyan .ct-series-c .ct-point {
  stroke: #5ed3e1;
}
.theme-cyan .ct-series-c .ct-area,
.theme-cyan .ct-series-c .ct-slice-donut-solid,
.theme-cyan .ct-series-c .ct-slice-pie,
.theme-cyan .ct-series-c .ct-bar {
  fill: #5ed3e1;
}
.theme-cyan .ct-series-d .ct-line,
.theme-cyan .ct-series-d .ct-bar,
.theme-cyan .ct-series-d .ct-point {
  stroke: #97cad0;
}
.theme-cyan .ct-series-d .ct-area,
.theme-cyan .ct-series-d .ct-slice-donut-solid,
.theme-cyan .ct-series-d .ct-slice-pie,
.theme-cyan .ct-series-d .ct-bar {
  fill: #97cad0;
}
.theme-cyan .ct-series-e .ct-line,
.theme-cyan .ct-series-e .ct-bar,
.theme-cyan .ct-series-e .ct-point {
  stroke: #394344;
}
.theme-cyan .ct-series-e .ct-area,
.theme-cyan .ct-series-e .ct-slice-donut-solid,
.theme-cyan .ct-series-e .ct-slice-pie,
.theme-cyan .ct-series-e .ct-bar {
  fill: #394344;
}
.theme-cyan .ct-series-f .ct-line,
.theme-cyan .ct-series-f .ct-bar,
.theme-cyan .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-cyan .ct-series-f .ct-area,
.theme-cyan .ct-series-f .ct-slice-donut-solid,
.theme-cyan .ct-series-f .ct-slice-pie,
.theme-cyan .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-cyan .ct-legend .ct-series-0:before {
  background-color: #394344;
  border-color: #394344;
}
.theme-cyan .ct-legend .ct-series-1:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-cyan .ct-legend .ct-series-2:before {
  background-color: #5ed3e1;
  border-color: #5ed3e1;
}
.theme-cyan .ct-legend .ct-series-3:before {
  background-color: #97cad0;
  border-color: #97cad0;
}
.theme-cyan .ct-legend .ct-series-4:before {
  background-color: #394344;
  border-color: #394344;
}
.theme-cyan .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-green ::selection {
  color: #fff;
  background: #29bd73;
}
.theme-green .page-loader-wrapper {
  background: #29bd73;
}
.theme-green .navbar-fixed-top {
  background: #29bd73;
}
.theme-green .navbar-fixed-top .form-control {
  border: 0;
}
.theme-green .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li.active > a {
  background: #29bd73;
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li i {
  color: #182973;
}
.theme-green .sidebar-nav .metismenu a {
  color: #182973;
}
.theme-green .sidebar-nav .metismenu a:hover,
.theme-green .sidebar-nav .metismenu a:focus {
  background: #182973;
  color: #fff;
}
.theme-green .sidebar-nav .metismenu a:hover i,
.theme-green .sidebar-nav .metismenu a:focus i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu .has-arrow:after {
  color: #e8e8e8;
}
.theme-green #left-sidebar {
  background: #fff;
}
.theme-green #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-green #left-sidebar .navbar-brand span {
  color: #29bd73;
}
.theme-green #left-sidebar .user-account {
  color: #182973;
}
.theme-green #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-green #left-sidebar .user-account .user_div .user-photo {
  border-color: #29bd73;
}
.theme-green #left-sidebar .user-account .dropdown-menu {
  background: #182973;
}
.theme-green #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-green #left-sidebar .user-account .dropdown-menu a:hover {
  color: #29bd73;
}
.theme-green #left-sidebar .user-account .dropdown-menu a:hover i {
  color: #29bd73;
}
.theme-green .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #29bd73;
  border-color: #29bd73;
}
.theme-green .chat-widget li.right .chat-info {
  background: #29bd73;
  color: #fff;
}
.theme-green .chat-widget li.right .chat-info:before {
  border-left: 10px solid #29bd73;
}
.theme-green .right_chat li a:hover .media-object {
  border-color: #29bd73;
}
.theme-green
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #29bd73;
  border-color: #29bd73;
  color: #fff;
}
.theme-green .auth-main:after {
  background: #29bd73;
}
.theme-green .auth-main:before {
  background: #182973;
}
.theme-green .wizard > .steps .current a {
  background: #29bd73;
}
.theme-green .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-green .wizard .actions a {
  background: #182973;
}
.theme-green .maintenance {
  background: #29bd73;
}
.theme-green .maintenance > div {
  background: #fff;
}
.theme-green .noUi-connect {
  background: #29bd73;
}
.theme-green .blog-page .single_post .footer .stats li a:hover {
  color: #29bd73;
}
.theme-green .ct-series-a .ct-line,
.theme-green .ct-series-a .ct-bar,
.theme-green .ct-series-a .ct-point {
  stroke: #182973;
}
.theme-green .ct-series-a .ct-area,
.theme-green .ct-series-a .ct-slice-donut-solid,
.theme-green .ct-series-a .ct-slice-pie,
.theme-green .ct-series-a .ct-bar {
  fill: #182973;
}
.theme-green .ct-series-b .ct-line,
.theme-green .ct-series-b .ct-bar,
.theme-green .ct-series-b .ct-point {
  stroke: #29bd73;
}
.theme-green .ct-series-b .ct-area,
.theme-green .ct-series-b .ct-slice-donut-solid,
.theme-green .ct-series-b .ct-slice-pie,
.theme-green .ct-series-b .ct-bar {
  fill: #29bd73;
}
.theme-green .ct-series-c .ct-line,
.theme-green .ct-series-c .ct-bar,
.theme-green .ct-series-c .ct-point {
  stroke: #6bc196;
}
.theme-green .ct-series-c .ct-area,
.theme-green .ct-series-c .ct-slice-donut-solid,
.theme-green .ct-series-c .ct-slice-pie,
.theme-green .ct-series-c .ct-bar {
  fill: #6bc196;
}
.theme-green .ct-series-d .ct-line,
.theme-green .ct-series-d .ct-bar,
.theme-green .ct-series-d .ct-point {
  stroke: #a4d4bc;
}
.theme-green .ct-series-d .ct-area,
.theme-green .ct-series-d .ct-slice-donut-solid,
.theme-green .ct-series-d .ct-slice-pie,
.theme-green .ct-series-d .ct-bar {
  fill: #a4d4bc;
}
.theme-green .ct-series-e .ct-line,
.theme-green .ct-series-e .ct-bar,
.theme-green .ct-series-e .ct-point {
  stroke: #00bcd2;
}
.theme-green .ct-series-e .ct-area,
.theme-green .ct-series-e .ct-slice-donut-solid,
.theme-green .ct-series-e .ct-slice-pie,
.theme-green .ct-series-e .ct-bar {
  fill: #00bcd2;
}
.theme-green .ct-series-f .ct-line,
.theme-green .ct-series-f .ct-bar,
.theme-green .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-green .ct-series-f .ct-area,
.theme-green .ct-series-f .ct-slice-donut-solid,
.theme-green .ct-series-f .ct-slice-pie,
.theme-green .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-green .ct-legend .ct-series-0:before {
  background-color: #182973;
  border-color: #182973;
}
.theme-green .ct-legend .ct-series-1:before {
  background-color: #29bd73;
  border-color: #29bd73;
}
.theme-green .ct-legend .ct-series-2:before {
  background-color: #6bc196;
  border-color: #6bc196;
}
.theme-green .ct-legend .ct-series-3:before {
  background-color: #a4d4bc;
  border-color: #a4d4bc;
}
.theme-green .ct-legend .ct-series-4:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-green .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-orange ::selection {
  color: #fff;
  background: #00bcd2;
}
.theme-orange .page-loader-wrapper {
  background: #00bcd2;
}
.theme-orange .navbar-fixed-top {
  background: #F45D48;
}
.theme-orange .navbar-fixed-top .form-control {
  border: 0;
}
.theme-orange .navbar-fixed-top .icon-menu i {
  color: #fff;
}

.theme-orange .sidebar-nav .metismenu > li.active > a {
  background: #343a40;

  color:.theme-purple ::selection {
    color: #fff;
    background: #6462ef;
   
  }


  /* .theme-orange .sidebar-nav .metismenu > li.active > a {
    background: #343a40;
    color: #fff;
  }
  
  .theme-purple ::selection {
    color: #fff;
    background: #6462ef;
  } */
  
  
  .theme-purple .page-loader-wrapper {
    background: #6462ef;
  }
  .theme-purple .navbar-fixed-top {
    background: #6462ef;
  }
  .theme-purple .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-purple .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-purple .sidebar-nav .metismenu > li.active > a {
    background: #6462ef;
    color: #fff;
  }
  .theme-purple .sidebar-nav .metismenu a {
    color: #292c3f;
  }
  .theme-purple .sidebar-nav .metismenu a:hover,
  .theme-purple .sidebar-nav .metismenu a:focus {
    background: #292c3f;
    color: #e8e8e8;
  }
  .theme-purple #left-sidebar {
    background: #fff;
  }
  .theme-purple #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-purple #left-sidebar .navbar-brand span {
    color: #6462ef;
  }
  .theme-purple #left-sidebar .user-account {
    color: #292c3f;
  }
  .theme-purple #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-purple #left-sidebar .user-account .user_div .user-photo {
    border-color: #6462ef;
  }
  .theme-purple #left-sidebar .user-account .dropdown-menu {
    background: #6462ef;
  }
  .theme-purple #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-purple .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #6462ef;
    border-color: #6462ef;
  }
  .theme-purple .chat-widget li.right .chat-info {
    background: #6462ef;
    color: #fff;
  }
  .theme-purple .chat-widget li.right .chat-info:before {
    border-left: 10px solid #6462ef;
  }
  .theme-purple .right_chat li a:hover .media-object {
    border-color: #6462ef;
  }
  .theme-purple .auth-main:after {
    background: #6462ef;
  }
  .theme-purple .auth-main:before {
    background: #17191c;
  }
  .theme-purple .wizard > .steps .current a {
    background: #6462ef;
  }
  .theme-purple .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-purple .wizard .actions a {
    background: #00bcd2;
  }
  .theme-purple
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #6462ef;
    border-color: #6462ef;
    color: #fff;
  }
  .theme-purple .maintenance {
    background: #6462ef;
  }
  .theme-purple .maintenance > div {
    background: #fff;
  }
  .theme-purple .noUi-connect {
    background: #6462ef;
  }
  .theme-purple .blog-page .single_post .footer .stats li a:hover {
    color: #6462ef;
  }
  .theme-purple .ct-series-a .ct-line,
  .theme-purple .ct-series-a .ct-bar,
  .theme-purple .ct-series-a .ct-point {
    stroke: #292c3f;
  }
  .theme-purple .ct-series-a .ct-area,
  .theme-purple .ct-series-a .ct-slice-donut-solid,
  .theme-purple .ct-series-a .ct-slice-pie,
  .theme-purple .ct-series-a .ct-bar {
    fill: #292c3f;
  }
  .theme-purple .ct-series-b .ct-line,
  .theme-purple .ct-series-b .ct-bar,
  .theme-purple .ct-series-b .ct-point {
    stroke: #6462ef;
  }
  .theme-purple .ct-series-b .ct-area,
  .theme-purple .ct-series-b .ct-slice-donut-solid,
  .theme-purple .ct-series-b .ct-slice-pie,
  .theme-purple .ct-series-b .ct-bar {
    fill: #6462ef;
  }
  .theme-purple .ct-series-c .ct-line,
  .theme-purple .ct-series-c .ct-bar,
  .theme-purple .ct-series-c .ct-point {
    stroke: #8f8de7;
  }
  .theme-purple .ct-series-c .ct-area,
  .theme-purple .ct-series-c .ct-slice-donut-solid,
  .theme-purple .ct-series-c .ct-slice-pie,
  .theme-purple .ct-series-c .ct-bar {
    fill: #8f8de7;
  }
  .theme-purple .ct-series-d .ct-line,
  .theme-purple .ct-series-d .ct-bar,
  .theme-purple .ct-series-d .ct-point {
    stroke: #b4b3e4;
  }
  .theme-purple .ct-series-d .ct-area,
  .theme-purple .ct-series-d .ct-slice-donut-solid,
  .theme-purple .ct-series-d .ct-slice-pie,
  .theme-purple .ct-series-d .ct-bar {
    fill: #b4b3e4;
  }
  .theme-purple .ct-series-e .ct-line,
  .theme-purple .ct-series-e .ct-bar,
  .theme-purple .ct-series-e .ct-point {
    stroke: #00bcd2;
  }
  .theme-purple .ct-series-e .ct-area,
  .theme-purple .ct-series-e .ct-slice-donut-solid,
  .theme-purple .ct-series-e .ct-slice-pie,
  .theme-purple .ct-series-e .ct-bar {
    fill: #00bcd2;
  }
  .theme-purple .ct-series-f .ct-line,
  .theme-purple .ct-series-f .ct-bar,
  .theme-purple .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-purple .ct-series-f .ct-area,
  .theme-purple .ct-series-f .ct-slice-donut-solid,
  .theme-purple .ct-series-f .ct-slice-pie,
  .theme-purple .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-purple .ct-legend .ct-series-0:before {
    background-color: #292c3f;
    border-color: #292c3f;
  }
  .theme-purple .ct-legend .ct-series-1:before {
    background-color: #6462ef;
    border-color: #6462ef;
  }
  .theme-purple .ct-legend .ct-series-2:before {
    background-color: #8f8de7;
    border-color: #8f8de7;
  }
  .theme-purple .ct-legend .ct-series-3:before {
    background-color: #b4b3e4;
    border-color: #b4b3e4;
  }
  .theme-purple .ct-legend .ct-series-4:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-purple .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-blue ::selection {
    color: #fff;
    background: #0e9be2;
  }
  .theme-blue .page-loader-wrapper {
    background: #0e9be2;
  }
  .theme-blue .navbar-fixed-top {
    background: #0e9be2;
  }
  .theme-blue .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-blue .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li.active > a {
    background: #ffaf1d;
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li i {
    color: #9da1a7;
  }
  .theme-blue .sidebar-nav .metismenu > li ul li.active a {
    color: #0e9be2;
  }
  .theme-blue .sidebar-nav .metismenu a {
    color: #9da1a7;
  }
  .theme-blue .sidebar-nav .metismenu a:hover,
  .theme-blue .sidebar-nav .metismenu a:focus {
    background: #2d3435;
  }
  .theme-blue .sidebar-nav .metismenu .has-arrow:after {
    color: #ffaf1d;
  }
  .theme-blue #left-sidebar {
    background: #303844;
  }
  .theme-blue #left-sidebar .user-account .user_div .user-photo {
    border-color: #ffaf1d;
  }
  .theme-blue .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #0e9be2;
    border-color: #0e9be2;
  }
  .theme-blue .chat-widget li.right .chat-info {
    background: #0e9be2;
    color: #fff;
  }
  .theme-blue .chat-widget li.right .chat-info:before {
    border-left: 10px solid #0e9be2;
  }
  .theme-blue .right_chat li a:hover .media-object {
    border-color: #0e9be2;
  }
  .theme-blue
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #0e9be2;
    border-color: #0e9be2;
    color: #fff;
  }
  .theme-blue .auth-main:after {
    background: #0e9be2;
  }
  .theme-blue .auth-main:before {
    background: #303844;
  }
  .theme-blue .wizard > .steps .current a {
    background: #0e9be2;
  }
  .theme-blue .wizard > .steps .done a {
    background: #9da1a7;
  }
  .theme-blue .wizard .actions a {
    background: #ffaf1d;
  }
  .theme-blue .maintenance {
    background: #0e9be2;
  }
  .theme-blue .maintenance > div {
    background: #fff;
  }
  .theme-blue .noUi-connect {
    background: #0e9be2;
  }
  .theme-blue .blog-page .single_post .footer .stats li a:hover {
    color: #0e9be2;
  }
  .theme-blue .ct-series-a .ct-line,
  .theme-blue .ct-series-a .ct-bar,
  .theme-blue .ct-series-a .ct-point {
    stroke: #303844;
  }
  .theme-blue .ct-series-a .ct-area,
  .theme-blue .ct-series-a .ct-slice-donut-solid,
  .theme-blue .ct-series-a .ct-slice-pie,
  .theme-blue .ct-series-a .ct-bar {
    fill: #303844;
  }
  .theme-blue .ct-series-b .ct-line,
  .theme-blue .ct-series-b .ct-bar,
  .theme-blue .ct-series-b .ct-point {
    stroke: #0e9be2;
  }
  .theme-blue .ct-series-b .ct-area,
  .theme-blue .ct-series-b .ct-slice-donut-solid,
  .theme-blue .ct-series-b .ct-slice-pie,
  .theme-blue .ct-series-b .ct-bar {
    fill: #0e9be2;
  }
  .theme-blue .ct-series-c .ct-line,
  .theme-blue .ct-series-c .ct-bar,
  .theme-blue .ct-series-c .ct-point {
    stroke: #ffaf1d;
  }
  .theme-blue .ct-series-c .ct-area,
  .theme-blue .ct-series-c .ct-slice-donut-solid,
  .theme-blue .ct-series-c .ct-slice-pie,
  .theme-blue .ct-series-c .ct-bar {
    fill: #ffaf1d;
  }
  .theme-blue .ct-series-d .ct-line,
  .theme-blue .ct-series-d .ct-bar,
  .theme-blue .ct-series-d .ct-point {
    stroke: #02b5b2;
  }
  .theme-blue .ct-series-d .ct-area,
  .theme-blue .ct-series-d .ct-slice-donut-solid,
  .theme-blue .ct-series-d .ct-slice-pie,
  .theme-blue .ct-series-d .ct-bar {
    fill: #02b5b2;
  }
  .theme-blue .ct-series-e .ct-line,
  .theme-blue .ct-series-e .ct-bar,
  .theme-blue .ct-series-e .ct-point {
    stroke: #767ee9;
  }
  .theme-blue .ct-series-e .ct-area,
  .theme-blue .ct-series-e .ct-slice-donut-solid,
  .theme-blue .ct-series-e .ct-slice-pie,
  .theme-blue .ct-series-e .ct-bar {
    fill: #767ee9;
  }
  .theme-blue .ct-series-f .ct-line,
  .theme-blue .ct-series-f .ct-bar,
  .theme-blue .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-blue .ct-series-f .ct-area,
  .theme-blue .ct-series-f .ct-slice-donut-solid,
  .theme-blue .ct-series-f .ct-slice-pie,
  .theme-blue .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-blue .ct-legend .ct-series-0:before {
    background-color: #303844;
    border-color: #303844;
  }
  .theme-blue .ct-legend .ct-series-1:before {
    background-color: #0e9be2;
    border-color: #0e9be2;
  }
  .theme-blue .ct-legend .ct-series-2:before {
    background-color: #ffaf1d;
    border-color: #ffaf1d;
  }
  .theme-blue .ct-legend .ct-series-3:before {
    background-color: #02b5b2;
    border-color: #02b5b2;
  }
  .theme-blue .ct-legend .ct-series-4:before {
    background-color: #767ee9;
    border-color: #767ee9;
  }
  .theme-blue .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-cyan ::selection {
    color: #fff;
    background: #00bcd2;
  }
  .theme-cyan .page-loader-wrapper {
    background: #00bcd2;
  }
  .theme-cyan .navbar-fixed-top {
    background: #00bcd2;
  }
  .theme-cyan .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-cyan .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li.active > a {
    background: #00bcd2;
    
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li i {
    color: #6f7b7d;
  }
  .theme-cyan .sidebar-nav .metismenu > li ul li.active a {
    color: #00bcd2;
   
  }
  .theme-cyan .sidebar-nav .metismenu a {
    color: #6f7b7d;
  }
  .theme-cyan .sidebar-nav .metismenu a:hover,
  .theme-cyan .sidebar-nav .metismenu a:focus {
    background: #2d3435;
  }
  .theme-cyan #left-sidebar {
    background: #fff;
  }
  .theme-cyan #left-sidebar .user-account .user_div .user-photo {
    border-color: #00bcd2;
  }
  .theme-cyan .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-cyan .chat-widget li.right .chat-info {
    background: #00bcd2;
    color: #fff;
  }
  .theme-cyan .chat-widget li.right .chat-info:before {
    border-left: 10px solid #00bcd2;
  }
  .theme-cyan .right_chat li a:hover .media-object {
    border-color: #00bcd2;
  }
  .theme-cyan
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #00bcd2;
    border-color: #00bcd2;
    color: #fff;
  }
  .theme-cyan .auth-main:after {
    background: #00bcd2;
  }
  .theme-cyan .auth-main:before {
    background: #242a2b;
  }
  .theme-cyan .wizard > .steps .current a {
    background: #00bcd2;
  }
  .theme-cyan .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-cyan .wizard .actions a {
    background: #00bcd2;
  }
  .theme-cyan .maintenance {
    background: #00bcd2;
  }
  .theme-cyan .maintenance > div {
    background: #fff;
  }
  .theme-cyan .noUi-connect {
    background: #00bcd2;
  }
  .theme-cyan .blog-page .single_post .footer .stats li a:hover {
    color: #00bcd2;
  }
  .theme-cyan .ct-series-a .ct-line,
  .theme-cyan .ct-series-a .ct-bar,
  .theme-cyan .ct-series-a .ct-point {
    stroke: #394344;
  }
  .theme-cyan .ct-series-a .ct-area,
  .theme-cyan .ct-series-a .ct-slice-donut-solid,
  .theme-cyan .ct-series-a .ct-slice-pie,
  .theme-cyan .ct-series-a .ct-bar {
    fill: #394344;
  }
  .theme-cyan .ct-series-b .ct-line,
  .theme-cyan .ct-series-b .ct-bar,
  .theme-cyan .ct-series-b .ct-point {
    stroke: #00bcd2;
  }
  .theme-cyan .ct-series-b .ct-area,
  .theme-cyan .ct-series-b .ct-slice-donut-solid,
  .theme-cyan .ct-series-b .ct-slice-pie,
  .theme-cyan .ct-series-b .ct-bar {
    fill: #00bcd2;
  }
  .theme-cyan .ct-series-c .ct-line,
  .theme-cyan .ct-series-c .ct-bar,
  .theme-cyan .ct-series-c .ct-point {
    stroke: #5ed3e1;
  }
  .theme-cyan .ct-series-c .ct-area,
  .theme-cyan .ct-series-c .ct-slice-donut-solid,
  .theme-cyan .ct-series-c .ct-slice-pie,
  .theme-cyan .ct-series-c .ct-bar {
    fill: #5ed3e1;
  }
  .theme-cyan .ct-series-d .ct-line,
  .theme-cyan .ct-series-d .ct-bar,
  .theme-cyan .ct-series-d .ct-point {
    stroke: #97cad0;
  }
  .theme-cyan .ct-series-d .ct-area,
  .theme-cyan .ct-series-d .ct-slice-donut-solid,
  .theme-cyan .ct-series-d .ct-slice-pie,
  .theme-cyan .ct-series-d .ct-bar {
    fill: #97cad0;
  }
  .theme-cyan .ct-series-e .ct-line,
  .theme-cyan .ct-series-e .ct-bar,
  .theme-cyan .ct-series-e .ct-point {
    stroke: #394344;
  }
  .theme-cyan .ct-series-e .ct-area,
  .theme-cyan .ct-series-e .ct-slice-donut-solid,
  .theme-cyan .ct-series-e .ct-slice-pie,
  .theme-cyan .ct-series-e .ct-bar {
    fill: #394344;
  }
  .theme-cyan .ct-series-f .ct-line,
  .theme-cyan .ct-series-f .ct-bar,
  .theme-cyan .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-cyan .ct-series-f .ct-area,
  .theme-cyan .ct-series-f .ct-slice-donut-solid,
  .theme-cyan .ct-series-f .ct-slice-pie,
  .theme-cyan .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-cyan .ct-legend .ct-series-0:before {
    background-color: #394344;
    border-color: #394344;
  }
  .theme-cyan .ct-legend .ct-series-1:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-cyan .ct-legend .ct-series-2:before {
    background-color: #5ed3e1;
    border-color: #5ed3e1;
  }
  .theme-cyan .ct-legend .ct-series-3:before {
    background-color: #97cad0;
    border-color: #97cad0;
  }
  .theme-cyan .ct-legend .ct-series-4:before {
    background-color: #394344;
    border-color: #394344;
  }
  .theme-cyan .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-green ::selection {
    color: #fff;
    background: #29bd73;
  }
  .theme-green .page-loader-wrapper {
    background: #29bd73;
  }
  .theme-green .navbar-fixed-top {
    background: #29bd73;
  }
  .theme-green .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-green .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li.active > a {
    background: #29bd73;
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li i {
    color: #182973;
  }
  .theme-green .sidebar-nav .metismenu a {
    color: #182973;
  }
  .theme-green .sidebar-nav .metismenu a:hover,
  .theme-green .sidebar-nav .metismenu a:focus {
    background: #182973;
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu a:hover i,
  .theme-green .sidebar-nav .metismenu a:focus i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu .has-arrow:after {
    color: #e8e8e8;
  }
  .theme-green #left-sidebar {
    background: #fff;
  }
  .theme-green #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-green #left-sidebar .navbar-brand span {
    color: #29bd73;
  }
  .theme-green #left-sidebar .user-account {
    color: #182973;
  }
  .theme-green #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-green #left-sidebar .user-account .user_div .user-photo {
    border-color: #29bd73;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu {
    background: #182973;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a:hover {
    color: #29bd73;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a:hover i {
    color: #29bd73;
  }
  .theme-green .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #29bd73;
    border-color: #29bd73;
  }
  .theme-green .chat-widget li.right .chat-info {
    background: #29bd73;
    color: #fff;
  }
  .theme-green .chat-widget li.right .chat-info:before {
    border-left: 10px solid #29bd73;
  }
  .theme-green .right_chat li a:hover .media-object {
    border-color: #29bd73;
  }
  .theme-green
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #29bd73;
    border-color: #29bd73;
    color: #fff;
  }
  .theme-green .auth-main:after {
    background: #29bd73;
  }
  .theme-green .auth-main:before {
    background: #182973;
  }
  .theme-green .wizard > .steps .current a {
    background: #29bd73;
  }
  .theme-green .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-green .wizard .actions a {
    background: #182973;
  }
  .theme-green .maintenance {
    background: #29bd73;
  }
  .theme-green .maintenance > div {
    background: #fff;
  }
  .theme-green .noUi-connect {
    background: #29bd73;
  }
  .theme-green .blog-page .single_post .footer .stats li a:hover {
    color: #29bd73;
  }
  .theme-green .ct-series-a .ct-line,
  .theme-green .ct-series-a .ct-bar,
  .theme-green .ct-series-a .ct-point {
    stroke: #182973;
  }
  .theme-green .ct-series-a .ct-area,
  .theme-green .ct-series-a .ct-slice-donut-solid,
  .theme-green .ct-series-a .ct-slice-pie,
  .theme-green .ct-series-a .ct-bar {
    fill: #182973;
  }
  .theme-green .ct-series-b .ct-line,
  .theme-green .ct-series-b .ct-bar,
  .theme-green .ct-series-b .ct-point {
    stroke: #29bd73;
  }
  .theme-green .ct-series-b .ct-area,
  .theme-green .ct-series-b .ct-slice-donut-solid,
  .theme-green .ct-series-b .ct-slice-pie,
  .theme-green .ct-series-b .ct-bar {
    fill: #29bd73;
  }
  .theme-green .ct-series-c .ct-line,
  .theme-green .ct-series-c .ct-bar,
  .theme-green .ct-series-c .ct-point {
    stroke: #6bc196;
  }
  .theme-green .ct-series-c .ct-area,
  .theme-green .ct-series-c .ct-slice-donut-solid,
  .theme-green .ct-series-c .ct-slice-pie,
  .theme-green .ct-series-c .ct-bar {
    fill: #6bc196;
  }
  .theme-green .ct-series-d .ct-line,
  .theme-green .ct-series-d .ct-bar,
  .theme-green .ct-series-d .ct-point {
    stroke: #a4d4bc;
  }
  .theme-green .ct-series-d .ct-area,
  .theme-green .ct-series-d .ct-slice-donut-solid,
  .theme-green .ct-series-d .ct-slice-pie,
  .theme-green .ct-series-d .ct-bar {
    fill: #a4d4bc;
  }
  .theme-green .ct-series-e .ct-line,
  .theme-green .ct-series-e .ct-bar,
  .theme-green .ct-series-e .ct-point {
    stroke: #00bcd2;
  }
  .theme-green .ct-series-e .ct-area,
  .theme-green .ct-series-e .ct-slice-donut-solid,
  .theme-green .ct-series-e .ct-slice-pie,
  .theme-green .ct-series-e .ct-bar {
    fill: #00bcd2;
  }
  .theme-green .ct-series-f .ct-line,
  .theme-green .ct-series-f .ct-bar,
  .theme-green .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-green .ct-series-f .ct-area,
  .theme-green .ct-series-f .ct-slice-donut-solid,
  .theme-green .ct-series-f .ct-slice-pie,
  .theme-green .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-green .ct-legend .ct-series-0:before {
    background-color: #182973;
    border-color: #182973;
  }
  .theme-green .ct-legend .ct-series-1:before {
    background-color: #29bd73;
    border-color: #29bd73;
  }
  .theme-green .ct-legend .ct-series-2:before {
    background-color: #6bc196;
    border-color: #6bc196;
  }
  .theme-green .ct-legend .ct-series-3:before {
    background-color: #a4d4bc;
    border-color: #a4d4bc;
  }
  .theme-green .ct-legend .ct-series-4:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-green .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-orange ::selection {
    color: #fff;
    background: #00bcd2;
  }
  .theme-orange .page-loader-wrapper {
    background: #00bcd2;
  }
  .theme-orange .navbar-fixed-top {
    background: #00bcd2;
  }
  .theme-orange .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-orange .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li.active > a {
    background: #343a40;
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li i {
    color: #380e47;
  }
  .theme-orange .sidebar-nav .metismenu a {
    color: #380e47;
  }
  .theme-orange .sidebar-nav .metismenu a:hover,
  .theme-orange .sidebar-nav .metismenu a:focus {
    /* background: #00bcd2; */
    background-color: #F45D48;
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu a:hover i,
  .theme-orange .sidebar-nav .metismenu a:focus i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu .has-arrow:after {
    color: #e8e8e8;
  }
  .theme-orange #left-sidebar {
    background: #fff;
  }
  .theme-orange #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-orange #left-sidebar .navbar-brand span {
    /* color: #00bcd2; */
  }
  .theme-orange #left-sidebar .user-account {
    color: #380e47;
  }
  .theme-orange #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-orange #left-sidebar .user-account .user_div .user-photo {
    border-color: #00bcd2;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu {
    background: #343a40;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a:hover {
    color: #00bcd2;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a:hover i {
    color: #00bcd2;
  }
  .theme-orange .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-orange .chat-widget li.right .chat-info {
    background: #00bcd2;
    color: #fff;
  }
  .theme-orange .chat-widget li.right .chat-info:before {
    border-left: 10px solid #00bcd2;
  }
  .theme-orange .right_chat li a:hover .media-object {
    border-color: #00bcd2;
  }
  .theme-orange
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #00bcd2;
    border-color: #00bcd2;
    color: #fff;
  }
  .theme-orange .auth-main:after {
    background: #00bcd2;
  }
  .theme-orange .auth-main:before {
    background: #380e47;
  }
  .theme-orange .wizard > .steps .current a {
    background: #00bcd2;
  }
  .theme-orange .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-orange .wizard .actions a {
    background: #380e47;
  }
  .theme-orange .maintenance {
    background: #00bcd2;
  }
  .theme-orange .maintenance > div {
    background: #fff;
  }
  .theme-orange .noUi-connect {
    background: #00bcd2;
  }
  .theme-orange .blog-page .single_post .footer .stats li a:hover {
    color: #00bcd2;
  }
  .theme-orange .ct-series-a .ct-line,
  .theme-orange .ct-series-a .ct-bar,
  .theme-orange .ct-series-a .ct-point {
    stroke: #380e47;
  }
  .theme-orange .ct-series-a .ct-area,
  .theme-orange .ct-series-a .ct-slice-donut-solid,
  .theme-orange .ct-series-a .ct-slice-pie,
  .theme-orange .ct-series-a .ct-bar {
    fill: #380e47;
  }
  .theme-orange .ct-series-b .ct-line,
  .theme-orange .ct-series-b .ct-bar,
  .theme-orange .ct-series-b .ct-point {
    stroke: #00bcd2;
  }
  .theme-orange .ct-series-b .ct-area,
  .theme-orange .ct-series-b .ct-slice-donut-solid,
  .theme-orange .ct-series-b .ct-slice-pie,
  .theme-orange .ct-series-b .ct-bar {
    fill: #00bcd2;
  }
  .theme-orange .ct-series-c .ct-line,
  .theme-orange .ct-series-c .ct-bar,
  .theme-orange .ct-series-c .ct-point {
    stroke: #ffd974;
  }
  .theme-orange .ct-series-c .ct-area,
  .theme-orange .ct-series-c .ct-slice-donut-solid,
  .theme-orange .ct-series-c .ct-slice-pie,
  .theme-orange .ct-series-c .ct-bar {
    fill: #ffd974;
  }
  .theme-orange .ct-series-d .ct-line,
  .theme-orange .ct-series-d .ct-bar,
  .theme-orange .ct-series-d .ct-point {
    stroke: #e4dbc2;
  }
  .theme-orange .ct-series-d .ct-area,
  .theme-orange .ct-series-d .ct-slice-donut-solid,
  .theme-orange .ct-series-d .ct-slice-pie,
  .theme-orange .ct-series-d .ct-bar {
    fill: #e4dbc2;
  }
  .theme-orange .ct-series-e .ct-line,
  .theme-orange .ct-series-e .ct-bar,
  .theme-orange .ct-series-e .ct-point {
    stroke: #1a79ee;
  }
  .theme-orange .ct-series-e .ct-area,
  .theme-orange .ct-series-e .ct-slice-donut-solid,
  .theme-orange .ct-series-e .ct-slice-pie,
  .theme-orange .ct-series-e .ct-bar {
    fill: #1a79ee;
  }
  .theme-orange .ct-series-f .ct-line,
  .theme-orange .ct-series-f .ct-bar,
  .theme-orange .ct-series-f .ct-point {
    stroke: #2ac151;
  }
  .theme-orange .ct-series-f .ct-area,
  .theme-orange .ct-series-f .ct-slice-donut-solid,
  .theme-orange .ct-series-f .ct-slice-pie,
  .theme-orange .ct-series-f .ct-bar {
    fill: #2ac151;
  }
  .theme-orange .ct-legend .ct-series-0:before {
    background-color: #380e47;
    border-color: #380e47;
  }
  .theme-orange .ct-legend .ct-series-1:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-orange .ct-legend .ct-series-2:before {
    background-color: #ffd974;
    border-color: #ffd974;
  }
  .theme-orange .ct-legend .ct-series-3:before {
    background-color: #e4dbc2;
    border-color: #e4dbc2;
  }
  .theme-orange .ct-legend .ct-series-4:before {
    background-color: #1a79ee;
    border-color: #1a79ee;
  }
  .theme-orange .ct-legend .ct-series-5:before {
    background-color: #2ac151;
    border-color: #2ac151;
  }
  .theme-blush ::selection {
    color: #fff;
    background: #e25985;
  }
  .theme-blush .page-loader-wrapper {
    background: #e25985;
  }
  .theme-blush .navbar-fixed-top {
    background: #e25985;
  }
  .theme-blush .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-blush .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li.active > a {
    background: #e25985;
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li i {
    color: #969696;
  }
  .theme-blush .sidebar-nav .metismenu > li ul li.active a {
    color: #e25985;
  }
  .theme-blush .sidebar-nav .metismenu a {
    color: #969696;
  }
  .theme-blush .sidebar-nav .metismenu a:hover,
  .theme-blush .sidebar-nav .metismenu a:focus {
    background: rgba(226, 89, 133, 0.1);
  }
  .theme-blush #left-sidebar {
    background: #1b1f23;
  }
  .theme-blush #left-sidebar .user-account .user_div .user-photo {
    border-color: #e25985;
  }
  .theme-blush .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #e25985;
    border-color: #e25985;
  }
  .theme-blush .chat-widget li.right .chat-info {
    background: #e25985;
    color: #fff;
  }
  .theme-blush .chat-widget li.right .chat-info:before {
    border-left: 10px solid #e25985;
  }
  .theme-blush .right_chat li a:hover .media-object {
    border-color: #e25985;
  }
  .theme-blush
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #e25985;
    border-color: #e25985;
    color: #fff;
  }
  .theme-blush .auth-main:after {
    background: #e25985;
  }
  .theme-blush .auth-main:before {
    background: #1b1f23;
  }
  .theme-blush .wizard > .steps .current a {
    background: #e25985;
  }
  .theme-blush .wizard > .steps .done a {
    background: #969696;
  }
  .theme-blush .wizard .actions a {
    background: #1b1f23;
  }
  .theme-blush .maintenance {
    background: #e25985;
  }
  .theme-blush .maintenance > div {
    background: #fff;
  }
  .theme-blush .noUi-connect {
    background: #e25985;
  }
  .theme-blush .blog-page .single_post .footer .stats li a:hover {
    color: #e25985;
  }
  .theme-blush .ct-series-a .ct-line,
  .theme-blush .ct-series-a .ct-bar,
  .theme-blush .ct-series-a .ct-point {
    stroke: #1b1f23;
  }
  .theme-blush .ct-series-a .ct-area,
  .theme-blush .ct-series-a .ct-slice-donut-solid,
  .theme-blush .ct-series-a .ct-slice-pie,
  .theme-blush .ct-series-a .ct-bar {
    fill: #1b1f23;
  }
  .theme-blush .ct-series-b .ct-line,
  .theme-blush .ct-series-b .ct-bar,
  .theme-blush .ct-series-b .ct-point {
    stroke: #e25985;
  }
  .theme-blush .ct-series-b .ct-area,
  .theme-blush .ct-series-b .ct-slice-donut-solid,
  .theme-blush .ct-series-b .ct-slice-pie,
  .theme-blush .ct-series-b .ct-bar {
    fill: #e25985;
  }
  .theme-blush .ct-series-c .ct-line,
  .theme-blush .ct-series-c .ct-bar,
  .theme-blush .ct-series-c .ct-point {
    stroke: #de7f9e;
  }
  .theme-blush .ct-series-c .ct-area,
  .theme-blush .ct-series-c .ct-slice-donut-solid,
  .theme-blush .ct-series-c .ct-slice-pie,
  .theme-blush .ct-series-c .ct-bar {
    fill: #de7f9e;
  }
  .theme-blush .ct-series-d .ct-line,
  .theme-blush .ct-series-d .ct-bar,
  .theme-blush .ct-series-d .ct-point {
    stroke: #debac6;
  }
  .theme-blush .ct-series-d .ct-area,
  .theme-blush .ct-series-d .ct-slice-donut-solid,
  .theme-blush .ct-series-d .ct-slice-pie,
  .theme-blush .ct-series-d .ct-bar {
    fill: #debac6;
  }
  .theme-blush .ct-series-e .ct-line,
  .theme-blush .ct-series-e .ct-bar,
  .theme-blush .ct-series-e .ct-point {
    stroke: #767ee9;
  }
  .theme-blush .ct-series-e .ct-area,
  .theme-blush .ct-series-e .ct-slice-donut-solid,
  .theme-blush .ct-series-e .ct-slice-pie,
  .theme-blush .ct-series-e .ct-bar {
    fill: #767ee9;
  }
  .theme-blush .ct-series-f .ct-line,
  .theme-blush .ct-series-f .ct-bar,
  .theme-blush .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-blush .ct-series-f .ct-area,
  .theme-blush .ct-series-f .ct-slice-donut-solid,
  .theme-blush .ct-series-f .ct-slice-pie,
  .theme-blush .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-blush .ct-legend .ct-series-0:before {
    background-color: #1b1f23;
    border-color: #1b1f23;
  }
  .theme-blush .ct-legend .ct-series-1:before {
    background-color: #e25985;
    border-color: #e25985;
  }
  .theme-blush .ct-legend .ct-series-2:before {
    background-color: #de7f9e;
    border-color: #de7f9e;
  }
  .theme-blush .ct-legend .ct-series-3:before {
    background-color: #debac6;
    border-color: #debac6;
  }
  .theme-blush .ct-legend .ct-series-4:before {
    background-color: #767ee9;
    border-color: #767ee9;
  }
  .theme-blush .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
}
.theme-orange .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-orange .sidebar-nav .metismenu > li i {
  color: #380e47;
}
.theme-orange .sidebar-nav .metismenu a {
  color: #380e47;
}
/* changes to be done */
.theme-orange .sidebar-nav .metismenu a:hover,  
.theme-orange .sidebar-nav .metismenu a:focus {
  /* background: #F45D48; 
  color: #fff; */
  background-color: #fff;
  color: #F45D48;
}
.theme-orange .sidebar-nav .metismenu a:hover i,
.theme-orange .sidebar-nav .metismenu a:focus i {
  color: #fff;
}
.theme-orange .sidebar-nav .metismenu .has-arrow:after {
  color: #e8e8e8;
}
.theme-orange #left-sidebar {
  background: #fff;
}
.theme-orange #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-orange #left-sidebar .navbar-brand span {
  color: #00bcd2;
}
.theme-orange #left-sidebar .user-account {
  color: #380e47;
}
.theme-orange #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-orange #left-sidebar .user-account .user_div .user-photo {
  border-color: #00bcd2;
  border-radius: 90px;
}
.theme-orange #left-sidebar .user-account .dropdown-menu {
  background: #343a40;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a:hover {
  color: #00bcd2;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a:hover i {
  color: #00bcd2;
}
.theme-orange .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #00bcd2;
  border-color: #00bcd2;
}
.theme-orange .chat-widget li.right .chat-info {
  background: #00bcd2;
  color: #fff;
}
.theme-orange .chat-widget li.right .chat-info:before {
  border-left: 10px solid #00bcd2;
}
.theme-orange .right_chat li a:hover .media-object {
  border-color: #00bcd2;
}
.theme-orange
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #00bcd2;
  border-color: #00bcd2;
  color: #fff;
}
.theme-orange .auth-main:after {
  background: #00bcd2;
}
.theme-orange .auth-main:before {
  background: #380e47;
}
.theme-orange .wizard > .steps .current a {
  background: #00bcd2;
}
.theme-orange .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-orange .wizard .actions a {
  background: #380e47;
}
.theme-orange .maintenance {
  background: #00bcd2;
}
.theme-orange .maintenance > div {
  background: #fff;
}
.theme-orange .noUi-connect {
  background: #00bcd2;
}
.theme-orange .blog-page .single_post .footer .stats li a:hover {
  color: #00bcd2;
}
.theme-orange .ct-series-a .ct-line,
.theme-orange .ct-series-a .ct-bar,
.theme-orange .ct-series-a .ct-point {
  stroke: #380e47;
}
.theme-orange .ct-series-a .ct-area,
.theme-orange .ct-series-a .ct-slice-donut-solid,
.theme-orange .ct-series-a .ct-slice-pie,
.theme-orange .ct-series-a .ct-bar {
  fill: #380e47;
}
.theme-orange .ct-series-b .ct-line,
.theme-orange .ct-series-b .ct-bar,
.theme-orange .ct-series-b .ct-point {
  stroke: #00bcd2;
}
.theme-orange .ct-series-b .ct-area,
.theme-orange .ct-series-b .ct-slice-donut-solid,
.theme-orange .ct-series-b .ct-slice-pie,
.theme-orange .ct-series-b .ct-bar {
  fill: #00bcd2;
}
.theme-orange .ct-series-c .ct-line,
.theme-orange .ct-series-c .ct-bar,
.theme-orange .ct-series-c .ct-point {
  stroke: #ffd974;
}
.theme-orange .ct-series-c .ct-area,
.theme-orange .ct-series-c .ct-slice-donut-solid,
.theme-orange .ct-series-c .ct-slice-pie,
.theme-orange .ct-series-c .ct-bar {
  fill: #ffd974;
}
.theme-orange .ct-series-d .ct-line,
.theme-orange .ct-series-d .ct-bar,
.theme-orange .ct-series-d .ct-point {
  stroke: #e4dbc2;
}
.theme-orange .ct-series-d .ct-area,
.theme-orange .ct-series-d .ct-slice-donut-solid,
.theme-orange .ct-series-d .ct-slice-pie,
.theme-orange .ct-series-d .ct-bar {
  fill: #e4dbc2;
}
.theme-orange .ct-series-e .ct-line,
.theme-orange .ct-series-e .ct-bar,
.theme-orange .ct-series-e .ct-point {
  stroke: #1a79ee;
}
.theme-orange .ct-series-e .ct-area,
.theme-orange .ct-series-e .ct-slice-donut-solid,
.theme-orange .ct-series-e .ct-slice-pie,
.theme-orange .ct-series-e .ct-bar {
  fill: #1a79ee;
}
.theme-orange .ct-series-f .ct-line,
.theme-orange .ct-series-f .ct-bar,
.theme-orange .ct-series-f .ct-point {
  stroke: #2ac151;
}
.theme-orange .ct-series-f .ct-area,
.theme-orange .ct-series-f .ct-slice-donut-solid,
.theme-orange .ct-series-f .ct-slice-pie,
.theme-orange .ct-series-f .ct-bar {
  fill: #2ac151;
}
.theme-orange .ct-legend .ct-series-0:before {
  background-color: #380e47;
  border-color: #380e47;
}
.theme-orange .ct-legend .ct-series-1:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-orange .ct-legend .ct-series-2:before {
  background-color: #ffd974;
  border-color: #ffd974;
}
.theme-orange .ct-legend .ct-series-3:before {
  background-color: #e4dbc2;
  border-color: #e4dbc2;
}
.theme-orange .ct-legend .ct-series-4:before {
  background-color: #1a79ee;
  border-color: #1a79ee;
}
.theme-orange .ct-legend .ct-series-5:before {
  background-color: #2ac151;
  border-color: #2ac151;
}
.theme-blush ::selection {
  color: #fff;
  background: #e25985;
}
.theme-blush .page-loader-wrapper {
  background: #e25985;
}
.theme-blush .navbar-fixed-top {
  background: #e25985;
}
.theme-blush .navbar-fixed-top .form-control {
  border: 0;
}
.theme-blush .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li.active > a {
  background: #e25985;
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li i {
  color: #969696;
}
.theme-blush .sidebar-nav .metismenu > li ul li.active a {
  color: #e25985;
}
.theme-blush .sidebar-nav .metismenu a {
  color: #969696;
}
.theme-blush .sidebar-nav .metismenu a:hover,
.theme-blush .sidebar-nav .metismenu a:focus {
  background: rgba(226, 89, 133, 0.1);
}
.theme-blush #left-sidebar {
  background: #1b1f23;
}
.theme-blush #left-sidebar .user-account .user_div .user-photo {
  border-color: #e25985;
}
.theme-blush .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #e25985;
  border-color: #e25985;
}
.theme-blush .chat-widget li.right .chat-info {
  background: #e25985;
  color: #fff;
}
.theme-blush .chat-widget li.right .chat-info:before {
  border-left: 10px solid #e25985;
}
.theme-blush .right_chat li a:hover .media-object {
  border-color: #e25985;
}
.theme-blush
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #e25985;
  border-color: #e25985;
  color: #fff;
}
.theme-blush .auth-main:after {
  background: #e25985;
}
.theme-blush .auth-main:before {
  background: #1b1f23;
}
.theme-blush .wizard > .steps .current a {
  background: #e25985;
}
.theme-blush .wizard > .steps .done a {
  background: #969696;
}
.theme-blush .wizard .actions a {
  background: #1b1f23;
}
.theme-blush .maintenance {
  background: #e25985;
}
.theme-blush .maintenance > div {
  background: #fff;
}
.theme-blush .noUi-connect {
  background: #e25985;
}
.theme-blush .blog-page .single_post .footer .stats li a:hover {
  color: #e25985;
}
.theme-blush .ct-series-a .ct-line,
.theme-blush .ct-series-a .ct-bar,
.theme-blush .ct-series-a .ct-point {
  stroke: #1b1f23;
}
.theme-blush .ct-series-a .ct-area,
.theme-blush .ct-series-a .ct-slice-donut-solid,
.theme-blush .ct-series-a .ct-slice-pie,
.theme-blush .ct-series-a .ct-bar {
  fill: #1b1f23;
}
.theme-blush .ct-series-b .ct-line,
.theme-blush .ct-series-b .ct-bar,
.theme-blush .ct-series-b .ct-point {
  stroke: #e25985;
}
.theme-blush .ct-series-b .ct-area,
.theme-blush .ct-series-b .ct-slice-donut-solid,
.theme-blush .ct-series-b .ct-slice-pie,
.theme-blush .ct-series-b .ct-bar {
  fill: #e25985;
}
.theme-blush .ct-series-c .ct-line,
.theme-blush .ct-series-c .ct-bar,
.theme-blush .ct-series-c .ct-point {
  stroke: #de7f9e;
}
.theme-blush .ct-series-c .ct-area,
.theme-blush .ct-series-c .ct-slice-donut-solid,
.theme-blush .ct-series-c .ct-slice-pie,
.theme-blush .ct-series-c .ct-bar {
  fill: #de7f9e;
}
.theme-blush .ct-series-d .ct-line,
.theme-blush .ct-series-d .ct-bar,
.theme-blush .ct-series-d .ct-point {
  stroke: #debac6;
}
.theme-blush .ct-series-d .ct-area,
.theme-blush .ct-series-d .ct-slice-donut-solid,
.theme-blush .ct-series-d .ct-slice-pie,
.theme-blush .ct-series-d .ct-bar {
  fill: #debac6;
}
.theme-blush .ct-series-e .ct-line,
.theme-blush .ct-series-e .ct-bar,
.theme-blush .ct-series-e .ct-point {
  stroke: #767ee9;
}
.theme-blush .ct-series-e .ct-area,
.theme-blush .ct-series-e .ct-slice-donut-solid,
.theme-blush .ct-series-e .ct-slice-pie,
.theme-blush .ct-series-e .ct-bar {
  fill: #767ee9;
}
.theme-blush .ct-series-f .ct-line,
.theme-blush .ct-series-f .ct-bar,
.theme-blush .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-blush .ct-series-f .ct-area,
.theme-blush .ct-series-f .ct-slice-donut-solid,
.theme-blush .ct-series-f .ct-slice-pie,
.theme-blush .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-blush .ct-legend .ct-series-0:before {
  background-color: #1b1f23;
  border-color: #1b1f23;
}
.theme-blush .ct-legend .ct-series-1:before {
  background-color: #e25985;
  border-color: #e25985;
}
.theme-blush .ct-legend .ct-series-2:before {
  background-color: #de7f9e;
  border-color: #de7f9e;
}
.theme-blush .ct-legend .ct-series-3:before {
  background-color: #debac6;
  border-color: #debac6;
}
.theme-blush .ct-legend .ct-series-4:before {
  background-color: #767ee9;
  border-color: #767ee9;
}
.theme-blush .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}

/* changes */
.theme-orange .sidebar-nav .metismenu > li.active > a {
  background: #ffff;
  color: #F45D48;
  font-weight: bold;
}
