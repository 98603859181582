@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);

.loaderbgpopup {
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;
  }

  .loader {
    top: 0px;
    right: 0px;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 3px solid;
    border-color: #FFF #FFF transparent transparent;
    box-sizing: border-box;
    animation: rotation 2s linear infinite;
     
  }
  .loader::after,
  .loader::before {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    border: 3px solid;
    border-color: transparent transparent #FF3D00 #FF3D00;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-sizing: border-box;
    animation: rotationBack 0.5s linear infinite;
    transform-origin: center center;
  }
  .loader::before {
    width: 32px;
    height: 32px;
    border-color: #FFF #FFF transparent transparent;
    animation: rotation 1.5s linear infinite;
  }
      
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  } 
  @keyframes rotationBack {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
      
  
.readonly {
    background-color: #dbd7d8;
    border: 1px solid #8facc2;
    padding-left: 5px;
  }
  
  /* .my-autocomplete-search {
      border: 1px solid #000;
      background: none;
    } */
  
  /* .fa-plus,
  .fa-search,
  .fa-pencil-alt {
    font-size: 24px;
    position: relative;
    top: 3px;
    margin-left: 5px;
  } */
  /* .fa-plus {
    color: #518197;
  }
  
  .fa-search {
    color: #56849a;
  }
  
  .fas {
    cursor: pointer;
  } */
  
  /* .myAutocomplete {
      position: absolute;
      display: inline-block;
    } */
  
  
  #myAutocompleteID {
    min-height: 35px;
    font-size: 13px;
    width: 100% !important;
    color: #495057;
    background-color: #ffdd;
    background-clip: padding-box;
    border: 1px solid #e0e0ad;
    border-radius: 0.25rem;
    padding-left: 16px;
  }
  #myAutocompleteID :focus {
    border: 1px solid #eb7890 !important;
  }
  
  .myAutocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99999;
    /*position the autocomplete items to be the same width as the container:*/
    /* top: 100%;
      left: 0;
      right: 0; */
    width: 100%;
    box-shadow: 1px 19px 12px rgb(217 217 217); 
  }
  
  .myAutocomplete-items div,
  .myAutocomplete-items span {
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: black !important; /* inst visit */
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  
  /*when hovering an item:*/
  .myAutocomplete-items div:hover,
  .myAutocomplete-items span:hover {
    background-color: #e9e9e9;
  }
  
  /*when navigating through the items using the arrow keys:*/
  .myAutocomplete-active {
    background-color: #e9e9e9 !important;
  }
  
  #my-autocomplete-cross-icon {
    color: #858585;
    font-size: 16px;
    cursor: pointer;
    vertical-align: super;
  }
  
  /* .my-autocomplete-search-icon {
    font-weight: bold;
    padding: 15px;
    background: url(../../../asserts/images/my-autocomplete-searchicon.png)
      no-repeat right;
    border: none;
    cursor: pointer;
    vertical-align: super;
  } */
  .provider-form {
    padding-left: 0px 0px 0px 0px;
  }
  .provider-form input {
    width: 98%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #7d8086;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  
  .provider-form {
    padding-left: 0px 0px 0px 0px;
  }
  .provider-form label:hover {
    background: none;
  }
  
  .provider-form input {
    width: 98%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #7d8086;
  }
  .provider-form .address {
    width: 99%;
    height: 36px;
    border-radius: 5px;
    border: 1px solid #8facc2;
  }
  .provider-form h3 {
    margin: 0 auto;
    color: #d8526b;
    background: #c5e7f4;
    padding: 10px;
    font-size: 17px;
    font-family: "Lato";
    border-bottom: 0px solid #03658c;
    font-weight: bold;
    float: left;
  }
  
  .provider-form h2 {
    /* ADDED BY KHIZER*/
    margin: 0 auto;
    color: #d8526b;
    background: #c5e7f4;
    padding: 10px;
    font-size: 25px;
    font-family: "Lato";
    border-bottom: 0px solid #03658c;
    font-weight: bold;
    float: left;
  }
  
  .provider-form .form-control-user {
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 10px;
  }
  
  .provider-form:focus {
    /* color: #6e707e; */
    color: rgb(0 0 0) !important;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.08rem rgba(242, 121, 144);
  }
  
  .text-danger {
    color: #e74a3b !important;
  }
  
  a.text-danger:focus,
  a.text-danger:hover {
    color: #be2617 !important;
  }
  
.popBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: none;
}

.text-red {
  color: red;
}
.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0rem !important;
  /* font-size: 80%; */
  font-size: 0.75rem !important;
  color: #e74a3b;
  padding-left: 1% !important;
  text-align: left;
}

.anchor-tag {
  color: var(--primaryColor);
}
.anchor-tag:hover {
  text-decoration: underline;
  color: var(--primaryColorHovered);
  cursor: pointer;
}
.required:after {
  content:" *";
  font-size: 10px;
  color: rgb(163, 163, 163);
}
.SuperSearch_mainWrapper__2HGp0 {
  position: relative;
}

.SuperSearch_table__1XvlT {
  margin-bottom: 1rem;
  border-color: #dee2e6;
  color: #626569;
  height: inherit;
  border: 0px solid #ccc;
  border-top: 0px;
  display: block;
  width: 100%;
  overflow-x: auto;
}

.SuperSearch_tableWrapper__ohmAG {
  z-index: 2;
  background: #faf8f8;
  border: 1px solid #036bb4;
  box-shadow: 0 7px 10px rgb(0 0 0 / 40%);
  height: 100%;
  min-height: 100px;
  position: absolute;
}

.SuperSearch_table_head__2WVNR {
  border-top: 1px solid #eaecec;
  border-bottom: 1px solid #eaecec;
  background-color: var(--secondaryColor) !important;
  display: block;
}

.SuperSearch_table_head__2WVNR tr th {
  white-space: nowrap;
  border-color:white;
  color: white;
  font-size: 12px;
  word-wrap: break-word;
  padding: 0.5rem 0.5rem;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
}

.SuperSearch_table_body__2MZ7U {
  display: block;
  background-color: #fff;
}

.SuperSearch_table_body__2MZ7U tr {
  display: flex;
  cursor: pointer;
}

.SuperSearch_table_body__2MZ7U tr:hover {
  background-color: var(--primary-gray);
}

.SuperSearch_table_body__2MZ7U tr td {
  border-color: #c5c5c5;
  font-size: 12px;
  word-wrap: break-word;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 50px;
  padding: 0.5rem 0.5rem;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
}

.SuperSearch_flexGrow__3TZ76 {
  flex-grow: 1;
}

.SuperSearch_selected_row__nKi2A {
  background-color: #f4f0e9;
  font-weight: 700;
}
.InputVariants_varriant1__1qTuG {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
}

.InputVariants_varriant1_input__3P1Gw {
  border: 1px solid #d9d9d9;
  padding: 16px 10px;
  border-right: none;
  font-size: 0.7rem;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 97%;
  height: 26px;
}

.InputVariants_varriant1_input__3P1Gw:focus {
  outline: none;
}

.InputVariants_varriant1_icon__1Qd8Y {
  min-width: 28px;
  border: 1px solid #d9d9d9;
  border-left: none;
  /* padding: 3px 8px; */
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.InputVariants_varriant1_icon__1Qd8Y img {
  width: 13px;
  height: 13px;
}

/* bootstrap css */
.tp_row {
  display: flex;
  flex-wrap: wrap;
}
.tp_col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.tp_col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.tp_col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.tp_col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.tp_position-relative {
  position: relative !important;
}
.tp_d-flex {
  display: flex !important;
}

.tp_h-100 {
  height: 100% !important;
}
.tp_w-100 {
  width: 100% !important;
}
.tp_text-center {
  text-align: center;
}
.tp_align-items-center {
  align-items: center !important;
}
.tp_justify-content-center {
  justify-content: center !important;
}
.tp_justify-content-end {
  justify-content: flex-end !important;
}
.tp_justify-content-evenly {
  justify-content: space-evenly !important;
}
/* bootstrap css ENDDDDDDDDDDDDDDDDDDDDD */

/*  HOUR AND MINUTES INPUT CSS */

.hours_minute_input {
  width: 40px;
    height: 37px;
    min-height: 20px;
    border: none;
    padding: 0px;
    margin: 0px;
    color: black;
    text-align: center;
    border-radius: 4px;
    background: white;
    font-weight: 300;
    line-height: 1.2;
    transition: all 0.5s;
    vertical-align: middle;
    font-size: 24px !important;
}
.hours_minute_input:read-only {
  cursor: pointer;
  background: transparent;
  color: #036bb4;
}
.hours_minute_input:hover {
  background: #036bb4;
  color: #fff;
}
.hours_minute_input:focus-visible {
  outline: none;
}

/* HOUR AND MINUTES INPUT ******** END ****** */

.form-outline {
  position: relative;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
.timepicker-input {
  min-height: auto;
  padding: 0.33em 0.75em;
  border: 0;
  background: transparent;
  transition: all 0.2s linear;
  display: block;
  width: -webkit-fill-available;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  -webkit-appearance: none;
          appearance: none;
  border-radius: 0.25rem;
}

.timepicker-toggle-button:hover {
  color: #2979ff;
}
.timepicker-toggle-button {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  right: -10px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  cursor: pointer;
}
.timepicker-wrapper {
  padding: 3px 2px;
  position: absolute;
  touch-action: none;
  z-index: 1065;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  color: #036bb4;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  box-sizing: border-box;
  height: 75px !important;
  border: 1px solid #036bb4;
}

.timepicker-current {
  font-weight: 300;
  line-height: 1.2;
  color: #000;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 24px !important;
}
.timepicker_wrapper_icon {
  /* transition: all 0.2s ease;
  cursor: pointer;
  transform: translate(-50%, -50%); */
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 8px;
  display: flex;
  justify-content: center;
}
.timepicker_wrapper_icon_up {
  top: -7px;
}
.timepicker_wrapper_icon_down {
  bottom: -7px;
}

.timepicker_icon {
  opacity: 1;
  cursor: pointer;
  transition: all 0.35s;
}
.timepicker_icon:hover {
  opacity: 1;
}

.timepicker-dots {
  margin: 0;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.00833em;
}
.ok_btn {
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: #036bb4;
  font-size: 18px !important;
    padding: 5px !important;
}
.ok_btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.chevroncolor
{
  color: #cceaff;
}
.chevroncolor:hover
{
  color: #036bb4;
}
.errordiv {
  position: absolute;
  color: #f93154;
  font-size: 0.875rem;
  z-index: 1064;
  background: white;
  width: 100%;
}

.LockPortal_practicetitle__krYrJ {
    font-size: 18px;
    font-weight: bold;
}

.LockPortal_overlay01__12vk8 {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111111;
}

.LockPortal_overlaycontent01__nn-1v {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: auto;
    min-height: 300px;
    background-color: #fff;
    color: #fff;
    margin: 220px auto;
}

.LockPortal_overlayinner__2MRxj {
    width: 100%;
    padding: 20px;
    color: var(--font-color);
}

.LockPortal_bluenewheading__Olf-N {
    font-size: 20px;
    font-weight: bold;
    color: #036bb4;
}

.LockPortal_lockiconcenter__2RFKi {
    display: flex;
    justify-content: center;
    align-items: center;
}

.LockPortal_icon150__2lJci {
    height: 140px;
}

.LockPortal_icon100__b5pqH {
    height: 100px;
}

.LockPortal_icontxtsize__13VK4 {
    font-size: 14px;
}

.LockPortal_loaderbgpopup__3W89T {
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;
}

.LockPortal_loaderdivpopup__S_-Fw {
    border: 2px solid #ebeef0;
    border-radius: 50%;
    border-top: 2px solid #00a7a9;
    width: 30px;
    height: 30px;
    animation: LockPortal_spin__wwe7f 2s linear infinite;
    position: relative;
    margin: auto;
}

@keyframes LockPortal_spin__wwe7f {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
@font-face {
  font-family: "simple-Line-Icons";
  src: url(/static/media/simple-Line-Icons.37c20767.eot);
  src: url(/static/media/simple-Line-Icons.37c20767.eot) format("embedded-opentype"),
    url(/static/media/simple-Line-Icons.d25156c9.woff) format("woff"),
    url(/static/media/simple-Line-Icons.549254fc.ttf) format("truetype"),
    url(/static/media/simple-Line-Icons.020f280b.svg#Simple-Line-Icons) format("svg");
  font-weight: normal;
  font-style: normal;
}
[data-icon]:before {
  font-family: "simple-Line-Icons";
  content: attr(data-icon);
  speak: none;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 17px;
}
.icon-user-female,
.icon-user-follow,
.icon-user-following,
.icon-user-unfollow,
.icon-trophy,
.icon-screen-smartphone,
.icon-screen-desktop,
.icon-plane,
.icon-notebook,
.icon-moustache,
.icon-mouse,
.icon-magnet,
.icon-energy,
.icon-emoticon-smile,
.icon-disc,
.icon-cursor-move,
.icon-crop,
.icon-credit-card,
.icon-chemistry,
.icon-user,
.icon-speedometer,
.icon-social-youtube,
.icon-social-twitter,
.icon-social-tumblr,
.icon-social-facebook,
.icon-social-dropbox,
.icon-social-dribbble,
.icon-shield,
.icon-screen-tablet,
.icon-magic-wand,
.icon-hourglass,
.icon-graduation,
.icon-ghost,
.icon-game-controller,
.icon-fire,
.icon-eyeglasses,
.icon-envelope-open,
.icon-envelope-letter,
.icon-bell,
.icon-badge,
.icon-anchor,
.icon-wallet,
.icon-vector,
.icon-speech,
.icon-puzzle,
.icon-printer,
.icon-present,
.icon-playlist,
.icon-pin,
.icon-picture,
.icon-map,
.icon-layers,
.icon-handbag,
.icon-globe-alt,
.icon-globe,
.icon-frame,
.icon-folder-alt,
.icon-film,
.icon-feed,
.icon-earphones-alt,
.icon-earphones,
.icon-drop,
.icon-drawer,
.icon-docs,
.icon-directions,
.icon-direction,
.icon-diamond,
.icon-cup,
.icon-compass,
.icon-call-out,
.icon-call-in,
.icon-call-end,
.icon-calculator,
.icon-bubbles,
.icon-briefcase,
.icon-book-open,
.icon-basket-loaded,
.icon-basket,
.icon-bag,
.icon-action-undo,
.icon-action-redo,
.icon-wrench,
.icon-umbrella,
.icon-trash,
.icon-tag,
.icon-support,
.icon-size-fullscreen,
.icon-size-actual,
.icon-shuffle,
.icon-share-alt,
.icon-share,
.icon-rocket,
.icon-question,
.icon-pie-chart,
.icon-pencil,
.icon-note,
.icon-music-tone-alt,
.icon-music-tone,
.icon-microphone,
.icon-loop,
.icon-logout,
.icon-login,
.icon-list,
.icon-like,
.icon-home,
.icon-grid,
.icon-graph,
.icon-equalizer,
.icon-dislike,
.icon-cursor,
.icon-control-start,
.icon-control-rewind,
.icon-control-play,
.icon-control-pause,
.icon-control-forward,
.icon-control-end,
.icon-calendar,
.icon-bulb,
.icon-bar-chart,
.icon-arrow-up,
.icon-arrow-right,
.icon-arrow-left,
.icon-arrow-down,
.icon-ban,
.icon-bubble,
.icon-camcorder,
.icon-camera,
.icon-check,
.icon-clock,
.icon-close,
.icon-cloud-download,
.icon-cloud-upload,
.icon-doc,
.icon-envelope,
.icon-eye,
.icon-flag,
.icon-folder,
.icon-heart,
.icon-info,
.icon-key,
.icon-link,
.icon-lock,
.icon-lock-open,
.icon-magnifier,
.icon-magnifier-add,
.icon-magnifier-remove,
.icon-paper-clip,
.icon-paper-plane,
.icon-plus,
.icon-pointer,
.icon-power,
.icon-refresh,
.icon-reload,
.icon-settings,
.icon-star,
.icon-symbol-female,
.icon-symbol-male,
.icon-target,
.icon-volume-1,
.icon-volume-2,
.icon-volume-off,
.icon-users {
  font-family: "Simple-Line-Icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
}
.icon-user-female:before {
  content: "\e000";
}
.icon-user-follow:before {
  content: "\e002";
}
.icon-user-following:before {
  content: "\e003";
}
.icon-user-unfollow:before {
  content: "\e004";
}
.icon-trophy:before {
  content: "\e006";
}
.icon-screen-smartphone:before {
  content: "\e010";
}
.icon-screen-desktop:before {
  content: "\e011";
}
.icon-plane:before {
  content: "\e012";
}
.icon-notebook:before {
  content: "\e013";
}
.icon-moustache:before {
  content: "\e014";
}
.icon-mouse:before {
  content: "\e015";
}
.icon-magnet:before {
  content: "\e016";
}
.icon-energy:before {
  content: "\e020";
}
.icon-emoticon-smile:before {
  content: "\e021";
}
.icon-disc:before {
  content: "\e022";
}
.icon-cursor-move:before {
  content: "\e023";
}
.icon-crop:before {
  content: "\e024";
}
.icon-credit-card:before {
  content: "\e025";
}
.icon-chemistry:before {
  content: "\e026";
}
.icon-user:before {
  content: "\e005";
}
.icon-speedometer:before {
  content: "\e007";
}
.icon-social-youtube:before {
  content: "\e008";
}
.icon-social-twitter:before {
  content: "\e009";
}
.icon-social-tumblr:before {
  content: "\e00a";
}
.icon-social-facebook:before {
  content: "\e00b";
}
.icon-social-dropbox:before {
  content: "\e00c";
}
.icon-social-dribbble:before {
  content: "\e00d";
}
.icon-shield:before {
  content: "\e00e";
}
.icon-screen-tablet:before {
  content: "\e00f";
}
.icon-magic-wand:before {
  content: "\e017";
}
.icon-hourglass:before {
  content: "\e018";
}
.icon-graduation:before {
  content: "\e019";
}
.icon-ghost:before {
  content: "\e01a";
}
.icon-game-controller:before {
  content: "\e01b";
}
.icon-fire:before {
  content: "\e01c";
}
.icon-eyeglasses:before {
  content: "\e01d";
}
.icon-envelope-open:before {
  content: "\e01e";
}
.icon-envelope-letter:before {
  content: "\e01f";
}
.icon-bell:before {
  content: "\e027";
}
.icon-badge:before {
  content: "\e028";
}
.icon-anchor:before {
  content: "\e029";
}
.icon-wallet:before {
  content: "\e02a";
}
.icon-vector:before {
  content: "\e02b";
}
.icon-speech:before {
  content: "\e02c";
}
.icon-puzzle:before {
  content: "\e02d";
}
.icon-printer:before {
  content: "\e02e";
}
.icon-present:before {
  content: "\e02f";
}
.icon-playlist:before {
  content: "\e030";
}
.icon-pin:before {
  content: "\e031";
}
.icon-picture:before {
  content: "\e032";
}
.icon-map:before {
  content: "\e033";
}
.icon-layers:before {
  content: "\e034";
}
.icon-handbag:before {
  content: "\e035";
}
.icon-globe-alt:before {
  content: "\e036";
}
.icon-globe:before {
  content: "\e037";
}
.icon-frame:before {
  content: "\e038";
}
.icon-folder-alt:before {
  content: "\e039";
}
.icon-film:before {
  content: "\e03a";
}
.icon-feed:before {
  content: "\e03b";
}
.icon-earphones-alt:before {
  content: "\e03c";
}
.icon-earphones:before {
  content: "\e03d";
}
.icon-drop:before {
  content: "\e03e";
}
.icon-drawer:before {
  content: "\e03f";
}
.icon-docs:before {
  content: "\e040";
}
.icon-directions:before {
  content: "\e041";
}
.icon-direction:before {
  content: "\e042";
}
.icon-diamond:before {
  content: "\e043";
}
.icon-cup:before {
  content: "\e044";
}
.icon-compass:before {
  content: "\e045";
}
.icon-call-out:before {
  content: "\e046";
}
.icon-call-in:before {
  content: "\e047";
}
.icon-call-end:before {
  content: "\e048";
}
.icon-calculator:before {
  content: "\e049";
}
.icon-bubbles:before {
  content: "\e04a";
}
.icon-briefcase:before {
  content: "\e04b";
}
.icon-book-open:before {
  content: "\e04c";
}
.icon-basket-loaded:before {
  content: "\e04d";
}
.icon-basket:before {
  content: "\e04e";
}
.icon-bag:before {
  content: "\e04f";
}
.icon-action-undo:before {
  content: "\e050";
}
.icon-action-redo:before {
  content: "\e051";
}
.icon-wrench:before {
  content: "\e052";
}
.icon-umbrella:before {
  content: "\e053";
}
.icon-trash:before {
  content: "\e054";
}
.icon-tag:before {
  content: "\e055";
}
.icon-support:before {
  content: "\e056";
}
.icon-size-fullscreen:before {
  content: "\e057";
}
.icon-size-actual:before {
  content: "\e058";
}
.icon-shuffle:before {
  content: "\e059";
}
.icon-share-alt:before {
  content: "\e05a";
}
.icon-share:before {
  content: "\e05b";
}
.icon-rocket:before {
  content: "\e05c";
}
.icon-question:before {
  content: "\e05d";
}
.icon-pie-chart:before {
  content: "\e05e";
}
.icon-pencil:before {
  content: "\e05f";
}
.icon-note:before {
  content: "\e060";
}
.icon-music-tone-alt:before {
  content: "\e061";
}
.icon-music-tone:before {
  content: "\e062";
}
.icon-microphone:before {
  content: "\e063";
}
.icon-loop:before {
  content: "\e064";
}
.icon-logout:before {
  content: "\e065";
}
.icon-login:before {
  content: "\e066";
}
.icon-list:before {
  content: "\e067";
}
.icon-like:before {
  content: "\e068";
}
.icon-home:before {
  content: "\e069";
}
.icon-grid:before {
  content: "\e06a";
}
.icon-graph:before {
  content: "\e06b";
}
.icon-equalizer:before {
  content: "\e06c";
}
.icon-dislike:before {
  content: "\e06d";
}
.icon-cursor:before {
  content: "\e06e";
}
.icon-control-start:before {
  content: "\e06f";
}
.icon-control-rewind:before {
  content: "\e070";
}
.icon-control-play:before {
  content: "\e071";
}
.icon-control-pause:before {
  content: "\e072";
}
.icon-control-forward:before {
  content: "\e073";
}
.icon-control-end:before {
  content: "\e074";
}
.icon-calendar:before {
  content: "\e075";
}
.icon-bulb:before {
  content: "\e076";
}
.icon-bar-chart:before {
  content: "\e077";
}
.icon-arrow-up:before {
  content: "\e078";
}
.icon-arrow-right:before {
  content: "\e079";
}
.icon-arrow-left:before {
  content: "\e07a";
}
.icon-arrow-down:before {
  content: "\e07b";
}
.icon-ban:before {
  content: "\e07c";
}
.icon-bubble:before {
  content: "\e07d";
}
.icon-camcorder:before {
  content: "\e07e";
}
.icon-camera:before {
  content: "\e07f";
}
.icon-check:before {
  content: "\e080";
}
.icon-clock:before {
  content: "\e081";
}
.icon-close:before {
  content: "\e082";
}
.icon-cloud-download:before {
  content: "\e083";
}
.icon-cloud-upload:before {
  content: "\e084";
}
.icon-doc:before {
  content: "\e085";
}
.icon-envelope:before {
  content: "\e086";
}
.icon-eye:before {
  content: "\e087";
}
.icon-flag:before {
  content: "\e088";
}
.icon-folder:before {
  content: "\e089";
}
.icon-heart:before {
  content: "\e08a";
}
.icon-info:before {
  content: "\e08b";
}
.icon-key:before {
  content: "\e08c";
}
.icon-link:before {
  content: "\e08d";
}
.icon-lock:before {
  content: "\e08e";
}
.icon-lock-open:before {
  content: "\e08f";
}
.icon-magnifier:before {
  content: "\e090";
}
.icon-magnifier-add:before {
  content: "\e091";
}
.icon-magnifier-remove:before {
  content: "\e092";
}
.icon-paper-clip:before {
  content: "\e093";
}
.icon-paper-plane:before {
  content: "\e094";
}
.icon-plus:before {
  content: "\e095";
}
.icon-pointer:before {
  content: "\e096";
}
.icon-power:before {
  content: "\e097";
}
.icon-refresh:before {
  content: "\e098";
}
.icon-reload:before {
  content: "\e099";
}
.icon-settings:before {
  content: "\e09a";
}
.icon-star:before {
  content: "\e09b";
}
.icon-symbol-female:before {
  content: "\e09c";
}
.icon-symbol-male:before {
  content: "\e09d";
}
.icon-target:before {
  content: "\e09e";
}
.icon-volume-1:before {
  content: "\e09f";
}
.icon-volume-2:before {
  content: "\e0a0";
}
.icon-volume-off:before {
  content: "\e0a1";
}
.icon-users:before {
  content: "\e001";
}
@font-face {
  font-family: "weathericons";
  src: url(/static/media/weathericons-regular-webfont.db8e6bec.eot);
  src: url(/static/media/weathericons-regular-webfont.db8e6bec.eot?#iefix)
      format("embedded-opentype"),
    url(/static/media/weathericons-regular-webfont.e4d2c00e.woff) format("woff"),
    url(/static/media/weathericons-regular-webfont.520c26b1.ttf) format("truetype"),
    url(/static/media/weathericons-regular-webfont.3726e04a.svg#weathericons-regular-webfontRg)
      format("svg");
  font-weight: normal;
  font-style: normal;
}
.wi {
  display: inline-block;
  font-family: "weathericons";
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.wi-day-cloudy-gusts:before {
  content: "\f000";
}
.wi-day-cloudy-windy:before {
  content: "\f001";
}
.wi-day-cloudy:before {
  content: "\f002";
}
.wi-day-fog:before {
  content: "\f003";
}
.wi-day-hail:before {
  content: "\f004";
}
.wi-day-lightning:before {
  content: "\f005";
}
.wi-day-rain-mix:before {
  content: "\f006";
}
.wi-day-rain-wind:before {
  content: "\f007";
}
.wi-day-rain:before {
  content: "\f008";
}
.wi-day-showers:before {
  content: "\f009";
}
.wi-day-snow:before {
  content: "\f00a";
}
.wi-day-sprinkle:before {
  content: "\f00b";
}
.wi-day-sunny-overcast:before {
  content: "\f00c";
}
.wi-day-sunny:before {
  content: "\f00d";
}
.wi-day-storm-showers:before {
  content: "\f00e";
}
.wi-day-thunderstorm:before {
  content: "\f010";
}
.wi-cloudy-gusts:before {
  content: "\f011";
}
.wi-cloudy-windy:before {
  content: "\f012";
}
.wi-cloudy:before {
  content: "\f013";
}
.wi-fog:before {
  content: "\f014";
}
.wi-hail:before {
  content: "\f015";
}
.wi-lightning:before {
  content: "\f016";
}
.wi-rain-mix:before {
  content: "\f017";
}
.wi-rain-wind:before {
  content: "\f018";
}
.wi-rain:before {
  content: "\f019";
}
.wi-showers:before {
  content: "\f01a";
}
.wi-snow:before {
  content: "\f01b";
}
.wi-sprinkle:before {
  content: "\f01c";
}
.wi-storm-showers:before {
  content: "\f01d";
}
.wi-thunderstorm:before {
  content: "\f01e";
}
.wi-windy:before {
  content: "\f021";
}
.wi-night-alt-cloudy-gusts:before {
  content: "\f022";
}
.wi-night-alt-cloudy-windy:before {
  content: "\f023";
}
.wi-night-alt-hail:before {
  content: "\f024";
}
.wi-night-alt-lightning:before {
  content: "\f025";
}
.wi-night-alt-rain-mix:before {
  content: "\f026";
}
.wi-night-alt-rain-wind:before {
  content: "\f027";
}
.wi-night-alt-rain:before {
  content: "\f028";
}
.wi-night-alt-showers:before {
  content: "\f029";
}
.wi-night-alt-snow:before {
  content: "\f02a";
}
.wi-night-alt-sprinkle:before {
  content: "\f02b";
}
.wi-night-alt-storm-showers:before {
  content: "\f02c";
}
.wi-night-alt-thunderstorm:before {
  content: "\f02d";
}
.wi-night-clear:before {
  content: "\f02e";
}
.wi-night-cloudy-gusts:before {
  content: "\f02f";
}
.wi-night-cloudy-windy:before {
  content: "\f030";
}
.wi-night-cloudy:before {
  content: "\f031";
}
.wi-night-hail:before {
  content: "\f032";
}
.wi-night-lightning:before {
  content: "\f033";
}
.wi-night-rain-mix:before {
  content: "\f034";
}
.wi-night-rain-wind:before {
  content: "\f035";
}
.wi-night-rain:before {
  content: "\f036";
}
.wi-night-showers:before {
  content: "\f037";
}
.wi-night-snow:before {
  content: "\f038";
}
.wi-night-sprinkle:before {
  content: "\f039";
}
.wi-night-storm-showers:before {
  content: "\f03a";
}
.wi-night-thunderstorm:before {
  content: "\f03b";
}
.wi-celsius:before {
  content: "\f03c";
}
.wi-cloud-down:before {
  content: "\f03d";
}
.wi-cloud-refresh:before {
  content: "\f03e";
}
.wi-cloud-up:before {
  content: "\f040";
}
.wi-cloud:before {
  content: "\f041";
}
.wi-degrees:before {
  content: "\f042";
}
.wi-down-left:before {
  content: "\f043";
}
.wi-down:before {
  content: "\f044";
}
.wi-fahrenheit:before {
  content: "\f045";
}
.wi-horizon-alt:before {
  content: "\f046";
}
.wi-horizon:before {
  content: "\f047";
}
.wi-left:before {
  content: "\f048";
}
.wi-lightning:before {
  content: "\f016";
}
.wi-night-fog:before {
  content: "\f04a";
}
.wi-refresh-alt:before {
  content: "\f04b";
}
.wi-refresh:before {
  content: "\f04c";
}
.wi-right:before {
  content: "\f04d";
}
.wi-sprinkles:before {
  content: "\f04e";
}
.wi-strong-wind:before {
  content: "\f050";
}
.wi-sunrise:before {
  content: "\f051";
}
.wi-sunset:before {
  content: "\f052";
}
.wi-thermometer-exterior:before {
  content: "\f053";
}
.wi-thermometer-internal:before {
  content: "\f054";
}
.wi-thermometer:before {
  content: "\f055";
}
.wi-tornado:before {
  content: "\f056";
}
.wi-up-right:before {
  content: "\f057";
}
.wi-up:before {
  content: "\f058";
}
.wi-wind-west:before {
  content: "\f059";
}
.wi-wind-south-west:before {
  content: "\f05a";
}
.wi-wind-south-east:before {
  content: "\f05b";
}
.wi-wind-south:before {
  content: "\f05c";
}
.wi-wind-north-west:before {
  content: "\f05d";
}
.wi-wind-north-east:before {
  content: "\f05e";
}
.wi-wind-north:before {
  content: "\f060";
}
.wi-wind-east:before {
  content: "\f061";
}
.wi-smoke:before {
  content: "\f062";
}
.wi-dust:before {
  content: "\f063";
}
.wi-snow-wind:before {
  content: "\f064";
}
.wi-day-snow-wind:before {
  content: "\f065";
}
.wi-night-snow-wind:before {
  content: "\f066";
}
.wi-night-alt-snow-wind:before {
  content: "\f067";
}
.wi-day-sleet-storm:before {
  content: "\f068";
}
.wi-night-sleet-storm:before {
  content: "\f069";
}
.wi-night-alt-sleet-storm:before {
  content: "\f06a";
}
.wi-day-snow-thunderstorm:before {
  content: "\f06b";
}
.wi-night-snow-thunderstorm:before {
  content: "\f06c";
}
.wi-night-alt-snow-thunderstorm:before {
  content: "\f06d";
}
.wi-solar-eclipse:before {
  content: "\f06e";
}
.wi-lunar-eclipse:before {
  content: "\f070";
}
.wi-meteor:before {
  content: "\f071";
}
.wi-hot:before {
  content: "\f072";
}
.wi-hurricane:before {
  content: "\f073";
}
.wi-smog:before {
  content: "\f074";
}
.wi-alien:before {
  content: "\f075";
}
.wi-snowflake-cold:before {
  content: "\f076";
}
.wi-stars:before {
  content: "\f077";
}
.wi-night-partly-cloudy:before {
  content: "\f083";
}
.wi-umbrella:before {
  content: "\f084";
}
.wi-day-windy:before {
  content: "\f085";
}
.wi-night-alt-cloudy:before {
  content: "\f086";
}
.wi-up-left:before {
  content: "\f087";
}
.wi-down-right:before {
  content: "\f088";
}
.wi-time-12:before {
  content: "\f089";
}
.wi-time-1:before {
  content: "\f08a";
}
.wi-time-2:before {
  content: "\f08b";
}
.wi-time-3:before {
  content: "\f08c";
}
.wi-time-4:before {
  content: "\f08d";
}
.wi-time-5:before {
  content: "\f08e";
}
.wi-time-6:before {
  content: "\f08f";
}
.wi-time-7:before {
  content: "\f090";
}
.wi-time-8:before {
  content: "\f091";
}
.wi-time-9:before {
  content: "\f092";
}
.wi-time-10:before {
  content: "\f093";
}
.wi-time-11:before {
  content: "\f094";
}
.wi-day-sleet:before {
  content: "\f0b2";
}
.wi-night-sleet:before {
  content: "\f0b3";
}
.wi-night-alt-sleet:before {
  content: "\f0b4";
}
.wi-sleet:before {
  content: "\f0b5";
}
.wi-day-haze:before {
  content: "\f0b6";
}
.wi-beafort-0:before {
  content: "\f0b7";
}
.wi-beafort-1:before {
  content: "\f0b8";
}
.wi-beafort-2:before {
  content: "\f0b9";
}
.wi-beafort-3:before {
  content: "\f0ba";
}
.wi-beafort-4:before {
  content: "\f0bb";
}
.wi-beafort-5:before {
  content: "\f0bc";
}
.wi-beafort-6:before {
  content: "\f0bd";
}
.wi-beafort-7:before {
  content: "\f0be";
}
.wi-beafort-8:before {
  content: "\f0bf";
}
.wi-beafort-9:before {
  content: "\f0c0";
}
.wi-beafort-10:before {
  content: "\f0c1";
}
.wi-beafort-11:before {
  content: "\f0c2";
}
.wi-beafort-12:before {
  content: "\f0c3";
}
.wi-wind-default:before {
  content: "\f0b1";
}
.wi-wind-default._0-deg {
  transform: rotate(0deg);
}
.wi-wind-default._15-deg {
  transform: rotate(15deg);
}
.wi-wind-default._30-deg {
  transform: rotate(30deg);
}
.wi-wind-default._45-deg {
  transform: rotate(45deg);
}
.wi-wind-default._60-deg {
  transform: rotate(60deg);
}
.wi-wind-default._75-deg {
  transform: rotate(75deg);
}
.wi-wind-default._90-deg {
  transform: rotate(90deg);
}
.wi-wind-default._105-deg {
  transform: rotate(105deg);
}
.wi-wind-default._120-deg {
  transform: rotate(120deg);
}
.wi-wind-default._135-deg {
  transform: rotate(135deg);
}
.wi-wind-default._150-deg {
  transform: rotate(150deg);
}
.wi-wind-default._165-deg {
  transform: rotate(165deg);
}
.wi-wind-default._180-deg {
  transform: rotate(180deg);
}
.wi-wind-default._195-deg {
  transform: rotate(195deg);
}
.wi-wind-default._210-deg {
  transform: rotate(210deg);
}
.wi-wind-default._225-deg {
  transform: rotate(225deg);
}
.wi-wind-default._240-deg {
  transform: rotate(240deg);
}
.wi-wind-default._255-deg {
  transform: rotate(255deg);
}
.wi-wind-default._270-deg {
  transform: rotate(270deg);
}
.wi-wind-default._285-deg {
  transform: rotate(295deg);
}
.wi-wind-default._300-deg {
  transform: rotate(300deg);
}
.wi-wind-default._315-deg {
  transform: rotate(315deg);
}
.wi-wind-default._330-deg {
  transform: rotate(330deg);
}
.wi-wind-default._345-deg {
  transform: rotate(345deg);
}
.wi-moon-new:before {
  content: "\f095";
}
.wi-moon-waxing-cresent-1:before {
  content: "\f096";
}
.wi-moon-waxing-cresent-2:before {
  content: "\f097";
}
.wi-moon-waxing-cresent-3:before {
  content: "\f098";
}
.wi-moon-waxing-cresent-4:before {
  content: "\f099";
}
.wi-moon-waxing-cresent-5:before {
  content: "\f09a";
}
.wi-moon-waxing-cresent-6:before {
  content: "\f09b";
}
.wi-moon-first-quarter:before {
  content: "\f09c";
}
.wi-moon-waxing-gibbous-1:before {
  content: "\f09d";
}
.wi-moon-waxing-gibbous-2:before {
  content: "\f09e";
}
.wi-moon-waxing-gibbous-3:before {
  content: "\f09f";
}
.wi-moon-waxing-gibbous-4:before {
  content: "\f0a0";
}
.wi-moon-waxing-gibbous-5:before {
  content: "\f0a1";
}
.wi-moon-waxing-gibbous-6:before {
  content: "\f0a2";
}
.wi-moon-full:before {
  content: "\f0a3";
}
.wi-moon-waning-gibbous-1:before {
  content: "\f0a4";
}
.wi-moon-waning-gibbous-2:before {
  content: "\f0a5";
}
.wi-moon-waning-gibbous-3:before {
  content: "\f0a6";
}
.wi-moon-waning-gibbous-4:before {
  content: "\f0a7";
}
.wi-moon-waning-gibbous-5:before {
  content: "\f0a8";
}
.wi-moon-waning-gibbous-6:before {
  content: "\f0a9";
}
.wi-moon-3rd-quarter:before {
  content: "\f0aa";
}
.wi-moon-waning-crescent-1:before {
  content: "\f0ab";
}
.wi-moon-waning-crescent-2:before {
  content: "\f0ac";
}
.wi-moon-waning-crescent-3:before {
  content: "\f0ad";
}
.wi-moon-waning-crescent-4:before {
  content: "\f0ae";
}
.wi-moon-waning-crescent-5:before {
  content: "\f0af";
}
.wi-moon-waning-crescent-6:before {
  content: "\f0b0";
}
.waitMe_container:not(body) {
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
body.waitMe_container .waitMe {
  position: fixed;
  height: 100vh;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
body.waitMe_container .waitMe .waitMe_content {
  top: 50% !important;
  transform: none !important;
}
.waitMe_container .waitMe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9989;
  text-align: center;
  overflow: hidden;
  cursor: wait;
}
.waitMe_container .waitMe * {
  font-family: inherit;
  font-size: inherit;
  font-weight: initial;
  font-style: initial;
  line-height: initial;
  color: initial;
  text-decoration: initial;
  text-transform: initial;
  padding: initial;
  margin: initial;
}
.waitMe_container .waitMe .waitMe_content {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.waitMe_container .waitMe .waitMe_content.horizontal > .waitMe_progress {
  display: inline-block;
  vertical-align: middle;
}
.waitMe_container .waitMe .waitMe_content.horizontal > .waitMe_text {
  display: inline-block;
  margin: 0 0 0 20px;
  vertical-align: middle;
}
.waitMe_container .waitMe .waitMe_progress {
  position: relative;
  font-size: 0;
  line-height: 0;
}
.waitMe_container .waitMe .waitMe_progress > div {
  animation-fill-mode: both;
  display: inline-block;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}
.waitMe_container .waitMe .waitMe_text {
  position: relative;
  margin: 20px 0 0;
}
body.waitMe_body {
  overflow: hidden;
  height: 100%;
}
body.waitMe_body.hideMe {
  transition: opacity 0.2s ease-in-out;
  opacity: 0;
}
body.waitMe_body .waitMe_container:not([data-waitme_id]) {
  position: fixed;
  z-index: 9989;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
}
body.waitMe_body .waitMe_container:not([data-waitme_id]) > div {
  animation-fill-mode: both;
  position: absolute;
}
body.waitMe_body .waitMe_container.progress > div {
  width: 0;
  height: 3px;
  top: 0;
  left: 0;
  background: #000;
  box-shadow: -5px 0 5px 2px rgba(0, 0, 0, 0.2);
  animation: progress_body 7s infinite ease-out;
}
body.waitMe_body .waitMe_container.working > div {
  width: 10%;
  height: 3px;
  top: 0;
  left: -10%;
  background: #000;
  box-shadow: -5px 0 5px 2px rgba(0, 0, 0, 0.2);
  animation: working_body 2s infinite linear;
}
body.waitMe_body .waitMe_container.progress > div:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 60%;
  right: 0;
  width: 60px;
  border-radius: 50%;
  opacity: 0.5;
  transform: rotate(3deg);
  box-shadow: #000 1px 0 6px 1px;
}
body.waitMe_body .waitMe_container.img > div {
  width: 100%;
  height: 100%;
  text-align: center;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
body.waitMe_body .waitMe_container.text > div {
  width: 100%;
  top: 45%;
  text-align: center;
}
@keyframes progress_body {
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
@keyframes working_body {
  0% {
    left: -10%;
  }
  100% {
    left: 100%;
  }
}
.waitMe_container .waitMe_progress.bounce > div {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  animation: bounce 1.4s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem1 {
  animation-delay: -0.32s;
}
.waitMe_container .waitMe_progress.bounce .waitMe_progress_elem2 {
  animation-delay: -0.16s;
}
@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.waitMe_container .waitMe_progress.rotateplane > div {
  width: 30px;
  height: 30px;
  animation: rotateplane 1.2s infinite ease-in-out;
  -webkit-backface-visibility: visible;
          backface-visibility: visible;
}
@keyframes rotateplane {
  0% {
    transform: perspective(120px);
  }
  50% {
    transform: perspective(120px) rotateY(180deg);
  }
  100% {
    transform: perspective(120px) rotateY(180deg) rotateX(180deg);
  }
}
.waitMe_container .waitMe_progress.stretch {
  height: 60px;
}
.waitMe_container .waitMe_progress.stretch > div {
  width: 1px;
  height: 100%;
  margin: 0 4px;
  animation: stretch 1.2s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem2 {
  animation-delay: -1.1s;
}
.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem3 {
  animation-delay: -1s;
}
.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem4 {
  animation-delay: -0.9s;
}
.waitMe_container .waitMe_progress.stretch .waitMe_progress_elem5 {
  animation-delay: -0.8s;
}
@keyframes stretch {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
  }
}
.waitMe_container .waitMe_progress.orbit {
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
}
.waitMe_container .waitMe_progress.orbit > div {
  width: 100%;
  height: 100%;
  margin: auto;
  top: 0;
  left: 0;
  position: absolute;
  animation: orbit_rotate 2s infinite linear;
}
.waitMe_container .waitMe_progress.orbit > div > div {
  width: 50%;
  height: 50%;
  border-radius: 50%;
  top: 10%;
  left: 10%;
  position: absolute;
  animation: orbit 2s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.orbit .waitMe_progress_elem2 > div {
  top: auto;
  bottom: 10%;
  left: auto;
  right: 10%;
  animation-delay: -1s;
}
@keyframes orbit_rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes orbit {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.waitMe_container .waitMe_progress.roundBounce {
  width: 60px;
  height: 60px;
  margin: auto;
}
.waitMe_container .waitMe_progress.roundBounce > div {
  width: 24%;
  height: 24%;
  border-radius: 50%;
  position: absolute;
  animation: roundBounce 1.2s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem1 {
  top: 0;
  left: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
  top: 0;
  right: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
  bottom: 0;
  right: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
  bottom: 0;
  left: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem5 {
  top: -3%;
  left: 50%;
  margin-top: -12%;
  margin-left: -12%;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
  top: 50%;
  right: -3%;
  margin-top: -12%;
  margin-right: -12%;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
  bottom: -3%;
  left: 50%;
  margin-bottom: -12%;
  margin-left: -12%;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
  top: 50%;
  left: -3%;
  margin-top: -12%;
  margin-left: -12%;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem9 {
  top: 0;
  right: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem10 {
  bottom: 0;
  right: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem11 {
  bottom: 0;
  left: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem12 {
  top: 0;
  left: 0;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem5 {
  animation-delay: -1.1s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem9 {
  animation-delay: -1s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem2 {
  animation-delay: -0.9s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem6 {
  animation-delay: -0.8s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem10 {
  animation-delay: -0.7s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem3 {
  animation-delay: -0.6s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem7 {
  animation-delay: -0.5s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem11 {
  animation-delay: -0.4s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem4 {
  animation-delay: -0.3s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem8 {
  animation-delay: -0.2s;
}
.waitMe_container .waitMe_progress.roundBounce .waitMe_progress_elem12 {
  animation-delay: -0.1s;
}
@keyframes roundBounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.waitMe_container .waitMe_progress.win8 {
  width: 40px;
  height: 40px;
  margin: auto;
}
.waitMe_container .waitMe_progress.win8 > div {
  width: 100%;
  height: 100%;
  opacity: 0;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  transform: rotate(225deg);
  animation: win8 5.5s infinite;
}
.waitMe_container .waitMe_progress.win8 > div > div {
  width: 15%;
  height: 15%;
  border-radius: 50%;
  position: absolute;
}
.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem2 {
  animation-delay: 0.24s;
}
.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem3 {
  animation-delay: 0.48s;
}
.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem4 {
  animation-delay: 0.72s;
}
.waitMe_container .waitMe_progress.win8 .waitMe_progress_elem5 {
  animation-delay: 0.96s;
}
@keyframes win8 {
  0% {
    transform: rotate(225deg);
    animation-timing-function: ease-out;
  }
  7% {
    opacity: 1;
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    opacity: 1;
    transform: rotate(815deg);
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    opacity: 0;
    transform: rotate(945deg);
  }
  100% {
    opacity: 0;
    transform: rotate(945deg);
  }
}
.waitMe_container .waitMe_progress.win8_linear {
  margin: auto;
  width: 150px;
  height: 6px;
}
.waitMe_container .waitMe_progress.win8_linear > div {
  width: 100%;
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  animation: win8_linear 3s infinite;
}
.waitMe_container .waitMe_progress.win8_linear > div > div {
  width: 4%;
  height: 100%;
  border-radius: 50%;
}
.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem2 {
  animation-delay: 0.3s;
}
.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem3 {
  animation-delay: 0.6s;
}
.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem4 {
  animation-delay: 0.9s;
}
.waitMe_container .waitMe_progress.win8_linear .waitMe_progress_elem5 {
  animation-delay: 1.2s;
}
@keyframes win8_linear {
  0%,
  100% {
    transform: translateX(0);
    animation-timing-function: ease-out;
  }
  10% {
    opacity: 1;
    transform: translateX(33.333%);
    animation-timing-function: linear;
  }
  50% {
    opacity: 1;
    transform: translateX(53.333%);
    animation-timing-function: ease-in-out;
  }
  60% {
    opacity: 0;
    transform: translateX(86.666%);
  }
}
.waitMe_container .waitMe_progress.ios {
  margin: auto;
  width: 40px;
  height: 40px;
}
.waitMe_container .waitMe_progress.ios > div {
  width: 10%;
  height: 26%;
  position: absolute;
  left: 44.5%;
  top: 37%;
  opacity: 0;
  border-radius: 50px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  animation: ios 1s infinite linear;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem1 {
  transform: rotate(0deg) translate(0, -142%);
  animation-delay: 0s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem2 {
  transform: rotate(30deg) translate(0, -142%);
  animation-delay: -0.9167s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem3 {
  transform: rotate(60deg) translate(0, -142%);
  animation-delay: -0.833s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem4 {
  transform: rotate(90deg) translate(0, -142%);
  animation-delay: -0.75s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem5 {
  transform: rotate(120deg) translate(0, -142%);
  animation-delay: -0.667s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem6 {
  transform: rotate(150deg) translate(0, -142%);
  animation-delay: -0.5833s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem7 {
  transform: rotate(180deg) translate(0, -142%);
  animation-delay: -0.5s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem8 {
  transform: rotate(210deg) translate(0, -142%);
  animation-delay: -0.41667s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem9 {
  transform: rotate(240deg) translate(0, -142%);
  animation-delay: -0.333s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem10 {
  transform: rotate(270deg) translate(0, -142%);
  animation-delay: -0.25s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem11 {
  transform: rotate(300deg) translate(0, -142%);
  animation-delay: -0.1667s;
}
.waitMe_container .waitMe_progress.ios .waitMe_progress_elem12 {
  transform: rotate(330deg) translate(0, -142%);
  animation-delay: -0.0833s;
}
@keyframes ios {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.25;
  }
}
.waitMe_container .waitMe_progress.facebook {
  margin: auto;
}
.waitMe_container .waitMe_progress.facebook > div {
  width: 6px;
  height: 25px;
  margin-left: 3px;
  border-radius: 20px;
  transform: scaleY(0.7);
  opacity: 0.1;
  animation: facebook 1.3s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem1 {
  animation-delay: 0.2s;
  transform: scaleY(0.7);
}
.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem2 {
  animation-delay: 0.4s;
  transform: scaleY(0.85);
}
.waitMe_container .waitMe_progress.facebook > .waitMe_progress_elem3 {
  animation-delay: 0.6s;
  transform: scaleY(1);
}
@keyframes facebook {
  0% {
    transform: scaleY(0.7);
    opacity: 0.1;
  }
  50% {
    transform: scaleY(1);
    opacity: 1;
  }
  100% {
    transform: scaleY(0.7);
    opacity: 0.1;
  }
}
.waitMe_container .waitMe_progress.rotation > div {
  width: 60px;
  height: 60px;
  margin: auto;
  border-radius: 100%;
  border-width: 6px;
  border-style: solid;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  animation: rotation 1s infinite linear;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.waitMe_container .waitMe_progress.timer {
  width: 40px;
  height: 40px;
  margin: auto;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  box-sizing: border-box;
  position: relative;
  text-indent: -9999px;
}
.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem1,
.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem2 {
  border-radius: 3px;
  position: absolute;
  width: 2px;
  height: 48%;
  left: 50%;
  top: 50%;
  margin-left: -1px;
  margin-top: -1px;
  animation: timer 1.25s infinite linear;
  transform-origin: 1px 1px;
}
.waitMe_container .waitMe_progress.timer > .waitMe_progress_elem2 {
  height: 40%;
  animation: timer 15s infinite linear;
}
@keyframes timer {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.waitMe_container .waitMe_progress.pulse {
  width: 30px;
  height: 30px;
  margin: auto;
  position: relative;
}
.waitMe_container .waitMe_progress.pulse > div {
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  position: absolute;
  opacity: 0;
  animation: pulsate 1s infinite ease-out;
}
@keyframes pulsate {
  0% {
    transform: scale(0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
.waitMe_container .waitMe_progress.progressBar {
  width: 200px;
  height: 20px;
  margin: auto;
  background: rgba(0, 0, 0, 0.1);
  padding: 5px;
  border-radius: 20px;
  line-height: 0;
  max-width: 100%;
}
.waitMe_container .waitMe_progress.progressBar > div {
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 20px;
  background-size: 50px 50px;
  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);
  background-image: linear-gradient(
    -45deg,
    rgba(240, 240, 240, 0.4) 26%,
    transparent 25%,
    transparent 51%,
    rgba(240, 240, 240, 0.4) 50%,
    rgba(240, 240, 240, 0.4) 76%,
    transparent 75%,
    transparent
  );
  animation: progressBar 2s linear infinite;
}
@keyframes progressBar {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 50px 50px;
  }
}
.waitMe_container .waitMe_progress.bouncePulse > div {
  width: 20px;
  height: 20px;
  margin-right: 1px;
  display: inline-block;
  border-radius: 50%;
  transform: scale(0.5);
  animation: bouncePulse 1.4s infinite ease-in-out;
}
.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem1,
.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem3 {
  animation-delay: 0.1s;
}
.waitMe_container .waitMe_progress.bouncePulse > .waitMe_progress_elem2 {
  animation-delay: 0.4s;
}
@keyframes bouncePulse {
  0%,
  80%,
  100% {
    transform: scale(0.5);
  }
  40% {
    transform: scale(1);
  }
}
body {
  background-color: #f4f7f9;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #5a5a5a;
  transition: all 0.3s ease-in-out;
}
a,
a:hover,
a:focus {
  color: var(--primaryColor);
  text-decoration: none;
}
.inlineblock,
.card,
.card .header .header-dropdown li,
.navbar-nav,
.navbar-nav > li,
.navbar-nav ul.app_menu li,
#left-sidebar .navbar-brand span,
.sidebar-nav .metismenu > li i,
ul.choose-skin li,
ul.choose-skin li div,
.block-header .breadcrumb,
.block-header .breadcrumb li,
.accordion2 .accordion-thumb::before,
.wizard .actions ul,
.chat-widget li.right .chat-info,
.chat-widget li .chat-info,
.info-box-2 .icon,
.info-box-2 .content,
.weather .days li,
.weather3 .top .wrapper .temp .temp-value,
.weather3 .top .wrapper .temp .deg,
.stats-report .stat-item,
.new_timeline .header .color-overlay .day-number,
.new_timeline .header .color-overlay .date-right,
.new_timeline li div,
.team-info li,
.chart.easy-pie-chart-1,
.c_list .c_name,
.right_chat .media .message,
.right_chat .media .status,
.dt-bootstrap4 .dt-buttons,
.dt-bootstrap4 .dataTables_info,
.dt-bootstrap4 .dt-buttons + .dataTables_filter,
.dt-bootstrap4 .dt-buttons + .dataTables_paginate,
.dt-bootstrap4 .dataTables_info + .dataTables_filter,
.dt-bootstrap4 .dataTables_info + .dataTables_paginate,
.social_icon,
.social-links li,
.chat-app .chat .chat-history .message {
  display: inline-block;
}
.displayblock,
.card .header .header-dropdown li a,
.card .header h2 small,
.navbar-nav ul.app_menu li a,
.navbar-nav ul.app_menu li a span,
#left-sidebar .navbar-brand,
.user-account .user-name,
.user-account .dropdown,
.user-account .dropdown .dropdown-menu a,
.sidebar-nav .metismenu a,
.auth-main .helper-text,
.auth-main .btn-signin-social,
.metric-inline i,
.metric-inline span,
.separator-linethrough:after,
.wizard .actions,
.wizard .actions a,
.wizard .actions a:hover,
.wizard .actions a:active,
.wizard .steps,
.wizard .steps a,
.wizard .steps a:hover,
.wizard .steps a:active,
.chat-widget li .message,
.info-box-2 .chart,
.weather2 .city-selected .temp h2,
.weather .days li .degrees,
.weather3 .top::after,
.weather3 .bottom .forecast li,
.basic-list li,
.right_chat .media .name,
.social_media_table .list-name,
.testimonial4 blockquote,
.testimonial4 blockquote:before,
.testimonial4 blockquote:after,
.member-card .text-pink,
.w_social2 .pw_img:before,
.w_social2 .pw_content .pw_meta small,
.w_social2 .pw_content .pw_meta span,
.w_profile .job_post,
.feeds_widget li .feeds-body,
.feeds_widget li .feeds-body > small,
.timeline-item:before,
.timeline-item:after,
.page-calendar .event-name h4 span,
.page-calendar .event-name p,
.fc .fc-view-container .fc-event:focus .fc-overlay,
.fc .fc-view-container .fc-event:hover .fc-overlay,
.fc .fc-view-container .fc-event:active .fc-overlay,
.pricing3 .pricing-option .price,
.invoice1 .info {
  display: block;
}
.displaynone {
  display: none;
}
.overflowhidden,
.member-card {
  overflow: hidden;
}
.z-index {
  z-index: 9999;
}
.br8,
.navbar-nav .dropdown-menu {
  border-radius: 8px;
}
.p-10 {
  padding: 10px !important;
}
.l-green {
  background: linear-gradient(45deg, #9ce89d, #cdfa7e) !important;
}
.l-pink {
  background: linear-gradient(45deg, pink, #fbc1cb) !important;
}
.l-turquoise {
  background: linear-gradient(45deg, #00ced1, #08e5e8) !important;
}
.l-cyan {
  background: linear-gradient(45deg, #49cdd0, #00bcd4) !important;
}
.l-khaki {
  background: linear-gradient(45deg, khaki, #fdf181) !important;
}
.l-coral {
  background: linear-gradient(45deg, #f08080, #f58787) !important;
}
.l-salmon {
  background: linear-gradient(45deg, #ec74a1, #fbc7c0) !important;
}
.l-blue {
  background: linear-gradient(45deg, #72c2ff, #86f0ff) !important;
}
.l-seagreen {
  background: linear-gradient(45deg, #8ed8ec, #85f7b5) !important;
}
.l-amber {
  background: linear-gradient(45deg, #fda582, #f7cf68) !important;
}
.l-blush {
  background: linear-gradient(45deg, #dd5e89, #f7bb97) !important;
}
.l-parpl {
  background: linear-gradient(45deg, #a890d3, #edbae7) !important;
}
.l-slategray {
  background: linear-gradient(45deg, #708090, #7c8ea0) !important;
}
.xl-pink {
  background: #f9edee !important;
}
.xl-turquoise {
  background: #bdf3f5 !important;
}
.xl-khaki {
  background: #f9f1d8 !important;
}
.xl-parpl {
  background: #efebf4 !important;
}
.xl-salmon {
  background: #ffd4c3 !important;
}
.xl-blue {
  background: #e0eff5 !important;
}
.xl-seagreen {
  background: #ebf2e8 !important;
}
.xl-slategray {
  background: #eceeef !important;
}
.page-title {
  margin-top: 0;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
}
.overlay {
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  z-index: 10;
}
.overlay.open {
  display: block;
}
.modal-header {
  padding: 0.5rem;
}
.modal-footer {
  padding: 0;
}

.add-icon-btn {
  border-radius: 50%;
  height: 27px;
  width: 30px;
  border: 1px solid #00bcd2;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 34px;
  margin-left: 9px;
}
.add-icon-btn i {
  font-size: 14px;
  color: #00bcd2;
  font-weight: 300;
}
/* bootstrap */
.form-group,
label {
  margin-bottom: 0.15rem;
}
/* sidebar Icons */
.lockiconcenter {
  background-color: #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 12px;
  max-width: 230px;
  min-height: 210px;
  margin: 0 20px;
}
/* Payer  */
.payerList {
  width: 100%;
  min-height: 260px;
}
.payerList ul {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
.payerList ul li {
  display: flex;
  border-bottom: 1px solid #ddd;
  padding: 7px 12px;
  color: #0392cf;
}
.payerList ul li:first-child {
  border-top: 1px solid #ddd;
  margin-top: 12px;
}
.payerList ul li:last-child {
  border-bottom: none;
}
.payerListSr {
  width: 27px;
}
.payerBr {
  border-top: 1px solid #ddd;
  padding: 7px 12px;
}
.input-group-prepend,
.input-group-text {
  border-radius: 0 0.25rem 0.25rem 0 !important;
}
.payerContentScreen {
  width: 100%;
  min-height: 260px;
  box-sizing: border-box;
  padding: 12px 16px;
}
.loginleftpicture {
  height: 100vh;
  max-height: 100vh;
  width: auto;
}
.loginleftpicture img {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* .grid-container {
  display: grid;
  column-gap: 4px;
  grid-template-columns: auto auto auto;  
  padding: 10px;
}

.grid-item {
  border: 1px solid rgba(0, 0, 0, 0.8);
  padding: 20px;  
  text-align: center;
} */

@media screen and (max-width: 767px) {
  .hidden-xs {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .hidden-sm {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .hidden-md {
    display: none;
  }
}
*[dir="rtl"] .metismenu .arrow {
  float: left;
}
*[dir="rtl"] .metismenu .plus-times {
  float: left;
}
*[dir="rtl"] .metismenu .plus-minus {
  float: left;
}
.metismenu .arrow {
  float: right;
  line-height: 1.42857;
}
.metismenu .glyphicon.arrow:before {
  content: "\e079";
}
.metismenu .active > a > .glyphicon.arrow:before {
  content: "\e114";
}
.metismenu .fa.arrow:before {
  content: "\f104";
}
.metismenu .active > a > .fa.arrow:before {
  content: "\f107";
}
.metismenu .ion.arrow:before {
  content: "\f3d2";
}
.metismenu .active > a > .ion.arrow:before {
  content: "\f3d0";
}
.metismenu .plus-times {
  float: right;
}
.metismenu .fa.plus-times:before {
  content: "\f067";
}
.metismenu .active > a > .fa.plus-times {
  transform: rotate(45deg);
}
.metismenu .plus-minus {
  float: right;
}
.metismenu .fa.plus-minus:before {
  content: "\f067";
}
.metismenu .active > a > .fa.plus-minus:before {
  content: "\f068";
}
.metismenu .collapse {
  display: none;
}
.metismenu .collapse.in {
  display: block;
}
/* changes in box shadow  */
.layout-fullwidth .sidebar-nav .metismenu > li.active {
  box-shadow: 0px 0px 0px;
}
.layout-fullwidth .sidebar-nav .metismenu > li {
  padding-bottom: 0px;
  border-top: 1px solid #e0dada;
}
.layout-fullwidth .metismenu ul[aria-expanded="true"].collapse.in {
  display: block;
  position: fixed;
  left: 59px;
  background-color: white;

  border-radius: 5px;
  box-shadow: 2px 4px 6px;
}
.layout-fullwidth .metismenu ul[aria-expanded="false"].collapse.in {
  display: none;
  position: fixed;
  left: 70px;
  background-color: white;
  border: 1px solid;
  border-radius: 5px;
}
.layout-fullwidth .collapse.in li {
  border-top: 1px solid #e0d7d7;
}
.metismenu .collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-timing-function: ease;
  transition-duration: 0.35s;
  transition-property: height, visibility;
}
.metismenu .has-arrow {
  position: relative;
}
.metismenu .has-arrow:after {
  position: absolute;
  content: "+";
  right: 1em;
  transition: all 0.3s ease-out;
  color: #4c4c54;
}
*[dir="rtl"] .metismenu .has-arrow::after {
  right: auto;
  left: 1em;
  transform: rotate(135deg) translate(0, -50%);
}
.metismenu .active > .has-arrow::after,
.metismenu .has-arrow[aria-expanded="true"]::after {
  content: "-";
}
*[dir="rtl"] .metismenu .active > .has-arrow::after,
*[dir="rtl"] .metismenu .has-arrow[aria-expanded="true"]::after {
  transform: rotate(225deg) translate(0, -50%);
}
.nav.nav-tabs > li.active > a,
.nav.nav-tabs > li.active > a:hover,
.nav.nav-tabs > li.active > a:focus {
  background-color: #f4f7f6;
  color: #5a5a5a;
}
.nav.nav-tabs > li > a {
  color: inherit;
  font-weight: 600;
}
.nav.nav-tabs > li > a:hover,
.nav.nav-tabs > li > a:focus {
  background-color: #f5f5f5;
}
.nav.nav-tabs-new > li > a {
  color: #777;
  border: 1px solid #eee;
  border-radius: 0.25rem;
  padding: 5px 15px;
  background-color: transparent;
  transition: all 0.3s ease-in-out;
}
.nav.nav-tabs-new > li > a:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.nav.nav-tabs-new > li > a.active {
  border: 1px solid #bdbdbd;
}
.nav.nav-tabs-new2 {
  border-bottom: 1px solid #eee;
}
.nav.nav-tabs-new2 > li > a {
  color: #777;
  padding: 7px 20px;
  background-color: transparent;
  border-bottom: transparent;
  font-size: 15px;
}
.nav.nav-tabs-new2 > li > a:hover,
.nav.nav-tabs-new2 > li > a:focus {
  color: #00bcd2;
  border-bottom: 2px solid #00bcd2 !important;
}
.nav.nav-tabs-new2 > li > a.active {
  border-bottom: 2px solid #00bcd2 !important;
  background-color: transparent;
  color: #00bcd2;
}
.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #efefef;
  color: #5a5a5a;
}
.nav-pills > li > a {
  color: inherit;
}
.nav-tabs-colored > li.active > a,
.nav-tabs-colored > li.active > a:hover,
.nav-tabs-colored > li.active > a:focus {
  background-color: #f5f5f5;
  color: #5a5a5a;
}
.badge {
  font-weight: 400;
  padding: 4px 8px;
  text-transform: uppercase;
  line-height: 12px;
  border: 1px solid;
}
.badge-icon {
  padding: 0.4em 0.55em;
}
.badge-icon i {
  font-size: 0.8em;
}
.badge-default {
  background: transparent;
  border-color: #9a9a9a;
  color: #9a9a9a;
}
.badge-primary {
  background: transparent;
  border-color: #00bcd2;
  color: #00bcd2;
}
.badge-info {
  background: transparent;
  border-color: #3c89da;
  color: #3c89da;
}
.badge-success {
  background: transparent;
  border-color: #22af46;
  color: #22af46;
}
.badge-warning {
  background: transparent;
  border-color: #f3ad06;
  color: #f3ad06;
}
.badge-danger {
  background: transparent;
  border-color: #de4848;
  color: #de4848;
}
#main-content {
  transition: all 0.3s ease-in-out;
  width: calc(100% - 250px);
  float: right;
  position: relative;
  margin-top: 65px;
}
@media screen and (max-width: 1200px) {
  #main-content {
    width: 100%;
    padding: 0;
  }
}
@media screen and (max-width: 768px) {
  #main-content {
    margin-top: 52px;
  }
}
#main-content > .container-fluid {
  padding-top: 22px;
  padding-right: 20px;
  padding-left: 25px;
}
@media screen and (max-width: 1200px) {
  #main-content > .container-fluid {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  #main-content > .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}
html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
  height: 100%;
}
.vertical-align-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}
.vertical-align-middle {
  display: table-cell;
  vertical-align: middle;
}
.card {
  transition: all 0.3s ease-in-out;
  background: white;

  border-color: #eee;
  margin-bottom: 30px;
  position: relative;
  width: 100%;
}
.card.fullscreen {
  height: 100%;
  left: 0;
  margin: 0;
  position: fixed;
  overflow-y: auto;
  top: 0;
  width: 100%;
  z-index: 1040;
  border-radius: 0 !important;
}
.card.fullscreen .icon-size-fullscreen::before {
  content: "\e058";
}
.card:hover {
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}
.card .header {
  box-shadow: none;
  color: #444;
  padding: 10px;
  position: relative;
}

.card .header.bline {
  border-bottom: 1px solid #eee;
}
.card .header .header-dropdown {
  position: absolute;
  top: 4px;
  right: 3px;
  list-style: none;
}
.card .header .header-dropdown li a {
  padding: 5px 10px;
  color: #999;
}
.card .header .header-dropdown li a.dropdown-toggle:after {
  border: none;
  content: "\f141";
  font-family: "FontAwesome";
  vertical-align: text-top;
  width: 11px;
}
.card .header .header-dropdown li .dropdown-menu {
  border-radius: 0.55rem;
  overflow: hidden;
  right: 0;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  border: 0;
}
.card .header .header-dropdown li .dropdown-menu li {
  display: block !important;
}
.card .header .header-dropdown li .dropdown-menu li a {
  color: #777;
  padding: 10px;
  white-space: nowrap;
}
.card .header .header-dropdown li .dropdown-menu li a:hover {
  color: #444;
}
.card .header h2 {
  font-weight: 600;
  color: #380e47;
  position: relative;
}
.card .header h2 small {
  color: #999;
  line-height: 15px;
}
.card .header h2 small a {
  font-weight: 700;
  color: #777;
}
.card .p-15 {
  padding: 15px !important;
}
.card .body {
  font-weight: 400;
  color: #444;
  padding: 20px;
}
@media screen and (max-width: 1200px) {
  .btn-toggle-fullwidth {
    display: none;
  }
}
.navbar-fixed-top {
  transition: all 0.3s ease-in-out;
  background: #fff;
  position: fixed;
  top: 0px;
  right: 0;
  width: calc(100% - 250px);
  z-index: 8;
  padding: 7px 10px;
  border-bottom: 1px solid #eee;
}
@media screen and (max-width: 1200px) {
  .navbar-fixed-top {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .navbar-fixed-top {
    padding: 0;
  }
}
.navbar-fixed-top .navbar-btn {
  display: none;
  float: left;
  border-right: 1px solid #fff;
}
.navbar-fixed-top .navbar-btn button {
  font-size: 20px;
  line-height: 0.7;
  border: none;
  background: none;
  outline: none;
  color: #fff;
  vertical-align: middle;
}
.navbar-fixed-top .navbar-btn .logo {
  width: 32px;
}
@media screen and (max-width: 640px) {
  .navbar-fixed-top .navbar-btn {
    margin-left: 0;
  }
}
@media screen and (max-width: 1200px) {
  .navbar-fixed-top .navbar-btn {
    display: block;
  }
}
.navbar-fixed-top .navbar-right {
  display: inline-flex;
}
.navbar-fixed-top .navbar-right #navbar-search {
  float: left;
  margin-top: 6px;
}
.navbar-fixed-top .navbar-right #navbar-search .form-control {
  height: 40px;
  width: 400px;
}
@media screen and (max-width: 992px) {
  .navbar-fixed-top .navbar-right #navbar-search .form-control {
    width: 250px;
  }
}
.navbar-fixed-top .navbar-right #navbar-search i {
  font-size: 12px;
}
@media screen and (max-width: 767px) {
  .navbar-fixed-top .navbar-right #navbar-search {
    display: none;
  }
}
.navbar-nav > li {
  vertical-align: middle;
}
.navbar-nav > li a {
  color: #fff;
}
.navbar-nav .dropdown-menu {
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.2);
  top: 80%;
  position: absolute;
  border: 1px solid #fff;
  padding: 20px;
  font-size: inherit;
}
.navbar-nav .icon-menu {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  padding: 15px;
  position: relative;
}
@media screen and (max-width: 767px) {
  .navbar-nav .icon-menu {
    padding: 15px 12px;
  }
}
.navbar-nav .icon-menu i {
  color: #666;
}
.navbar-nav .icon-menu::after {
  display: none;
}
.navbar-nav .icon-menu .notification-dot {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  position: absolute;
  top: 12px;
  right: 7px;
  background-color: #17191c;
}
.navbar-nav ul.email {
  width: 360px;
  list-style: none;
}
@media screen and (max-width: 768px) {
  .navbar-nav ul.email {
    width: calc(100% - 20px);
    margin: 0 10px;
  }
}
.navbar-nav ul.app_menu {
  width: 226px;
}
.navbar-nav ul.app_menu li a {
  border-radius: 0.125rem;
  width: 90px;
  border: 1px solid #eee;
  padding: 13px 0;
  margin-bottom: 3px;
}
.navbar-nav ul.app_menu li a:hover {
  cursor: pointer;
  background: #eee;
}
@media screen and (max-width: 767px) {
  .navbar-nav {
    margin-right: 0;
  }
  .navbar-nav > li {
    position: inherit;
    text-align: center;
  }
  .navbar-nav .dropdown-menu {
    max-height: 400px;
    overflow: auto;
  }
  .navbar-nav .dropdown-menu > li > a {
    white-space: normal;
  }
  .navbar-nav .open .dropdown-menu {
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    position: absolute;
    width: 100%;
    background: #fff;
  }
  .navbar-nav .open .dropdown-menu > li > a {
    padding: 3px 20px;
  }
  .navbar-nav .open .dropdown-menu.notifications > li > a {
    padding: 12px 15px;
  }
}
@media screen and (max-width: 768px) {
  .navbar > .container .navbar-brand,
  .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
  }
}
.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: #ddd;
}
.dropdown-menu.dropdown-md {
  min-width: 250px;
}
.dropdown-menu.dropdown-lg {
  min-width: 360px;
}
.dropdown-menu > li > a,
.dropdown-menu > li > a:hover,
.dropdown-menu > li > a:focus {
  color: #5a5a5a;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #5c8ed4;
}
.dropdown-animated:not(.scale-left) .dropdown-menu,
.dropdown-animated:not(.scale-right) .dropdown-menu {
  opacity: 0;
  visibility: hidden;
  transform: translateY(15px) !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  display: block;
  margin: 0px;
  top: auto !important;
}
.dropdown-animated:not(.scale-left) .dropdown-menu.show,
.dropdown-animated:not(.scale-right) .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0px) !important;
}
.dropdown-animated.scale-left .dropdown-menu {
  transform-origin: top right !important;
  transform: scale(0, 0) !important;
  -webkit-transform: scale(0, 0) !important;
  -moz-transform: scale(0, 0) !important;
  -ms-transform: scale(0, 0) !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  display: block;
  margin: 0px;
  right: 0 !important;
  left: auto !important;
  top: auto !important;
}
.dropdown-animated.scale-left .dropdown-menu.show {
  transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -ms-transform: scale(1, 1) !important;
}
.dropdown-animated.scale-right .dropdown-menu {
  transform-origin: top left;
  transform: scale(0, 0) !important;
  -webkit-transform: scale(0, 0) !important;
  -moz-transform: scale(0, 0) !important;
  -ms-transform: scale(0, 0) !important;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  display: block;
  margin: 0px;
  top: auto !important;
}
.dropdown-animated.scale-right .dropdown-menu.show {
  transform: scale(1, 1) !important;
  -webkit-transform: scale(1, 1) !important;
  -moz-transform: scale(1, 1) !important;
  -ms-transform: scale(1, 1) !important;
}

#left-sidebar {
  transition: all 0.3s ease-in-out;
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
  width: 250px;
  height: 100%;
  float: left;
  margin-top: 0;
  position: fixed;
  left: 0px;
  background-color: #2c2c38;
  z-index: 9;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
#left-sidebar::-webkit-scrollbar {
  width: 0px;
}
@media screen and (max-width: 1200px) {
  #left-sidebar {
    box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.2);
    left: -250px;
  }
}
#left-sidebar .btn-toggle-offcanvas {
  display: none;
}
#left-sidebar .navbar-brand {
  padding: 0 0 15px 0;
  margin: 15px 20px 0 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
#left-sidebar#left-sidebar .navbar-brand span {
  color: #bdbdbd;
  vertical-align: middle;
}
.offcanvas-active #left-sidebar {
  left: 0;
}
.offcanvas-active #left-sidebar .btn-toggle-offcanvas {
  display: block !important;
}
.layout-fullwidth .giveclass ~ span {
  display: none;
}
.layout-fullwidth #wrapper .navbar-fixed-top {
  width: calc(100% - 70px);
}
.layout-fullwidth .user_div + .dropdown {
  display: none;
}
.layout-fullwidth #wrapper #left-sidebar {
  left: 0;
  width: 70px;
}
.layout-fullwidth #wrapper #left-sidebar.ps {
  overflow: visible !important;
}
.layout-fullwidth #wrapper #main-content {
  width: calc(100% - 70px);
}
.user-account {
  color: #bdbdbd;
  margin: 10px;
}
.user-account .user_div {
  border-radius: 90px;
  border: 1px solid #404050;
  padding: 8px;
  display: inline-block;
  border-radius: 90px;
}
.layout-fullwidth .user-account .user_div .user-photo {
  border-radius: 90px;

  width: 36px;

  vertical-align: top;
  border: 2px solid;
}
.user-account .user-name {
  color: inherit;
}
.user-account .user-name:hover,
.user-account .user-name:focus {
  text-decoration: none;
}
.user-account .dropdown .dropdown-menu {
  border-radius: 0.55rem;
  transform: none !important;
  border: none;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  padding: 15px;
  width: 100%;
}
.user-account .dropdown .dropdown-menu li.divider {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 10px 0;
}
.user-account .dropdown .dropdown-menu a {
  padding: 10px;
  color: #999;
}
.user-account .dropdown .dropdown-menu a:hover {
  color: #444;
}
.user-account .dropdown .dropdown-menu a:hover i {
  color: #444;
}
.sidebar-nav {
  margin: 0 11px;
}
.sidebar-nav ul {
  list-style: none;
}
.sidebar-nav .metismenu {
  display: flex;
  flex-direction: column;
}
.sidebar-nav .metismenu > li {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  position: relative;
}
.sidebar-nav .metismenu > li.active > a {
  background-color: #f1f1f1;
}
.sidebar-nav .metismenu > li .active a {
  font-weight: 700;
}
.sidebar-nav .metismenu > li i {
  position: relative;
  top: 2px;
  width: 31px;
}
.sidebar-nav .metismenu a {
  transition: all 0.3s ease-out;
  color: #999;
  border-radius: 0.1875rem;
  position: relative;
  padding: 12px 15px;
  outline-width: 0;
}
.sidebar-nav .metismenu ul a {
  color: #777;
  font-size: 14px;
  padding: 10px 15px 10px 47px;
  position: relative;
}
.layout-fullwidth .sidebar-nav .metismenu ul a {
  padding: 9px 11px 10px 22px;
}
.sidebar-nav .metismenu ul a::before {
  content: url(/static/media/double-arrow-icon.f5b0ed79.svg);
  position: absolute;
  left: 19px;
}
.layout-fullwidth .sidebar-nav .metismenu ul a::before {
  content: "";
  position: relative;
}
.sidebar-nav .metismenu ul ul a {
  padding: 10px 15px 10px 70px;
}
.sidebar-nav .metismenu ul.collapse a:hover,
.sidebar-nav .metismenu ul.collapse a:focus {
  text-decoration: none;
}
.sidebar-nav .metismenu a:hover,
.sidebar-nav .metismenu a:focus {
  text-decoration: none;
  background: #24242f;
}
.rightbar {
  box-shadow: 0 5px 10px 0px rgba(0, 0, 0, 0.3);
  transition: all 0.3s ease-in-out;
  position: fixed;
  right: -320px;
  top: 0;
  width: 300px;
  background: #fff;
  height: 100vh;
  z-index: 13;
  padding: 15px 20px;
}
@media screen and (max-width: 1200px) {
  .rightbar {
    width: 280px;
  }
}
.rightbar.open {
  right: 0;
}
.rightbar .nav {
  border-bottom: 1px solid #eee;
}
.rightbar .card {
  box-shadow: none;
  border: 0;
}
.rightbar .card h6 {
  font-size: 15px;
}
ul.choose-skin li div {
  transition: all 0.3s ease-in-out;
  border-radius: 3px;
  height: 40px;
  width: 55px;
  overflow: hidden;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
}
ul.choose-skin li div:before {
  content: "";
  height: 5px;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
ul.choose-skin li div:hover {
  cursor: pointer;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.3);
}
ul.choose-skin li div.purple {
  border-left: 15px solid #fff;
  background: #eee;
}
ul.choose-skin li div.purple::before {
  background: #6462ef;
}
ul.choose-skin li div.green {
  border-left: 15px solid #fff;
  background: #eee;
}
ul.choose-skin li div.green::before {
  background: #50d38a;
}
ul.choose-skin li div.orange {
  border-left: 15px solid #fff;
  background: #eee;
}
ul.choose-skin li div.orange::before {
  background: #feb800;
}
ul.choose-skin li div.blue {
  border-left: 15px solid #444;
  background: #eee;
}
ul.choose-skin li div.blue::before {
  background: #0e9be2;
}
ul.choose-skin li div.cyan {
  border-left: 15px solid #444;
  background: #eee;
}
ul.choose-skin li div.cyan::before {
  background: #00bcd2;
}
ul.choose-skin li div.blush {
  border-left: 15px solid #444;
  background: #eee;
}
ul.choose-skin li div.blush::before {
  background: #e25985;
}
ul.choose-skin li.active div::after {
  position: absolute;
  color: #444;
  font-size: 12px;
  top: 15px;
  left: 15px;
  content: "\f00c";
  font-family: "FontAwesome";
}
label {
  font-weight: 600;
}
.search-form {
  position: relative;
}
.search-form .btn {
  box-shadow: none;
  position: absolute;
  top: 10px;
  right: 15px;
  border: none;
  color: #999;
  background-color: transparent;
}
.search-form .btn:hover,
.search-form .btn:focus {
  background-color: inherit;
  border-left: none;
  border-color: inherit;
}
.fancy-checkbox {
  margin-right: 10px;
}
.fancy-checkbox,
.fancy-checkbox label {
  font-weight: normal;
}
.fancy-checkbox input[type="checkbox"] {
  display: none;
}
.fancy-checkbox input[type="checkbox"] + span {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  cursor: pointer;
  position: relative;
}
.fancy-checkbox input[type="checkbox"] + span:before {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  bottom: 1px;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  content: "";
  border: 1px solid #ccc;
  border-radius: 3px;
}
.fancy-checkbox input[type="checkbox"]:checked + span:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 10px;
  color: #fff;
  text-align: center;
  line-height: 21px;
}
.fancy-checkbox.custom-color-green
  input[type="checkbox"]:checked
  + span:before {
  color: #22af46;
  background-color: #fff;
}
.fancy-checkbox.custom-bgcolor-green
  input[type="checkbox"]:checked
  + span:before {
  color: #fff;
  background-color: #22af46;
  border-color: #1b8d38;
}
.fancy-radio {
  margin-right: 10px;
}
.fancy-radio,
.fancy-radio label {
  font-weight: normal;
}
.fancy-radio input[type="radio"] {
  display: none;
}
.fancy-radio input[type="radio"] + span {
  display: block;
  cursor: pointer;
  position: relative;
}
.fancy-radio input[type="radio"] + span i {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-radius: 8px;
  position: relative;
  bottom: 1px;
  content: "";
  border: 1px solid #ccc;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
.fancy-radio input[type="radio"]:checked + span i:after {
  border-radius: 50%;
  display: block;
  position: relative;
  top: 3px;
  left: 3px;
  content: "";
  width: 10px;
  height: 10px;
  background-color: #7b848c;
}
.fancy-radio.custom-color-green input[type="radio"]:checked + span i:after {
  background-color: #53d76a;
}
.fancy-radio.custom-bgcolor-green input[type="radio"]:checked + span i {
  background-color: #53d76a;
}
.fancy-radio.custom-bgcolor-green input[type="radio"]:checked + span i:after {
  background-color: #fff;
}
.input-group-addon .fancy-radio,
.input-group-addon .fancy-checkbox {
  margin: 0;
  position: relative;
  top: 1px;
}
.input-group-addon .fancy-radio input[type="radio"] + span i,
.input-group-addon .fancy-checkbox input[type="checkbox"] + span {
  margin: 0;
}
.input-group-addon .fancy-radio input[type="radio"] + span i:before,
.input-group-addon .fancy-checkbox input[type="checkbox"] + span:before {
  margin: 0;
}
.form-control {
  box-shadow: none;
  /* background-color: #fff; */
  font-size: 14px;
  height: auto;
  padding: 0.3rem 0.4rem;
}
.form-control:focus {
  /* -moz-box-shadow: none;
  -webkit-box-shadow: none; */
  /* box-shadow: 5px 5px 10px 2px rgba(0,0,0,.8); */

  box-shadow: 1px 12px 19px -9px rgba(244,93,72,0.46);
  -webkit-box-shadow: 1px 12px 19px -9px rgba(244,93,72,0.46);
  -moz-box-shadow: 1px 12px 19px -9px rgba(244,93,72,0.46);

  outline: none;
  /* border-color: #c1c0c0; */
  border-color: #F45D48;



}
.help-block {
  font-size: 13px;
  color: #929292;
}
.multiselect-custom + .btn-group ul.multiselect-container > li > a {
  padding: 5px 15px;
  display: block;
  outline: none;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li
  > a
  label.checkbox {
  padding: 0;
  font-size: 14px;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li
  > a
  label.checkbox:before {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  bottom: 1px;
  content: "";
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #fafafa;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li
  > a
  label.checkbox
  input[type="checkbox"] {
  display: none;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li.active
  > a
  label.checkbox:before {
  font-family: FontAwesome;
  content: "\f00c";
  font-size: 12px;
  color: #99a1a7;
  text-align: center;
  line-height: 15px;
  background-color: #f0f0f0;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li.active
  .checkbox {
  color: #fff;
}
.multiselect-custom + .btn-group ul.multiselect-container > li:hover {
  background-color: #f7f7f7;
}
.multiselect-custom + .btn-group ul.multiselect-container > li > a {
  padding: 5px 15px;
  display: block;
  outline: none;
}
.multiselect-custom + .btn-group ul.multiselect-container > li > a label.radio {
  padding: 0;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li
  > a
  label.radio:before {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-radius: 8px;
  position: relative;
  bottom: 1px;
  content: "";
  width: 16px;
  height: 16px;
  margin-right: 10px;
  border: 1px solid #ccc;
  background-color: #fafafa;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li
  > a
  label.radio
  input[type="radio"] {
  display: none;
}
.multiselect-custom
  + .btn-group
  ul.multiselect-container
  > li.active
  > a
  label.radio:before {
  font-family: FontAwesome;
  content: "\f111";
  color: #99a1a7;
  font-size: 8px;
  text-align: center;
  line-height: 15px;
}
.multiselect-custom + .btn-group ul.multiselect-container > li.active .radio {
  color: #fff;
}
.multiselect-custom + .btn-group ul.multiselect-container > li:hover {
  background-color: #f7f7f7;
}
.multiselect-search {
  border-top-right-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}
.btn.multiselect-clear-filter {
  box-shadow: none;
  position: absolute;
  top: 2px;
  right: 0;
  padding-left: 0;
  padding-right: 0;
  border: none;
  background: transparent;
  z-index: 9 !important;
  outline: none;
}
.btn.multiselect-clear-filter:hover,
.btn.multiselect-clear-filter:focus {
  background: transparent;
  outline: none;
}
.multiselect_div > .btn-group {
  display: block;
}
.multiselect_div > .btn-group .btn {
  text-align: left;
  position: relative;
  width: 100%;
  border: 1px solid #ced4da;
}
.multiselect_div > .btn-group .btn:hover {
  box-shadow: none;
}
.multiselect_div > .btn-group .btn:after {
  position: absolute;
  right: 15px;
  top: 15px;
}
.multiselect_div > .btn-group ul {
  width: 100%;
}
.datepicker {
  padding: 10px;
  font-size: 14px;
}
.datepicker table tr td.active.active,
.datepicker table tr td.active.highlighted.active,
.datepicker table tr td.active.highlighted:active,
.datepicker table tr td.active.active:active {
  background-color: #5c8ed4;
  border-color: #3c78cb;
}
.range-to {
  padding: 4px 10px !important;
  background: #ced4da;
  margin: 0 !important;
}
.parsley-errors-list {
  margin-top: 8px;
}
.parsley-errors-list li {
  color: #de4848;
  font-size: 0.9em;
  margin-top: 3px;
}
input.parsley-error,
select.parsley-error,
textarea.parsley-error {
  background-color: #fbf5f5;
  border-color: #efd8d8;
}
.form-control.parsley-error:focus {
  border-color: #e1b3b3;
}
.form-control.parsley-success:focus {
  border-color: #afd99d;
}
.md-editor > textarea {
  padding: 10px;
}
.dropify-wrapper {
  border-radius: 3px;
  background-color: #f9f9f9;
  color: #b9b9b9;
  border: 1px solid #e4e4e4;
}
.dropify-wrapper .dropify-preview {
  background-color: #f9f9f9;
}
select.form-control:not([size]):not([multiple]) {
  height: 33px;
}
.auth-main {
  margin-top: 100px;
}
@media screen and (max-width: 1200px) {
  .auth-main {
    margin-top: 50px;
  }
}
@media screen and (max-width: 640px) {
  .auth-main {
    margin-top: 20px;
  }
}
.auth-main:before {
  content: "";
  position: fixed;
  right: 0;
  top: 0;
  width: 31%;
  height: 100%;
  z-index: 0;
}
@media screen and (max-width: 1440px) {
  .auth-main:before {
    width: 26%;
  }
}
@media screen and (max-width: 1200px) {
  .auth-main:before {
    width: 28%;
  }
}
@media screen and (max-width: 992px) {
  .auth-main:before {
    width: 35%;
  }
}
@media screen and (max-width: 640px) {
  .auth-main:before {
    width: 100%;
  }
}
.auth-main:after {
  content: "";
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: -1;
}
.auth-main .navbar {
  margin-bottom: 80px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.14);
  justify-content: space-between;
}
@media screen and (max-width: 1200px) {
  .auth-main .navbar {
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 992px) {
  .auth-main .navbar {
    margin-bottom: 20px;
  }
}
.auth-main .navbar .navbar-brand {
  color: #fff;
}
.auth-main .navbar .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.auth-main .auth_detail {
  padding: 40px 0;
  max-width: 360px;
}
@media screen and (max-width: 992px) {
  .auth-main .auth_detail {
    padding: 20px 0;
  }
}
.auth-main .auth_detail h2 {
  margin-bottom: 20px;
  font-size: 40px;
  font-weight: 300;
  color: #fff;
}
@media screen and (max-width: 992px) {
  .auth-main .auth_detail h2 {
    font-size: 35px;
  }
}
@media screen and (max-width: 640px) {
  .auth-main .auth_detail h2 {
    font-size: 25px;
  }
}
.auth-main .auth_detail p {
  font-size: 16px;
  color: #fff;
}
.auth-main .auth_detail .social-links {
  margin-top: 40px;
}
.auth-main .form-auth-small .element-left {
  float: left;
}
.auth-main .form-auth-small .element-right {
  float: right;
}
.auth-main .lead {
  font-size: 18px;
}
.auth-main .helper-text {
  color: #9a9a9a;
}
.auth-main .btn-signin-social {
  padding: 10px 22px;
  width: 100%;
  background-color: #fff;
  border-color: #f0f0f0;
}
.auth-main .btn-signin-social:hover,
.auth-main .btn-signin-social:focus {
  background-color: #f0f0f0;
}
.auth-main .btn-signin-social i {
  font-size: 16px;
  margin: 5px;
}
@media screen and (max-width: 992px) {
  .auth-main {
    margin: 0 auto;
  }
}
.btn {
  font-size: 14px;
}
.btn.btn-round {
  border-radius: 50px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.metric-inline {
  border-radius: 3px;
  padding: 20px;
  font-size: 36px;
  background-color: #f5f5f5;
}
.metric-inline i {
  margin-bottom: 12px;
  color: #9a9a9a;
}
.metric-inline span {
  font-size: 20px;
}
.separator-linethrough {
  position: relative;
  margin: 30px 0;
}
.separator-linethrough span {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  padding: 0 10px;
  position: inherit;
  font-size: 18px;
  background-color: #fff;
  z-index: 9;
}
.separator-linethrough:after {
  position: absolute;
  top: 50%;
  width: 100%;
  content: "";
  border-top: 1px solid #f0f0f0;
  z-index: 0;
}
.block-header {
  padding: 10px 25px;
  background: #fff;
}
@media screen and (max-width: 1200px) {
  .block-header {
    padding: 10px 20px;
  }
}
@media screen and (max-width: 768px) {
  .block-header {
    padding: 10px 15px;
  }
}
@media screen and (max-width: 640px) {
  .block-header .text-right {
    text-align: left !important;
  }
}
.block-header h2 {
  font-size: 18px;
  line-height: 28px;
}
.block-header .breadcrumb {
  background: transparent;
}
#toast-container > div {
  opacity: 1;
  filter: alpha(opacity=100);
}
#toast-container > div,
#toast-container > div:hover {
  box-shadow: none;
}
#toast-container .toast-close-button {
  opacity: 0.3;
  filter: alpha(opacity=30);
  top: -11px;
  text-shadow: none;
  color: #fff;
}
#toast-container .toast-close-button:hover,
#toast-container .toast-close-button:focus {
  opacity: 0.5;
  filter: alpha(opacity=50);
}
#toast-container .toast-info {
  background-color: #5c8ed4;
}
#toast-container .toast-info .toast-close-button {
  color: #2a5ba0;
}
#toast-container .toast-success {
  background-color: #22af46;
}
#toast-container .toast-success .toast-close-button {
  color: #115a24;
}
#toast-container .toast-warning {
  background-color: #f3ad06;
}
#toast-container .toast-warning .toast-close-button {
  color: #8f6604;
}
#toast-container .toast-error {
  background-color: #de4848;
}
#toast-container .toast-error .toast-close-button {
  color: #8d1919;
}
#toast-container .toast a {
  text-decoration: underline;
}
#toast-container .toast a:hover,
#toast-container .toast a:focus {
  text-decoration: none;
  color: #fff;
}
#toast-container .toast .toast-message {
  font-size: 14px;
  line-height: 1.4;
}
.bootstrap-tagsinput .tag {
  margin: 2px !important;
}
.noUi-target {
  box-shadow: none !important;
  border-color: #eee;
}
.noUi-target .noUi-background,
.noUi-target .noUi-connect {
  box-shadow: none !important;
}
.accordion .card-header .btn {
  padding: 10px 20px;
  width: 100%;
}
.accordion2 {
  list-style: none;
  border-top: 1px solid #eee;
}
.accordion2 .accordion-item {
  border-bottom: 1px solid #eee;
  padding: 0 15px;
}
.accordion2 .accordion-thumb {
  font-weight: 400;
  padding: 0.8rem 0;
  cursor: pointer;
}
.accordion2 .accordion-thumb::before {
  transition: all 0.3s ease-in-out;
  content: "\f138";
  font-family: "FontAwesome";
}
.accordion2 .accordion-panel {
  padding-bottom: 0.8rem;
  display: none;
}
.accordion2 .accordion-item.is-active .accordion-thumb::before {
  content: "\f13a";
}
.carousel.vert .carousel-item-next.carousel-item-left,
.carousel.vert .carousel-item-prev.carousel-item-right {
  transform: translate3d(0, 0, 0);
}
.carousel.vert .carousel-item-next,
.carousel.vert .active.carousel-item-right {
  transform: translate3d(0, 100% 0);
}
.carousel.vert .carousel-item-prev,
.carousel.vert .active.carousel-item-left {
  transform: translate3d(0, -100%, 0);
}
.progress {
  border-radius: 2px;
  box-shadow: none;
  background-color: #f1f1f1;
}
.progress.wide {
  width: 60px;
}
.progress .progress-bar {
  transition: width 3s ease;
  box-shadow: none;
}
.progress.progress-lg {
  height: 28px;
}
.progress.progress-sm {
  height: 12px;
}
.progress.progress-xs {
  height: 5px;
}
.progress.progress-sm .progress-bar,
.progress.progress-xs .progress-bar {
  text-indent: -9999px;
}
.progress-bar {
  background-color: #5c8ed4;
}
.progress-bar-success {
  background-color: #22af46;
}
.progress-bar-warning {
  background-color: #f3ad06;
}
.progress-bar-danger {
  background-color: #de4848;
}
.progress-bar-info {
  background-color: #3c89da;
}
.progress-transparent.custom-color-blue {
  background-color: rgba(14, 155, 226, 0.2);
}
.progress-transparent.custom-color-blue .progress-bar {
  background-color: #0e9be2;
}
.progress-transparent.custom-color-green {
  background-color: rgba(124, 172, 37, 0.2);
}
.progress-transparent.custom-color-green .progress-bar {
  background-color: #7cac25;
}
.progress-transparent.custom-color-orange {
  background-color: rgba(255, 68, 2, 0.2);
}
.progress-transparent.custom-color-orange .progress-bar {
  background-color: #ff4402;
}
.progress-transparent.custom-color-purple {
  background-color: rgba(171, 125, 246, 0.2);
}
.progress-transparent.custom-color-purple .progress-bar {
  background-color: #ab7df6;
}
.progress-transparent.custom-color-yellow {
  background-color: rgba(243, 187, 35, 0.2);
}
.progress-transparent.custom-color-yellow .progress-bar {
  background-color: #f3bb23;
}
.progress-transparent.custom-color-lightseagreen {
  background-color: rgba(32, 178, 170, 0.2);
}
.progress-transparent.custom-color-lightseagreen .progress-bar {
  background-color: #20b2aa;
}
.tooltip-inner {
  background-color: #181818;
}
.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tooltip.left .tooltip-arrow {
  border-left-color: #5a5a5a;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #5a5a5a;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #5a5a5a;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #5a5a5a;
}
.popover {
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
  font-family: "Open Sans", sans-serif;
  border-color: #ededed;
}
.popover-title {
  font-weight: 600;
  color: #5a5a5a;
  background-color: #f5f5f5;
}
.panel-group .panel .panel-heading {
  padding: 0;
}
.panel-group .panel .panel-title {
  font-family: "Open Sans", sans-serif;
}
.panel-group .panel .panel-title > a {
  display: block;
  padding: 10px 15px;
}
.panel-group .panel .panel-title > a:hover,
.panel-group .panel .panel-title > a:focus {
  color: inherit;
}
.panel-group .panel .panel-title > a i.icon-collapsed {
  display: none;
}
.panel-group .panel .panel-title > a i.right {
  float: right;
}
.panel-group .panel .panel-title > a.collapsed .icon-expanded {
  display: none;
}
.panel-group .panel .panel-title > a.collapsed .icon-collapsed {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  vertical-align: baseline;
}
.pagination > li > a,
.pagination > li > span {
  color: #5c8ed4;
}
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus {
  background-color: #7ca4dd;
  border-color: #5c8ed4;
  color: #fff;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span {
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #7ca4dd;
  border-color: #5c8ed4;
  color: #fff;
}
.pagination > .active > a,
.pagination > .active > span {
  background-color: #5c8ed4;
  border-color: #4880cf;
}
.pagination.borderless > li > a,
.pagination.borderless > li > span {
  border-radius: 2px;
  border-color: transparent;
  margin: 0 1px;
}
.pagination.borderless > li:first-child > a,
.pagination.borderless > li:first-child > span,
.pagination.borderless > li:last-child > a,
.pagination.borderless > li:last-child > span {
  border-radius: 2px;
}
.pagination.borderless > li.active > a,
.pagination.borderless > li.active > span {
  color: #fff;
  background-color: #5c8ed4;
}
.pagination.borderless > li.active > a:hover,
.pagination.borderless > li.active > a:focus,
.pagination.borderless > li.active > span:hover,
.pagination.borderless > li.active > span:focus {
  color: #fff;
  background-color: #5c8ed4;
}
.pager li > a {
  border-color: #5c8ed4;
}
.pager li > a:hover,
.pager li > a:focus {
  background-color: #5c8ed4;
  border-color: #5086d1;
  color: #fff;
}
.pager .disabled > a,
.pager .disabled > a:hover,
.pager .disabled > a:focus,
.pager .disabled > span {
  border-color: #ddd;
}
.bd-example-border-utils [class^="border"] {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  margin: 0.25rem;
  background-color: #f5f5f5;
}
.blockquote {
  border-left: none;
  border: 1px solid #777;
  padding: 20px;
  font-size: 1.2em;
  line-height: 1.8;
}
.blockquote.blockquote-primary {
  border-color: #00bcd2;
}
.blockquote.blockquote-primary small {
  color: #00bcd2;
}
.blockquote.blockquote-info {
  border-color: #3c89da;
}
.blockquote.blockquote-info small {
  color: #3c89da;
}
.blockquote.blockquote-danger {
  border-color: #de4848;
}
.blockquote.blockquote-danger small {
  color: #de4848;
}
.blockquote small {
  font-size: 0.93em;
  text-transform: uppercase;
}
.btn {
  transition: all 0.3s ease-in-out;
}
.btn:hover {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}
.btn-default {
  color: #777;
  background-color: #fff;
  border-color: #eee;
  padding: 6px 18px;
}
.btn-default.btn-sm {
  padding: 4px 10px;
}
#jqstooltip {
  background-color: #fff;
  border-color: #ddd;
  color: #5a5a5a;
}
#jqstooltip .jqsfield {
  color: #5a5a5a;
}
.ct-chart {
  position: relative;
}
.ct-series .ct-line {
  stroke-width: 3px;
}
.ct-series .ct-bar {
  stroke-width: 15px;
}
.ct-series .ct-point {
  stroke-width: 10px;
  stroke-linecap: circle;
}
.ct-series-a .ct-line,
.ct-series-a .ct-bar,
.ct-series-a .ct-point {
  stroke: #6ebdd1;
}
.ct-series-a .ct-area,
.ct-series-a .ct-slice-donut-solid,
.ct-series-a .ct-slice-pie,
.ct-series-a .ct-bar {
  fill: #6ebdd1;
}
.ct-series-b .ct-line,
.ct-series-b .ct-bar,
.ct-series-b .ct-point {
  stroke: #f9ab6c;
}
.ct-series-b .ct-area,
.ct-series-b .ct-slice-donut-solid,
.ct-series-b .ct-slice-pie,
.ct-series-b .ct-bar {
  fill: #f9ab6c;
}
.ct-series-c .ct-line,
.ct-series-c .ct-bar,
.ct-series-c .ct-point {
  stroke: #afc979;
}
.ct-series-c .ct-area,
.ct-series-c .ct-slice-donut-solid,
.ct-series-c .ct-slice-pie,
.ct-series-c .ct-bar {
  fill: #afc979;
}
.ct-series-d .ct-line,
.ct-series-d .ct-bar,
.ct-series-d .ct-point {
  stroke: #ab7df6;
}
.ct-series-d .ct-area,
.ct-series-d .ct-slice-donut-solid,
.ct-series-d .ct-slice-pie,
.ct-series-d .ct-bar {
  fill: #ab7df6;
}
.ct-series-e .ct-line,
.ct-series-e .ct-bar,
.ct-series-e .ct-point {
  stroke: #5cc196;
}
.ct-series-e .ct-area,
.ct-series-e .ct-slice-donut-solid,
.ct-series-e .ct-slice-pie,
.ct-series-e .ct-bar {
  fill: #5cc196;
}
.ct-series-f .ct-line,
.ct-series-f .ct-bar,
.ct-series-f .ct-point {
  stroke: #d17905;
}
.ct-series-f .ct-area,
.ct-series-f .ct-slice-donut-solid,
.ct-series-f .ct-slice-pie,
.ct-series-f .ct-bar {
  fill: #d17905;
}
.ct-series-g .ct-line,
.ct-series-g .ct-bar,
.ct-series-g .ct-point {
  stroke: #453d3f;
}
.ct-series-g .ct-area,
.ct-series-g .ct-slice-donut-solid,
.ct-series-g .ct-slice-pie,
.ct-series-g .ct-bar {
  fill: #453d3f;
}
.ct-series-h .ct-line,
.ct-series-h .ct-bar,
.ct-series-h .ct-point {
  stroke: #59922b;
}
.ct-series-h .ct-area,
.ct-series-h .ct-slice-donut-solid,
.ct-series-h .ct-slice-pie,
.ct-series-h .ct-bar {
  fill: #59922b;
}
.ct-series-i .ct-line,
.ct-series-i .ct-bar,
.ct-series-i .ct-point {
  stroke: #0544d3;
}
.ct-series-i .ct-area,
.ct-series-i .ct-slice-donut-solid,
.ct-series-i .ct-slice-pie,
.ct-series-i .ct-bar {
  fill: #0544d3;
}
.ct-series-j .ct-line,
.ct-series-j .ct-bar,
.ct-series-j .ct-point {
  stroke: #6b0392;
}
.ct-series-j .ct-area,
.ct-series-j .ct-slice-donut-solid,
.ct-series-j .ct-slice-pie,
.ct-series-j .ct-bar {
  fill: #6b0392;
}
.ct-series-k .ct-line,
.ct-series-k .ct-bar,
.ct-series-k .ct-point {
  stroke: #f05b4f;
}
.ct-series-k .ct-area,
.ct-series-k .ct-slice-donut-solid,
.ct-series-k .ct-slice-pie,
.ct-series-k .ct-bar {
  fill: #f05b4f;
}
.ct-series-l .ct-line,
.ct-series-l .ct-bar,
.ct-series-l .ct-point {
  stroke: #dda458;
}
.ct-series-l .ct-area,
.ct-series-l .ct-slice-donut-solid,
.ct-series-l .ct-slice-pie,
.ct-series-l .ct-bar {
  fill: #dda458;
}
.ct-series-m .ct-line,
.ct-series-m .ct-bar,
.ct-series-m .ct-point {
  stroke: #eacf7d;
}
.ct-series-m .ct-area,
.ct-series-m .ct-slice-donut-solid,
.ct-series-m .ct-slice-pie,
.ct-series-m .ct-bar {
  fill: #eacf7d;
}
.ct-series-n .ct-line,
.ct-series-n .ct-bar,
.ct-series-n .ct-point {
  stroke: #86797d;
}
.ct-series-n .ct-area,
.ct-series-n .ct-slice-donut-solid,
.ct-series-n .ct-slice-pie,
.ct-series-n .ct-bar {
  fill: #86797d;
}
.ct-series-o .ct-line,
.ct-series-o .ct-bar,
.ct-series-o .ct-point {
  stroke: #b2c326;
}
.ct-series-o .ct-area,
.ct-series-o .ct-slice-donut-solid,
.ct-series-o .ct-slice-pie,
.ct-series-o .ct-bar {
  fill: #b2c326;
}
.chartist-tooltip {
  border-radius: 3px;
  min-width: 3.5em;
  background: #5a5a5a;
  color: #fff;
}
.chartist-tooltip:before {
  margin-left: -8px;
  border-width: 8px;
  border-top-color: #5a5a5a;
}
.ct-axis-title {
  fill: #9a9a9a;
}
.ct-label {
  color: #5a5a5a;
}
.ct-legend {
  position: relative;
  z-index: 5;
  text-align: right;
  padding: 0;
}
.ct-legend li {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
  padding-left: 16px;
  margin-bottom: 3px;
  margin-right: 15px;
  list-style-type: none;
}
.ct-legend li:before {
  border-radius: 2px;
  width: 12px;
  height: 12px;
  position: absolute;
  top: 5px;
  left: 0;
  content: "";
  border: 3px solid transparent;
}
.ct-legend li.inactive:before {
  background: transparent;
}
.ct-legend.ct-legend-inside {
  position: absolute;
  top: 0;
  right: 0;
}
.ct-legend .ct-series-0:before {
  background-color: #6ebdd1;
  border-color: #6ebdd1;
}
.ct-legend .ct-series-1:before {
  background-color: #f9ab6c;
  border-color: #f9ab6c;
}
.ct-legend .ct-series-2:before {
  background-color: #afc979;
  border-color: #afc979;
}
.ct-legend .ct-series-3:before {
  background-color: #ab7df6;
  border-color: #ab7df6;
}
.ct-legend .ct-series-4:before {
  background-color: #5cc196;
  border-color: #5cc196;
}
.ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.ct-legend .ct-series-6:before {
  background-color: #453d3f;
  border-color: #453d3f;
}
.ct-legend .ct-series-7:before {
  background-color: #59922b;
  border-color: #59922b;
}
.ct-legend .ct-series-8:before {
  background-color: #0544d3;
  border-color: #0544d3;
}
.ct-legend .ct-series-9:before {
  background-color: #6b0392;
  border-color: #6b0392;
}
.ct-legend .ct-series-10:before {
  background-color: #f05b4f;
  border-color: #f05b4f;
}
.ct-legend .ct-series-11:before {
  background-color: #dda458;
  border-color: #dda458;
}
.ct-legend .ct-series-12:before {
  background-color: #eacf7d;
  border-color: #eacf7d;
}
.ct-legend .ct-series-13:before {
  background-color: #86797d;
  border-color: #86797d;
}
.ct-legend .ct-series-14:before {
  background-color: #b2c326;
  border-color: #b2c326;
}
.z500px-color {
  color: #0099e5 !important;
}
.about-me-color {
  color: #00405d !important;
}
.airbnb-color {
  color: #fd5c63 !important;
}
.alphabet-color {
  color: #ed1c24 !important;
}
.amazon-color {
  color: #f90 !important;
}
.american-express-color {
  color: #002663 !important;
}
.aol-color {
  color: #ff0b00 !important;
}
.behance-color {
  color: #1769ff !important;
}
.bing-color {
  color: #ffb900 !important;
}
.bitbucket-color {
  color: #205081 !important;
}
.bitly-color {
  color: #ee6123 !important;
}
.blogger-color {
  color: #f57d00 !important;
}
.booking-com-color {
  color: #003580 !important;
}
.buffer-color {
  color: #168eea !important;
}
.code-school-color {
  color: #616f67 !important;
}
.codecademy-color {
  color: #f65a5b !important;
}
.creative-market-color {
  color: #8ba753 !important;
}
.delicious-color {
  color: #39f !important;
}
.deviantart-color {
  color: #05cc47 !important;
}
.digg-color {
  color: #005be2 !important;
}
.disqus-color {
  color: #2e9fff !important;
}
.django-color {
  color: #092e20 !important;
}
.dribbble-color {
  color: #ea4c89 !important;
}
.dropbox-color {
  color: #007ee5 !important;
}
.drupal-color {
  color: #0077c0 !important;
}
.elance-color {
  color: #0d69af !important;
}
.envato-color {
  color: #82b541 !important;
}
.etsy-color {
  color: #d5641c !important;
}
.facebook-color {
  color: #3b5998 !important;
}
.feedly-color {
  color: #2bb24c !important;
}
.flattr-color {
  color: #f67c1a !important;
}
.flickr-color {
  color: #0063dc !important;
}
.flipboard-color {
  color: #e12828 !important;
}
.flixster-color {
  color: #2971b2 !important;
}
.foursquare-color {
  color: #0732a2 !important;
}
.github-color {
  color: #333 !important;
}
.google-color {
  color: #4285f4 !important;
}
.google-plus-color {
  color: #dc4e41 !important;
}
.instagram-color {
  color: #3f729b !important;
}
.kickstarter-color {
  color: #2bde73 !important;
}
.kik-color {
  color: #82bc23 !important;
}
.lastfm-color {
  color: #d51007 !important;
}
.line-color {
  color: #00c300 !important;
}
.linkedin-color {
  color: #0077b5 !important;
}
.mail-ru-color {
  color: #168de2 !important;
}
.mailchimp-color {
  color: #2c9ab7 !important;
}
.myspace-color {
  color: #000 !important;
}
.netflix-color {
  color: #e50914 !important;
}
.ning-color {
  color: #75af42 !important;
}
.path-color {
  color: #ee3423 !important;
}
.patreon-color {
  color: #e6461a !important;
}
.paypal-color {
  color: #003087 !important;
}
.photobucket-color {
  color: #0ea0db !important;
}
.pinterest-color {
  color: #bd081c !important;
}
.product-hunt-color {
  color: #da552f !important;
}
.quora-color {
  color: #a82400 !important;
}
.rdio-color {
  color: #007dc3 !important;
}
.reddit-color {
  color: #ff4500 !important;
}
.rss-color {
  color: #f26522 !important;
}
.salesforce-color {
  color: #1798c1 !important;
}
.scribd-color {
  color: #1a7bba !important;
}
.shopify-color {
  color: #96bf48 !important;
}
.skype-color {
  color: #00aff0 !important;
}
.slack-color {
  color: #6ecadc !important;
}
.slideshare-color {
  color: #0077b5 !important;
}
.soundcloud-color {
  color: #f80 !important;
}
.spotify-color {
  color: #2ebd59 !important;
}
.squarespace-color {
  color: #222 !important;
}
.stackoverflow-color {
  color: #fe7a15 !important;
}
.stripe-color {
  color: #00afe1 !important;
}
.stumbleupon-color {
  color: #eb4924 !important;
}
.telegram-color {
  color: #08c !important;
}
.tumblr-color {
  color: #35465c !important;
}
.twitch-tv-color {
  color: #6441a5 !important;
}
.twitter-color {
  color: #55acee !important;
}
.vimeo-color {
  color: #162221 !important;
}
.vine-color {
  color: #00b488 !important;
}
.wechat-color {
  color: #7bb32e !important;
}
.whatsapp-color {
  color: #43d854 !important;
}
.wordpress-color {
  color: #21759b !important;
}
.yahoo-color {
  color: #410093 !important;
}
.youtube-color {
  color: #cd201f !important;
}
.z500px-bg {
  background-color: #0099e5 !important;
}
.z500px-bg:hover,
.z500px-bg:focus {
  background-color: #08c !important;
}
.about-me-bg {
  background-color: #00405d !important;
}
.about-me-bg:hover,
.about-me-bg:focus {
  background-color: #002e44 !important;
}
.airbnb-bg {
  background-color: #fd5c63 !important;
}
.airbnb-bg:hover,
.airbnb-bg:focus {
  background-color: #fd434b !important;
}
.alphabet-bg {
  background-color: #ed1c24 !important;
}
.alphabet-bg:hover,
.alphabet-bg:focus {
  background-color: #de1219 !important;
}
.amazon-bg {
  background-color: #f90 !important;
}
.amazon-bg:hover,
.amazon-bg:focus {
  background-color: #e68a00 !important;
}
.american-express-bg {
  background-color: #002663 !important;
}
.american-express-bg:hover,
.american-express-bg:focus {
  background-color: #001c4a !important;
}
.aol-bg {
  background-color: #ff0b00 !important;
}
.aol-bg:hover,
.aol-bg:focus {
  background-color: #e60a00 !important;
}
.behance-bg {
  background-color: #1769ff !important;
}
.behance-bg:hover,
.behance-bg:focus {
  background-color: #0059fd !important;
}
.bing-bg {
  background-color: #ffb900 !important;
}
.bing-bg:hover,
.bing-bg:focus {
  background-color: #e6a700 !important;
}
.bitbucket-bg {
  background-color: #205081 !important;
}
.bitbucket-bg:hover,
.bitbucket-bg:focus {
  background-color: #1b436d !important;
}
.bitly-bg {
  background-color: #ee6123 !important;
}
.bitly-bg:hover,
.bitly-bg:focus {
  background-color: #e65312 !important;
}
.blogger-bg {
  background-color: #f57d00 !important;
}
.blogger-bg:hover,
.blogger-bg:focus {
  background-color: #dc7000 !important;
}
.booking-com-bg {
  background-color: #003580 !important;
}
.booking-com-bg:hover,
.booking-com-bg:focus {
  background-color: #002a67 !important;
}
.buffer-bg {
  background-color: #168eea !important;
}
.buffer-bg:hover,
.buffer-bg:focus {
  background-color: #1380d3 !important;
}
.code-school-bg {
  background-color: #616f67 !important;
}
.code-school-bg:hover,
.code-school-bg:focus {
  background-color: #55615a !important;
}
.codecademy-bg {
  background-color: #f65a5b !important;
}
.codecademy-bg:hover,
.codecademy-bg:focus {
  background-color: #f54243 !important;
}
.creative-market-bg {
  background-color: #8ba753 !important;
}
.creative-market-bg:hover,
.creative-market-bg:focus {
  background-color: #7d964b !important;
}
.delicious-bg {
  background-color: #39f !important;
}
.delicious-bg:hover,
.delicious-bg:focus {
  background-color: #1a8cff !important;
}
.deviantart-bg {
  background-color: #05cc47 !important;
}
.deviantart-bg:hover,
.deviantart-bg:focus {
  background-color: #04b33e !important;
}
.digg-bg {
  background-color: #005be2 !important;
}
.digg-bg:hover,
.digg-bg:focus {
  background-color: #0051c9 !important;
}
.disqus-bg {
  background-color: #2e9fff !important;
}
.disqus-bg:hover,
.disqus-bg:focus {
  background-color: #1593ff !important;
}
.django-bg {
  background-color: #092e20 !important;
}
.django-bg:hover,
.django-bg:focus {
  background-color: #051911 !important;
}
.dribbble-bg {
  background-color: #ea4c89 !important;
}
.dribbble-bg:hover,
.dribbble-bg:focus {
  background-color: #e7357a !important;
}
.dropbox-bg {
  background-color: #007ee5 !important;
}
.dropbox-bg:hover,
.dropbox-bg:focus {
  background-color: #0070cc !important;
}
.drupal-bg {
  background-color: #0077c0 !important;
}
.drupal-bg:hover,
.drupal-bg:focus {
  background-color: #0067a7 !important;
}
.elance-bg {
  background-color: #0d69af !important;
}
.elance-bg:hover,
.elance-bg:focus {
  background-color: #0b5b97 !important;
}
.envato-bg {
  background-color: #82b541 !important;
}
.envato-bg:hover,
.envato-bg:focus {
  background-color: #75a23a !important;
}
.etsy-bg {
  background-color: #d5641c !important;
}
.etsy-bg:hover,
.etsy-bg:focus {
  background-color: #be5919 !important;
}
.facebook-bg {
  background-color: #3b5998 !important;
}
.facebook-bg:hover,
.facebook-bg:focus {
  background-color: #344e86 !important;
}
.feedly-bg {
  background-color: #2bb24c !important;
}
.feedly-bg:hover,
.feedly-bg:focus {
  background-color: #269d43 !important;
}
.flattr-bg {
  background-color: #f67c1a !important;
}
.flattr-bg:hover,
.flattr-bg:focus {
  background-color: #ed6f09 !important;
}
.flickr-bg {
  background-color: #0063dc !important;
}
.flickr-bg:hover,
.flickr-bg:focus {
  background-color: #0058c3 !important;
}
.flipboard-bg {
  background-color: #e12828 !important;
}
.flipboard-bg:hover,
.flipboard-bg:focus {
  background-color: #d21d1d !important;
}
.flixster-bg {
  background-color: #2971b2 !important;
}
.flixster-bg:hover,
.flixster-bg:focus {
  background-color: #24649d !important;
}
.foursquare-bg {
  background-color: #0732a2 !important;
}
.foursquare-bg:hover,
.foursquare-bg:focus {
  background-color: #062a8a !important;
}
.github-bg {
  background-color: #333 !important;
}
.github-bg:hover,
.github-bg:focus {
  background-color: #262626 !important;
}
.google-bg {
  background-color: #4285f4 !important;
}
.google-bg:hover,
.google-bg:focus {
  background-color: #2a75f3 !important;
}
.google-plus-bg {
  background-color: #dc4e41 !important;
}
.google-plus-bg:hover,
.google-plus-bg:focus {
  background-color: #d83a2b !important;
}
.instagram-bg {
  background-color: #3f729b !important;
}
.instagram-bg:hover,
.instagram-bg:focus {
  background-color: #386589 !important;
}
.kickstarter-bg {
  background-color: #2bde73 !important;
}
.kickstarter-bg:hover,
.kickstarter-bg:focus {
  background-color: #20cf67 !important;
}
.kik-bg {
  background-color: #82bc23 !important;
}
.kik-bg:hover,
.kik-bg:focus {
  background-color: #73a71f !important;
}
.lastfm-bg {
  background-color: #d51007 !important;
}
.lastfm-bg:hover,
.lastfm-bg:focus {
  background-color: #bc0e06 !important;
}
.line-bg {
  background-color: #00c300 !important;
}
.line-bg:hover,
.line-bg:focus {
  background-color: #0a0 !important;
}
.linkedin-bg {
  background-color: #0077b5 !important;
}
.linkedin-bg:hover,
.linkedin-bg:focus {
  background-color: #00669c !important;
}
.mail-ru-bg {
  background-color: #168de2 !important;
}
.mail-ru-bg:hover,
.mail-ru-bg:focus {
  background-color: #147fcb !important;
}
.mailchimp-bg {
  background-color: #2c9ab7 !important;
}
.mailchimp-bg:hover,
.mailchimp-bg:focus {
  background-color: #2789a2 !important;
}
.myspace-bg {
  background-color: #000 !important;
}
.myspace-bg:hover,
.myspace-bg:focus {
  background-color: #000 !important;
}
.netflix-bg {
  background-color: #e50914 !important;
}
.netflix-bg:hover,
.netflix-bg:focus {
  background-color: #cc0812 !important;
}
.ning-bg {
  background-color: #75af42 !important;
}
.ning-bg:hover,
.ning-bg:focus {
  background-color: #699c3b !important;
}
.path-bg {
  background-color: #ee3423 !important;
}
.path-bg:hover,
.path-bg:focus {
  background-color: #e62412 !important;
}
.patreon-bg {
  background-color: #e6461a !important;
}
.patreon-bg:hover,
.patreon-bg:focus {
  background-color: #d03f17 !important;
}
.paypal-bg {
  background-color: #003087 !important;
}
.paypal-bg:hover,
.paypal-bg:focus {
  background-color: #00276e !important;
}
.photobucket-bg {
  background-color: #0ea0db !important;
}
.photobucket-bg:hover,
.photobucket-bg:focus {
  background-color: #0c8ec3 !important;
}
.pinterest-bg {
  background-color: #bd081c !important;
}
.pinterest-bg:hover,
.pinterest-bg:focus {
  background-color: #a50718 !important;
}
.product-hunt-bg {
  background-color: #da552f !important;
}
.product-hunt-bg:hover,
.product-hunt-bg:focus {
  background-color: #cb4924 !important;
}
.quora-bg {
  background-color: #a82400 !important;
}
.quora-bg:hover,
.quora-bg:focus {
  background-color: #8f1f00 !important;
}
.rdio-bg {
  background-color: #007dc3 !important;
}
.rdio-bg:hover,
.rdio-bg:focus {
  background-color: #006daa !important;
}
.reddit-bg {
  background-color: #ff4500 !important;
}
.reddit-bg:hover,
.reddit-bg:focus {
  background-color: #e63e00 !important;
}
.rss-bg {
  background-color: #f26522 !important;
}
.rss-bg:hover,
.rss-bg:focus {
  background-color: #ed560e !important;
}
.salesforce-bg {
  background-color: #1798c1 !important;
}
.salesforce-bg:hover,
.salesforce-bg:focus {
  background-color: #1486aa !important;
}
.scribd-bg {
  background-color: #1a7bba !important;
}
.scribd-bg:hover,
.scribd-bg:focus {
  background-color: #176ca4 !important;
}
.shopify-bg {
  background-color: #96bf48 !important;
}
.shopify-bg:hover,
.shopify-bg:focus {
  background-color: #89b03e !important;
}
.skype-bg {
  background-color: #00aff0 !important;
}
.skype-bg:hover,
.skype-bg:focus {
  background-color: #009cd7 !important;
}
.slack-bg {
  background-color: #6ecadc !important;
}
.slack-bg:hover,
.slack-bg:focus {
  background-color: #59c2d7 !important;
}
.slideshare-bg {
  background-color: #0077b5 !important;
}
.slideshare-bg:hover,
.slideshare-bg:focus {
  background-color: #00669c !important;
}
.soundcloud-bg {
  background-color: #f80 !important;
}
.soundcloud-bg:hover,
.soundcloud-bg:focus {
  background-color: #e67a00 !important;
}
.spotify-bg {
  background-color: #2ebd59 !important;
}
.spotify-bg:hover,
.spotify-bg:focus {
  background-color: #29a84f !important;
}
.squarespace-bg {
  background-color: #222 !important;
}
.squarespace-bg:hover,
.squarespace-bg:focus {
  background-color: #151515 !important;
}
.stackoverflow-bg {
  background-color: #fe7a15 !important;
}
.stackoverflow-bg:hover,
.stackoverflow-bg:focus {
  background-color: #f86c01 !important;
}
.stripe-bg {
  background-color: #00afe1 !important;
}
.stripe-bg:hover,
.stripe-bg:focus {
  background-color: #009bc8 !important;
}
.stumbleupon-bg {
  background-color: #eb4924 !important;
}
.stumbleupon-bg:hover,
.stumbleupon-bg:focus {
  background-color: #e13b15 !important;
}
.telegram-bg {
  background-color: #08c !important;
}
.telegram-bg:hover,
.telegram-bg:focus {
  background-color: #0077b3 !important;
}
.tumblr-bg {
  background-color: #35465c !important;
}
.tumblr-bg:hover,
.tumblr-bg:focus {
  background-color: #2c3a4c !important;
}
.twitch-tv-bg {
  background-color: #6441a5 !important;
}
.twitch-tv-bg:hover,
.twitch-tv-bg:focus {
  background-color: #593a93 !important;
}
.twitter-bg {
  background-color: #55acee !important;
}
.twitter-bg:hover,
.twitter-bg:focus {
  background-color: #3ea1ec !important;
}
.vimeo-bg {
  background-color: #162221 !important;
}
.vimeo-bg:hover,
.vimeo-bg:focus {
  background-color: #0c1312 !important;
}
.vine-bg {
  background-color: #00b488 !important;
}
.vine-bg:hover,
.vine-bg:focus {
  background-color: #009b75 !important;
}
.wechat-bg {
  background-color: #7bb32e !important;
}
.wechat-bg:hover,
.wechat-bg:focus {
  background-color: #6d9f29 !important;
}
.whatsapp-bg {
  background-color: #43d854 !important;
}
.whatsapp-bg:hover,
.whatsapp-bg:focus {
  background-color: #2ed441 !important;
}
.wordpress-bg {
  background-color: #21759b !important;
}
.wordpress-bg:hover,
.wordpress-bg:focus {
  background-color: #1d6586 !important;
}
.yahoo-bg {
  background-color: #410093 !important;
}
.yahoo-bg:hover,
.yahoo-bg:focus {
  background-color: #36007a !important;
}
.youtube-bg {
  background-color: #cd201f !important;
}
.youtube-bg:hover,
.youtube-bg:focus {
  background-color: #b71d1c !important;
}
h6 {
  font-weight: 600;
}
h3 {
  font-weight: 700;
}
ul.list-justify > li {
  margin-bottom: 5px;
}
ul.list-justify > li span,
ul.list-justify > li strong {
  float: right;
}
ul.list-justify.large-number > li {
  margin-bottom: 15px;
}
ul.list-justify.large-number span {
  font-size: 32px;
  line-height: 1;
}
.text-primary {
  color: #5c8ed4;
}
.text-info {
  color: #3c89da;
}
.text-success {
  color: #22af46;
}
.text-warning {
  color: #f3ad06;
}
.text-danger {
  color: #de4848;
}
.text-muted {
  color: #9a9a9a;
}
.text-larger {
  font-size: 1.2em;
}
.text-large {
  font-size: 1.53em;
}
.m-l--125 {
  margin-left: -125px;
}
.m-t--125 {
  margin-top: -125px;
}
.m-r--125 {
  margin-right: -125px;
}
.m-b--125 {
  margin-bottom: -125px;
}
.m-l--120 {
  margin-left: -120px;
}
.m-t--120 {
  margin-top: -120px;
}
.m-r--120 {
  margin-right: -120px;
}
.m-b--120 {
  margin-bottom: -120px;
}
.m-l--115 {
  margin-left: -115px;
}
.m-t--115 {
  margin-top: -115px;
}
.m-r--115 {
  margin-right: -115px;
}
.m-b--115 {
  margin-bottom: -115px;
}
.m-l--110 {
  margin-left: -110px;
}
.m-t--110 {
  margin-top: -110px;
}
.m-r--110 {
  margin-right: -110px;
}
.m-b--110 {
  margin-bottom: -110px;
}
.m-l--105 {
  margin-left: -105px;
}
.m-t--105 {
  margin-top: -105px;
}
.m-r--105 {
  margin-right: -105px;
}
.m-b--105 {
  margin-bottom: -105px;
}
.m-l--100 {
  margin-left: -100px;
}
.m-t--100 {
  margin-top: -100px;
}
.m-r--100 {
  margin-right: -100px;
}
.m-b--100 {
  margin-bottom: -100px;
}
.m-l--95 {
  margin-left: -95px;
}
.m-t--95 {
  margin-top: -95px;
}
.m-r--95 {
  margin-right: -95px;
}
.m-b--95 {
  margin-bottom: -95px;
}
.m-l--90 {
  margin-left: -90px;
}
.m-t--90 {
  margin-top: -90px;
}
.m-r--90 {
  margin-right: -90px;
}
.m-b--90 {
  margin-bottom: -90px;
}
.m-l--85 {
  margin-left: -85px;
}
.m-t--85 {
  margin-top: -85px;
}
.m-r--85 {
  margin-right: -85px;
}
.m-b--85 {
  margin-bottom: -85px;
}
.m-l--80 {
  margin-left: -80px;
}
.m-t--80 {
  margin-top: -80px;
}
.m-r--80 {
  margin-right: -80px;
}
.m-b--80 {
  margin-bottom: -80px;
}
.m-l--75 {
  margin-left: -75px;
}
.m-t--75 {
  margin-top: -75px;
}
.m-r--75 {
  margin-right: -75px;
}
.m-b--75 {
  margin-bottom: -75px;
}
.m-l--70 {
  margin-left: -70px;
}
.m-t--70 {
  margin-top: -70px;
}
.m-r--70 {
  margin-right: -70px;
}
.m-b--70 {
  margin-bottom: -70px;
}
.m-l--65 {
  margin-left: -65px;
}
.m-t--65 {
  margin-top: -65px;
}
.m-r--65 {
  margin-right: -65px;
}
.m-b--65 {
  margin-bottom: -65px;
}
.m-l--60 {
  margin-left: -60px;
}
.m-t--60 {
  margin-top: -60px;
}
.m-r--60 {
  margin-right: -60px;
}
.m-b--60 {
  margin-bottom: -60px;
}
.m-l--55 {
  margin-left: -55px;
}
.m-t--55 {
  margin-top: -55px;
}
.m-r--55 {
  margin-right: -55px;
}
.m-b--55 {
  margin-bottom: -55px;
}
.m-l--50 {
  margin-left: -50px;
}
.m-t--50 {
  margin-top: -50px;
}
.m-r--50 {
  margin-right: -50px;
}
.m-b--50 {
  margin-bottom: -50px;
}
.m-l--45 {
  margin-left: -45px;
}
.m-t--45 {
  margin-top: -45px;
}
.m-r--45 {
  margin-right: -45px;
}
.m-b--45 {
  margin-bottom: -45px;
}
.m-l--40 {
  margin-left: -40px;
}
.m-t--40 {
  margin-top: -40px;
}
.m-r--40 {
  margin-right: -40px;
}
.m-b--40 {
  margin-bottom: -40px;
}
.m-l--35 {
  margin-left: -35px;
}
.m-t--35 {
  margin-top: -35px;
}
.m-r--35 {
  margin-right: -35px;
}
.m-b--35 {
  margin-bottom: -35px;
}
.m-l--30 {
  margin-left: -30px;
}
.m-t--30 {
  margin-top: -30px;
}
.m-r--30 {
  margin-right: -30px;
}
.m-b--30 {
  margin-bottom: -30px;
}
.m-l--25 {
  margin-left: -25px;
}
.m-t--25 {
  margin-top: -25px;
}
.m-r--25 {
  margin-right: -25px;
}
.m-b--25 {
  margin-bottom: -25px;
}
.m-l--20 {
  margin-left: -20px;
}
.m-t--20 {
  margin-top: -20px;
}
.m-r--20 {
  margin-right: -20px;
}
.m-b--20 {
  margin-bottom: -20px;
}
.m-l--15 {
  margin-left: -15px;
}
.m-t--15 {
  margin-top: -15px;
}
.m-r--15 {
  margin-right: -15px;
}
.m-b--15 {
  margin-bottom: -15px;
}
.m-l--10 {
  margin-left: -10px;
}
.m-t--10 {
  margin-top: -10px;
}
.m-r--10 {
  margin-right: -10px;
}
.m-b--10 {
  margin-bottom: -10px;
}
.m-l--5 {
  margin-left: -5px;
}
.m-t--5 {
  margin-top: -5px;
}
.m-r--5 {
  margin-right: -5px;
}
.m-b--5 {
  margin-bottom: -5px;
}
.m-l-0 {
  margin-left: 0px;
}
.m-t-0,
.weather2 .days-list .day h5,
.weather .city,
.weather .city h3,
.page-calendar .event-name h4 {
  margin-top: 0px;
}
.m-r-0,
.panel-group .panel .panel-title > a i.right,
.c_list .fancy-checkbox {
  margin-right: 0px;
}
.m-b-0,
.card .header,
ul.choose-skin,
.auth-main .lead,
.block-header h2,
.block-header .breadcrumb,
#toast-container .toast,
.weather .city h3,
.weather6 p,
.top_counter .content .number,
.basic-list,
.c_list .fancy-checkbox,
.c_list .c_name,
.c_list address,
.social_media_table .table,
.feeds_widget,
.chat-app .chat .chat-history ul li:last-child,
.page-calendar .event-name h6,
.page-calendar .event-name h4,
.page-calendar .event-name p,
.page-calendar .event-name address,
.pricing,
.pricing h3 {
  margin-bottom: 0px;
}
.m-l-5,
.badge,
.btn-toolbar > .btn-group,
.wizard .actions a,
.pricing3 .pricing-option .price b {
  margin-left: 5px;
}
.m-t-5,
.card .header h2 small,
.user-account .dropdown,
.auth-main .lead,
.top_summary small,
.setting-list li,
.team-info,
.page-calendar .event-name address {
  margin-top: 5px;
}
.m-r-5,
.nav.nav-tabs-new > li > a,
.nav.nav-tabs-new2 > li > a,
.badge,
.panel-group .panel .panel-title > a i,
.new_timeline .bullet,
.b4gallery .nav-item,
.page-calendar .event-name address i {
  margin-right: 5px;
}
.m-b-5,
.auth-main .btn-signin-social,
.weather .days li img {
  margin-bottom: 5px;
}
.m-l-10,
#left-sidebar .navbar-brand span,
.accordion2 .accordion-thumb span,
.c_list .c_name {
  margin-left: 10px;
}
.m-t-10,
.tab-content,
.navbar-fixed-top .navbar-btn,
.page-loader-wrapper p,
.weather3 .top .wrapper .temp .deg,
.social-links {
  margin-top: 10px;
}
.m-r-10,
.user-account .dropdown .dropdown-menu a i,
ul.choose-skin li div,
.list-referrals .value,
.top_counter .icon,
.right_chat .media .media-object,
.testimonial4 .carousel-info img {
  margin-right: 10px;
}
.m-b-10,
.rightbar .card h6,
ul.choose-skin li div,
.weather4 i,
.pricing3 .pricing-option .price {
  margin-bottom: 10px;
}
.m-l-15 {
  margin-left: 15px;
}
.m-t-15 {
  margin-top: 15px;
}
.m-r-15,
.navbar-fixed-top .navbar-btn,
.navbar-nav,
.new_timeline .header .color-overlay .day-number {
  margin-right: 15px;
}
.m-b-15,
.rightbar .nav,
.w_social3 .card .fa,
.chat-app .chat .chat-history .message-data {
  margin-bottom: 15px;
}
.m-l-20,
.media.mright .media-right,
.chat-widget li.left .chat-info,
.invoice1 .info {
  margin-left: 20px;
}
.m-t-20,
.auth-main .form-auth-small .btn,
.auth-main .form-auth-small .bottom,
.weather3 .top .wrapper .heading,
.weather3 .top .wrapper .location,
.stats-report,
.pricing3 .pricing-option .price {
  margin-top: 20px;
}
.m-r-20,
.navbar-fixed-top .navbar-right #navbar-search,
.block-header .breadcrumb,
.media.mleft .media-left,
.bs-example .media .media-left,
.chat-widget li.right .chat-info {
  margin-right: 20px;
}
.m-b-20,
.media,
.chat-widget li,
.weather .city,
.chart.easy-pie-chart-1,
.profilepage_2 .profile-header .profile-image {
  margin-bottom: 20px;
}
.m-l-25 {
  margin-left: 25px;
}
.m-t-25 {
  margin-top: 25px;
}
.m-r-25 {
  margin-right: 25px;
}
.m-b-25,
.block-header,
.pricing2 .pricing-plan .pricing-img {
  margin-bottom: 25px;
}
.m-l-30 {
  margin-left: 30px;
}
.m-t-30 {
  margin-top: 30px;
}
.m-r-30 {
  margin-right: 30px;
}
.m-b-30,
.testimonial2,
.testimonial3,
.testimonial4,
.chat-app .chat .chat-history ul li {
  margin-bottom: 30px;
}
.m-l-35 {
  margin-left: 35px;
}
.m-t-35 {
  margin-top: 35px;
}
.m-r-35 {
  margin-right: 35px;
}
.m-b-35 {
  margin-bottom: 35px;
}
.m-l-40 {
  margin-left: 40px;
}
.m-t-40 {
  margin-top: 40px;
}
.m-r-40 {
  margin-right: 40px;
}
.m-b-40 {
  margin-bottom: 40px;
}
.m-l-45 {
  margin-left: 45px;
}
.m-t-45 {
  margin-top: 45px;
}
.m-r-45 {
  margin-right: 45px;
}
.m-b-45 {
  margin-bottom: 45px;
}
.m-l-50 {
  margin-left: 50px;
}
.m-t-50 {
  margin-top: 50px;
}
.m-r-50 {
  margin-right: 50px;
}
.m-b-50 {
  margin-bottom: 50px;
}
.m-l-55 {
  margin-left: 55px;
}
.m-t-55 {
  margin-top: 55px;
}
.m-r-55 {
  margin-right: 55px;
}
.m-b-55 {
  margin-bottom: 55px;
}
.m-l-60 {
  margin-left: 60px;
}
.m-t-60 {
  margin-top: 60px;
}
.m-r-60 {
  margin-right: 60px;
}
.m-b-60 {
  margin-bottom: 60px;
}
.m-l-65 {
  margin-left: 65px;
}
.m-t-65 {
  margin-top: 65px;
}
.m-r-65 {
  margin-right: 65px;
}
.m-b-65 {
  margin-bottom: 65px;
}
.m-l-70 {
  margin-left: 70px;
}
.m-t-70 {
  margin-top: 70px;
}
.m-r-70 {
  margin-right: 70px;
}
.m-b-70 {
  margin-bottom: 70px;
}
.m-l-75 {
  margin-left: 75px;
}
.m-t-75 {
  margin-top: 75px;
}
.m-r-75 {
  margin-right: 75px;
}
.m-b-75 {
  margin-bottom: 75px;
}
.m-l-80 {
  margin-left: 80px;
}
.m-t-80 {
  margin-top: 80px;
}
.m-r-80 {
  margin-right: 80px;
}
.m-b-80 {
  margin-bottom: 80px;
}
.m-l-85 {
  margin-left: 85px;
}
.m-t-85 {
  margin-top: 85px;
}
.m-r-85 {
  margin-right: 85px;
}
.m-b-85 {
  margin-bottom: 85px;
}
.m-l-90 {
  margin-left: 90px;
}
.m-t-90 {
  margin-top: 90px;
}
.m-r-90 {
  margin-right: 90px;
}
.m-b-90 {
  margin-bottom: 90px;
}
.m-l-95 {
  margin-left: 95px;
}
.m-t-95 {
  margin-top: 95px;
}
.m-r-95 {
  margin-right: 95px;
}
.m-b-95 {
  margin-bottom: 95px;
}
.m-l-100 {
  margin-left: 100px;
}
.m-t-100 {
  margin-top: 100px;
}
.m-r-100 {
  margin-right: 100px;
}
.m-b-100 {
  margin-bottom: 100px;
}
.m-l-105 {
  margin-left: 105px;
}
.m-t-105 {
  margin-top: 105px;
}
.m-r-105 {
  margin-right: 105px;
}
.m-b-105 {
  margin-bottom: 105px;
}
.m-l-110 {
  margin-left: 110px;
}
.m-t-110 {
  margin-top: 110px;
}
.m-r-110 {
  margin-right: 110px;
}
.m-b-110 {
  margin-bottom: 110px;
}
.m-l-115 {
  margin-left: 115px;
}
.m-t-115 {
  margin-top: 115px;
}
.m-r-115 {
  margin-right: 115px;
}
.m-b-115 {
  margin-bottom: 115px;
}
.m-l-120 {
  margin-left: 120px;
}
.m-t-120 {
  margin-top: 120px;
}
.m-r-120 {
  margin-right: 120px;
}
.m-b-120 {
  margin-bottom: 120px;
}
.m-l-125 {
  margin-left: 125px;
}
.m-t-125 {
  margin-top: 125px;
}
.m-r-125 {
  margin-right: 125px;
}
.m-b-125 {
  margin-bottom: 125px;
}
.margin-0,
.card .header .header-dropdown,
.card .header h2,
.navbar-nav,
.sidebar-nav ul,
.accordion2,
.accordion2 .accordion-thumb,
.accordion2 .accordion-panel,
.media .media-body .media-heading,
.wizard .content,
.wizard .content .body label.error,
.weather2 .city-selected .temp h2,
.weather2 .days-list,
.weather3 .bottom .forecast,
.weather4 p,
.stats-report .stat-item h5,
.new_timeline > ul,
.new_timeline h3,
.new_timeline h4,
.c_list tr td .btn,
.taskboard .dd-handle p,
.w_social2 .pw_content .pw_header h6,
.w_user .wid-u-info h5,
.feeds_widget li .feeds-body .title,
.timeline-item h5,
.fc-overlay.right,
.fc .fc-toolbar,
.fc .fc-toolbar h2 {
  margin: 0 !important;
}
.p-l-0,
.pricing,
.invoice .nav-tabs {
  padding-left: 0px;
}
.p-t-0,
.basic-list li:first-child {
  padding-top: 0px;
}
.p-r-0,
.invoice .nav-tabs {
  padding-right: 0px;
}
.p-b-0,
.number-chart .body,
.basic-list li:last-child {
  padding-bottom: 0px;
}
.p-l-5,
.chat-app .chat .chat-history .message-data-time {
  padding-left: 5px;
}
.p-t-5,
.fc .fc-toolbar h2 {
  padding-top: 5px;
}
.p-r-5,
.wizard.vertical > .steps {
  padding-right: 5px;
}
.p-b-5 {
  padding-bottom: 5px;
}
.sidebar-nav .metismenu > li {
  padding-bottom: 0px;
}
.layout-fullwidth .collapse.in li {
  border-top: 1px solid #e0d7d7;
}

.p-l-10,
.navbar-fixed-top .navbar-btn button,
.chat-app .chat .chat-header .chat-about {
  padding-left: 10px;
}
.p-t-10,
.fc .fc-toolbar {
  padding-top: 10px;
}
.p-r-10 {
  padding-right: 10px;
}
.p-b-10,
.fc .fc-toolbar {
  padding-bottom: 10px;
}
.p-l-15 {
  padding-left: 15px;
}
.p-t-15,
.basic-list li {
  padding-top: 15px;
}
.p-r-15,
.navbar-fixed-top .navbar-btn,
.stats-report .stat-item {
  padding-right: 15px;
}
.p-b-15,
.rightbar .nav,
.basic-list li,
.new_timeline li {
  padding-bottom: 15px;
}
.p-l-20,
.navbar-fixed-top .navbar-right #navbar-search .form-control {
  padding-left: 20px;
}
.p-t-20,
.weather3 .bottom .forecast {
  padding-top: 20px;
}
.p-r-20 {
  padding-right: 20px;
}
.p-b-20 {
  padding-bottom: 20px;
}
.p-l-25 {
  padding-left: 25px;
}
.p-t-25 {
  padding-top: 25px;
}
.p-r-25 {
  padding-right: 25px;
}
.p-b-25 {
  padding-bottom: 25px;
}
.p-l-30 {
  padding-left: 30px;
}
.p-t-30 {
  padding-top: 30px;
}
.p-r-30 {
  padding-right: 30px;
}
.p-b-30 {
  padding-bottom: 30px;
}
.p-l-35 {
  padding-left: 35px;
}
.p-t-35 {
  padding-top: 35px;
}
.p-r-35 {
  padding-right: 35px;
}
.p-b-35 {
  padding-bottom: 35px;
}
.p-l-40 {
  padding-left: 40px;
}
.p-t-40,
.maintenance > div {
  padding-top: 40px;
}
.p-r-40 {
  padding-right: 40px;
}
.p-b-40,
.maintenance > div {
  padding-bottom: 40px;
}
.p-l-45 {
  padding-left: 45px;
}
.p-t-45 {
  padding-top: 45px;
}
.p-r-45 {
  padding-right: 45px;
}
.p-b-45 {
  padding-bottom: 45px;
}
.p-l-50 {
  padding-left: 50px;
}
.p-t-50 {
  padding-top: 50px;
}
.p-r-50 {
  padding-right: 50px;
}
.p-b-50 {
  padding-bottom: 50px;
}
.p-l-55 {
  padding-left: 55px;
}
.p-t-55 {
  padding-top: 55px;
}
.p-r-55 {
  padding-right: 55px;
}
.p-b-55 {
  padding-bottom: 55px;
}
.p-l-60 {
  padding-left: 60px;
}
.p-t-60 {
  padding-top: 60px;
}
.p-r-60 {
  padding-right: 60px;
}
.p-b-60 {
  padding-bottom: 60px;
}
.p-l-65 {
  padding-left: 65px;
}
.p-t-65 {
  padding-top: 65px;
}
.p-r-65 {
  padding-right: 65px;
}
.p-b-65 {
  padding-bottom: 65px;
}
.p-l-70 {
  padding-left: 70px;
}
.p-t-70 {
  padding-top: 70px;
}
.p-r-70 {
  padding-right: 70px;
}
.p-b-70 {
  padding-bottom: 70px;
}
.p-l-75 {
  padding-left: 75px;
}
.p-t-75 {
  padding-top: 75px;
}
.p-r-75 {
  padding-right: 75px;
}
.p-b-75 {
  padding-bottom: 75px;
}
.p-l-80 {
  padding-left: 80px;
}
.p-t-80 {
  padding-top: 80px;
}
.p-r-80 {
  padding-right: 80px;
}
.p-b-80 {
  padding-bottom: 80px;
}
.p-l-85 {
  padding-left: 85px;
}
.p-t-85 {
  padding-top: 85px;
}
.p-r-85 {
  padding-right: 85px;
}
.p-b-85 {
  padding-bottom: 85px;
}
.p-l-90 {
  padding-left: 90px;
}
.p-t-90 {
  padding-top: 90px;
}
.p-r-90 {
  padding-right: 90px;
}
.p-b-90 {
  padding-bottom: 90px;
}
.p-l-95 {
  padding-left: 95px;
}
.p-t-95 {
  padding-top: 95px;
}
.p-r-95 {
  padding-right: 95px;
}
.p-b-95 {
  padding-bottom: 95px;
}
.p-l-100 {
  padding-left: 100px;
}
.p-t-100 {
  padding-top: 100px;
}
.p-r-100 {
  padding-right: 100px;
}
.p-b-100 {
  padding-bottom: 100px;
}
.p-l-105 {
  padding-left: 105px;
}
.p-t-105 {
  padding-top: 105px;
}
.p-r-105 {
  padding-right: 105px;
}
.p-b-105 {
  padding-bottom: 105px;
}
.p-l-110 {
  padding-left: 110px;
}
.p-t-110 {
  padding-top: 110px;
}
.p-r-110 {
  padding-right: 110px;
}
.p-b-110 {
  padding-bottom: 110px;
}
.p-l-115 {
  padding-left: 115px;
}
.p-t-115 {
  padding-top: 115px;
}
.p-r-115 {
  padding-right: 115px;
}
.p-b-115 {
  padding-bottom: 115px;
}
.p-l-120 {
  padding-left: 120px;
}
.p-t-120 {
  padding-top: 120px;
}
.p-r-120 {
  padding-right: 120px;
}
.p-b-120 {
  padding-bottom: 120px;
}
.p-l-125 {
  padding-left: 125px;
}
.p-t-125 {
  padding-top: 125px;
}
.p-r-125 {
  padding-right: 125px;
}
.p-b-125 {
  padding-bottom: 125px;
}
.padding-0,
.card .header .header-dropdown,
.sidebar-nav ul,
.search-form,
.search-form .btn,
.block-header .breadcrumb,
.accordion .card-header,
.accordion2,
.chat-widget,
.weather3 .bottom .forecast,
.new_timeline .header,
.right_chat .media .status,
.chat-app .chat .chat-history ul,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-agenda-gutter,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  td.fc-agenda-gutter,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > thead
  tr
  th.fc-agenda-gutter,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > thead
  tr
  td.fc-agenda-gutter,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  td.fc-widget-header,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > thead
  tr
  td.fc-widget-header,
.pricing2 .pricing-plan .pricing-features {
  padding: 0 !important;
}
.font-6 {
  font-size: 6px;
}
.font-7 {
  font-size: 7px;
}
.font-8 {
  font-size: 8px;
}
.font-9 {
  font-size: 9px;
}
.font-10 {
  font-size: 10px;
}
.font-11,
.page-calendar .event-name h4 span,
.page-calendar .event-name address {
  font-size: 11px;
}
.font-12,
.card .header .header-dropdown li a > i,
.card .header h2 small,
.page-calendar .event-name address i,
.fc .fc-view-container .fc-event,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-allday
  .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr th,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
  font-size: 12px;
}
.font-13,
.event-control {
  font-size: 13px;
}
.font-14,
.user-account .dropdown .dropdown-menu a,
.user-account .dropdown .dropdown-menu a i,
.sidebar-nav .metismenu a,
.panel-group .panel .panel-title,
.ct-label {
  font-size: 14px;
}
.font-15,
.fc .fc-view-container .fc-view.fc-basic-view > table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda > table tbody tr td.fc-day-number {
  font-size: 15px;
}
.font-16,
.card .header h2,
.accordion .card-header .btn,
.accordion2 .accordion-thumb,
.fc .fc-toolbar h2,
.fc .fc-toolbar .fc-today-button,
.fc .fc-toolbar .fc-state-default {
  font-size: 16px;
}
.font-17 {
  font-size: 17px;
}
.font-18,
#left-sidebar .navbar-brand span,
.panel-group .panel .panel-title > a i {
  font-size: 18px;
}
.font-19 {
  font-size: 19px;
}
.font-20 {
  font-size: 20px;
}
.font-21 {
  font-size: 21px;
}
.font-22 {
  font-size: 22px;
}
.font-23 {
  font-size: 23px;
}
.font-24 {
  font-size: 24px;
}
.font-25,
.page-calendar .event-name h4 {
  font-size: 25px;
}
.font-26 {
  font-size: 26px;
}
.font-27 {
  font-size: 27px;
}
.font-28 {
  font-size: 28px;
}
.font-29 {
  font-size: 29px;
}
.font-30 {
  font-size: 30px;
}
.font-31 {
  font-size: 31px;
}
.font-32 {
  font-size: 32px;
}
.font-33 {
  font-size: 33px;
}
.font-34 {
  font-size: 34px;
}
.font-35 {
  font-size: 35px;
}
.font-36 {
  font-size: 36px;
}
.font-37 {
  font-size: 37px;
}
.font-38 {
  font-size: 38px;
}
.font-39 {
  font-size: 39px;
}
.font-40 {
  font-size: 40px;
}
.font-41 {
  font-size: 41px;
}
.font-42 {
  font-size: 42px;
}
.font-43 {
  font-size: 43px;
}
.font-44 {
  font-size: 44px;
}
.font-45 {
  font-size: 45px;
}
.font-46 {
  font-size: 46px;
}
.font-47 {
  font-size: 47px;
}
.font-48 {
  font-size: 48px;
}
.font-49 {
  font-size: 49px;
}
.font-50 {
  font-size: 50px;
}
.bg-success {
  background-color: #22af46;
}
.bg-warning {
  background-color: #f3ad06;
}
.bg-danger {
  background-color: #de4848;
}
.bg-info {
  background-color: #3c89da;
}
.align-left,
.accordion .card-header .btn,
.chat-widget li.left .chat-info {
  text-align: left;
}
.align-center,
.navbar-nav ul.app_menu li a,
.user-account,
.auth-main .form-auth-small .bottom,
.metric-inline,
.separator-linethrough,
.page-loader-wrapper,
.info-box-2 .chart,
.weather2 .days-list .day,
.weather .days li,
.weather3 .top,
.top_counter .icon,
.chart.easy-pie-chart-1,
.chart.easy-pie-chart-1 > span > img,
.c_list address i,
.social_icon,
.profile_state,
.testimonials .body,
.member-card,
.w_social3 .card,
.profilepage_2 .profile-header,
.pricing,
.pricing2,
.pricing3 {
  text-align: center;
}
.align-right,
.media.mright .media-body,
.wizard .actions,
.chat-widget li.right,
.chat-widget li.right .chat-info,
.chat-app .chat .chat-history .other-message {
  text-align: right;
}
.align-justify {
  text-align: justify;
}
.p-25 {
  padding: 25px !important;
}
.font300 {
  font-weight: 300;
}
.font600 {
  font-weight: 600;
}
.font700 {
  font-weight: 700;
}
.w40 {
  width: 40px;
}
.w45 {
  width: 45px;
}
.w60 {
  width: 60px;
}
.w80 {
  width: 80px;
}
.media .media-body {
  color: #666;
  font-size: 14px;
}
.media .media-body .media-heading {
  font-size: 16px;
  font-weight: 700;
  color: #222;
}
.wizard .content {
  min-height: 245px;
  overflow-y: auto;
  border-radius: 0;
}
.wizard .content .body {
  padding: 15px;
}
.wizard .content .body label.error {
  font-size: 12px;
  color: #de4848;
}
.wizard .content .body input[type="checkbox"] {
  display: none;
}
.wizard .actions {
  position: relative;
  width: 100%;
  margin-top: 10px !important;
}
.wizard .actions ul > li {
  float: left;
  margin: 0 !important;
}
.wizard .actions a {
  color: #fff;
  text-decoration: none;
}
.wizard .actions a:hover,
.wizard .actions a:active {
  color: #fff;
  text-decoration: none;
}
.wizard .actions .disabled a {
  background: #f0f0f0;
  color: #aaa;
}
.wizard .actions .disabled a:hover,
.wizard .actions .disabled a:active {
  background: #f0f0f0;
  color: #aaa;
}
.wizard .steps {
  position: relative;
  width: 100%;
  margin-bottom: 2px;
}
.wizard .steps a {
  width: auto;
  margin: 0 2px 0 0;
  padding: 10px;
  text-decoration: none;
  border-radius: 0;
}
.wizard .steps a:hover,
.wizard .steps a:active {
  width: auto;
  margin: 0 2px 0 0;
  padding: 10px;
  text-decoration: none;
  border-radius: 0;
}
.wizard .steps .number {
  font-size: 15px;
}
.wizard .steps .disabled a {
  background: #f0f0f0;
  color: #bdbdbd;
  cursor: default;
}
.wizard .steps .disabled a:hover,
.wizard .steps .disabled a:active {
  background: #f0f0f0;
  color: #bdbdbd;
  cursor: default;
}
.wizard .steps .current a {
  color: #fff;
  cursor: default;
}
.wizard .steps .current a:hover,
.wizard .steps .current a:active {
  color: #fff;
  cursor: default;
}
.wizard .steps .done a {
  color: #fff;
  opacity: 0.7;
}
.wizard .steps .done a:hover,
.wizard .steps .done a:active {
  color: #fff;
  opacity: 0.7;
}
.wizard .steps > ul > li {
  width: 25%;
  float: left;
}
.wizard .steps > ul > li:last-child a {
  margin: 0;
}
.wizard.vertical > .steps {
  float: left;
  width: 30%;
}
.wizard.vertical > .steps > ul > li {
  float: none;
  width: 100%;
}
.wizard.vertical > .steps > ul > li a {
  margin: 0 0 2px 0;
}
.wizard.vertical > .content {
  float: left;
  width: 70%;
  margin: 0;
}
.wizard.vertical > .actions {
  float: right;
  width: 100%;
  margin: 0;
}
.wizard_validation .wizard .steps > ul > li {
  width: auto !important;
}
@media screen and (max-width: 767px) {
  .wizard > .steps > ul > li {
    width: 100%;
    margin-bottom: 2px;
  }
  .wizard > .steps a {
    margin: 0;
  }
  .wizard.wizard.vertical > .content,
  .wizard.wizard.vertical > .steps {
    width: 100%;
    padding: 0;
  }
  .wizard_validation .wizard .steps > ul > li {
    width: 100% !important;
  }
}
.table .thead-primary th {
  color: #fff;
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.table .thead-success th {
  color: #fff;
  background-color: #22af46;
  border-color: #22af46;
}
.table .thead-danger th {
  color: #fff;
  background-color: #de4848;
  border-color: #de4848;
}
.table .thead-info th {
  color: #fff;
  background-color: #3c89da;
  border-color: #3c89da;
}
.table .thead-purple th {
  color: #fff;
  background-color: #6462ef;
  border-color: #6462ef;
}
.table tbody tr td,
.table tbody th td {
  vertical-align: middle;
  white-space: nowrap;
}
@media screen and (max-width: 767px) {
  div.dataTables_paginate .pagination {
    justify-content: left !important;
  }
  .dataTables_wrapper .col-sm-12.col-md-6,
  .dataTables_wrapper .col-sm-12.col-md-5,
  .dataTables_wrapper .col-sm-12.col-md-7 {
    padding: 0;
  }
  .dataTables_wrapper .col-sm-12.col-md-6 .dataTables_length,
  .dataTables_wrapper .col-sm-12.col-md-6 .dataTables_filter,
  .dataTables_wrapper .col-sm-12.col-md-6 .dataTables_info,
  .dataTables_wrapper .col-sm-12.col-md-6 .paging_simple_numbers,
  .dataTables_wrapper .col-sm-12.col-md-5 .dataTables_length,
  .dataTables_wrapper .col-sm-12.col-md-5 .dataTables_filter,
  .dataTables_wrapper .col-sm-12.col-md-5 .dataTables_info,
  .dataTables_wrapper .col-sm-12.col-md-5 .paging_simple_numbers,
  .dataTables_wrapper .col-sm-12.col-md-7 .dataTables_length,
  .dataTables_wrapper .col-sm-12.col-md-7 .dataTables_filter,
  .dataTables_wrapper .col-sm-12.col-md-7 .dataTables_info,
  .dataTables_wrapper .col-sm-12.col-md-7 .paging_simple_numbers {
    text-align: left !important;
  }
  div.dataTables_wrapper div.dataTables_filter {
    text-align: left !important;
    float: left !important;
  }
  .table-responsive .dt-buttons {
    margin-bottom: 10px;
  }
}
.top_summary h2 {
  margin: 0;
}
.top_summary small {
  line-height: 14px;
  display: inline-block;
  vertical-align: text-top;
}
.page-loader-wrapper {
  z-index: 99999999;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.page-loader-wrapper p {
  color: #fff;
}
.page-loader-wrapper .loader {
  position: relative;
  top: calc(40% - 30px);
}
.list-referrals > li {
  margin-bottom: 22px;
}
.list-referrals .value {
  font-size: 20px;
}
.number-chart .number {
  font-weight: 600;
}
.number-chart .number > span {
  font-size: 22px;
  line-height: 1.4;
}
.number-chart canvas {
  width: 100% !important;
}
.chat-widget li {
  list-style: none;
  width: 100%;
}
.chat-widget li.left img {
  float: left;
}
.chat-widget li.left .chat-info {
  float: left;
  background: #f7f7f7;
}
.chat-widget li.left .chat-info:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #f7f7f7;
  left: -10px;
  top: 12px;
}
.chat-widget li.right img {
  float: right;
}
.chat-widget li.right .chat-info:before {
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  right: -10px;
  top: 12px;
}
.chat-widget li .chat-info {
  width: calc(100% - 60px);
  border-radius: 8px;
  padding: 20px;
  position: relative;
}
.chat-widget li .chat-info:before {
  width: 0;
  height: 0;
  content: "";
  position: absolute;
  top: 12px;
}
.chat-widget li .datetime {
  font-size: 12px;
  color: #bdbdbd;
}
.chat-widget li img {
  height: 40px;
  width: 40px;
}
.info-box-2 {
  display: flex;
  cursor: default;
  position: relative;
  overflow: hidden;
  min-height: 130px;
}
.info-box-2 .icon i {
  font-size: 30px;
  line-height: 70px;
}
.info-box-2 .chart {
  position: relative;
  width: 100px;
}
.info-box-2 .chart.chart-bar {
  height: 100%;
}
.info-box-2 .chart.chart-bar canvas {
  vertical-align: baseline !important;
}
.info-box-2 .chart.chart-pie {
  height: 100%;
}
.info-box-2 .chart.chart-pie canvas {
  vertical-align: baseline !important;
}
.info-box-2 .chart.chart-line {
  height: 100%;
}
.info-box-2 .chart.chart-line canvas {
  vertical-align: baseline !important;
}
.info-box-2 .content {
  padding: 7px 0px;
}
.info-box-2 .content .text {
  margin-top: 11px;
  color: #999;
}
.info-box-2 .content .number {
  font-weight: normal;
  font-size: 26px;
  color: #666;
}
.weather2 .city-selected {
  position: relative;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
}
.weather2 .city-selected .city {
  font-size: 24px;
}
.weather2 .city-selected .city span {
  font-size: 13px;
  text-transform: lowercase;
}
.weather2 .city-selected .night {
  font-size: 15px;
  text-transform: uppercase;
}
.weather2 .city-selected .temp h2 {
  font-size: 73px;
  position: relative;
}
.weather2 .city-selected .icon i {
  font-size: 70px;
}
.weather2 .table tbody tr td {
  padding: 14px 20px;
}
.weather2 .days-list .day {
  padding: 20px 10px;
}
.weather2 .days-list .day i {
  font-size: 30px;
}
.weather2 .carousel .carousel-inner {
  box-shadow: none;
}
.weather .city {
  position: relative;
}
.weather .city i {
  font-size: 40px;
  position: absolute;
  right: 0;
  top: 0;
}
.weather .days li {
  width: 13.2%;
}
.weather .days li img {
  width: 25px;
}
.weather .days li h5 {
  font-size: 14px;
}
.weather3 {
  overflow: hidden;
}
.weather3 .top {
  position: relative;
  height: 430px;
  width: 100%;
  overflow: hidden;
  /* background: url("./../images/weather_img.jpg") no-repeat; */
  background-size: cover;
  background-position: center center;
}
.weather3 .top::after {
  content: "";
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
.weather3 .top .wrapper {
  padding: 30px;
  position: relative;
  z-index: 1;
  color: #fff;
}
.weather3 .top .wrapper .mynav {
  height: 20px;
}
.weather3 .top .wrapper .heading {
  font-size: 35px;
  font-weight: 400;
}
.weather3 .top .wrapper .location {
  font-size: 21px;
  font-weight: 400;
}
.weather3 .top .wrapper .temp .temp-value {
  font-size: 85px;
  font-weight: 600;
}
.weather3 .top .wrapper .temp .deg {
  font-size: 35px;
  font-weight: 600;
  vertical-align: top;
}
.weather3 .top .wrapper .temp .temp-type {
  font-size: 85px;
  color: #fff;
}
.weather3 .bottom {
  padding: 20px;
  background: #fff;
}
.weather3 .bottom .forecast {
  overflow: hidden;
  font-size: 0;
  max-height: 155px;
}
.weather3 .bottom .forecast li {
  font-size: 18px;
  color: #bdbdbd;
  line-height: 2em;
}
.weather3 .bottom .forecast li.active {
  color: #777;
}
.weather3 .bottom .forecast li .condition {
  float: right;
  line-height: 2em;
}
.weather4 i {
  font-size: 35px;
}
.weather5 {
  overflow: hidden;
}
.weather5 .widget {
  display: flex;
  flex-wrap: wrap;
}
.weather5 .weatherIcon {
  flex: 1 1 100%;
  height: 60%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 80px;
  padding: 20px 0;
}
.weather5 .weatherIcon i {
  padding-top: 20px;
}
.weather5 .weatherInfo {
  flex: 0 0 70%;
  height: 40%;
  background: #17191c;
  display: flex;
  align-items: center;
  color: #fff;
}
.weather5 .weatherInfo .temperature {
  flex: 0 0 40%;
  width: 100%;
  font-size: 65px;
  display: flex;
  justify-content: space-around;
}
.weather5 .weatherInfo .description {
  flex: 0 1 60%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.weather5 .date {
  flex: 0 0 30%;
  height: 40%;
  background: #00bcd2;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #fff;
  font-size: 28px;
  line-height: 97px;
}
.weather6 {
  text-align: center;
}
.weather6 .temp {
  font-size: 30px;
}
.top_counter .icon {
  float: left;
  width: 50px;
  height: 50px;
  background: #f7f7f7;
  border-radius: 0.55rem;
}
.top_counter .icon i {
  font-size: 25px;
  line-height: 50px;
}
.top_counter .content {
  height: 50px;
}
.stats-report .stat-item h5 {
  font-size: 13px;
}
.basic-list li span {
  float: right;
}
.new_timeline .header {
  background: url(http://via.placeholder.com/275x120);
  background-size: cover;
  color: #fff;
  position: relative;
}
.new_timeline .header .color-overlay {
  padding: 2em;
  box-sizing: border-box;
  background: rgba(123, 94, 155, 0.5);
  line-height: normal;
}
.new_timeline .header .color-overlay .day-number {
  font-size: 4em;
}
.new_timeline .header .color-overlay .day-name {
  font-size: 23px;
}
.new_timeline .header .color-overlay .month {
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 2px;
  margin-top: 2px;
}
.new_timeline > ul {
  padding: 1em 0 0 2em;
  list-style: none;
  position: relative;
}
.new_timeline > ul::before {
  content: " ";
  height: 100%;
  width: 1px;
  background-color: #f0f0f0;
  position: absolute;
  top: 0;
  left: 2.5em;
  z-index: 1;
}
.new_timeline li div {
  margin: 7px 0;
  vertical-align: top;
}
.new_timeline .bullet {
  width: 1em;
  height: 1em;
  box-sizing: border-box;
  border-radius: 50%;
  background: #fff;
  z-index: 2;
  position: relative;
}
.new_timeline .bullet.pink {
  border: 2px solid #e25985;
}
.new_timeline .bullet.green {
  border: 2px solid #50d38a;
}
.new_timeline .bullet.orange {
  border: 2px solid #feb800;
}
.new_timeline .time {
  width: 20%;
  font-size: 0.75em;
  padding-top: 0.25em;
}
.new_timeline .desc {
  width: 65%;
}
.new_timeline h3 {
  font-size: 15px;
  font-weight: 400;
}
.new_timeline h4 {
  font-size: 13px;
  font-weight: 400;
  color: #999;
}
.new_timeline .people img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.team-info li {
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
}
.team-info li + li {
  margin-left: -10px;
}
.team-info li img {
  width: 35px;
  border-radius: 50%;
  border: 2px solid #fff;
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}
.team-info li:hover {
  transform: translateY(-4px) scale(1.02);
  z-index: 999;
}
.team-info li:hover img {
  box-shadow: 0 14px 24px rgba(62, 57, 107, 0.2);
}
.list-widget .badge {
  float: right;
}
.list-widget .list-group-item {
  border-left: 0;
  border-right: 0;
  padding: 20px 20px;
  border-color: #f7f7f7;
}
.list-widget .list-group-item:first-child {
  border-top: 0;
}
.list-widget .list-group-item:last-child {
  border-bottom: 0;
}
.list-widget .list-group-item i {
  width: 30px;
  font-size: 18px;
}
.chart.easy-pie-chart-1 {
  position: relative;
  width: 100px;
  height: 100px;
}
.chart.easy-pie-chart-1 > span > img {
  left: 0;
  margin-top: -2px;
  position: absolute;
  right: 0;
  top: 50%;
  width: 60%;
  height: 60%;
  transform: translateY(-50%);
  margin: 0 auto;
}
.c_list tr td {
  vertical-align: middle;
}
.c_list .c_name {
  font-weight: 600;
}
.c_list .avatar {
  width: 40px;
  box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);
  border: 1px solid #fff;
}
.c_list address i {
  width: 25px;
  font-size: 15px;
}
.team_list .dd-handle {
  background: #f4f7f6;
}
.taskboard .dd-handle {
  background: #f7f7f7;
  border-radius: 3px;
  padding: 20px;
  position: relative;
}
.taskboard .dd-handle::after {
  content: "";
  width: 40px;
  height: 5px;
  position: absolute;
  top: -1px;
  right: 20px;
}
.taskboard .planned_task .dd-handle::after {
  background: #0e9be2;
}
.taskboard .progress_task .dd-handle::after {
  background: #feb800;
}
.taskboard .completed_task .dd-handle::after {
  background: #86c541;
}
.taskboard .dd-item:hover {
  cursor: move;
}
.b4gallery .gallery-item:hover {
  cursor: pointer;
}
.right_chat li {
  position: relative;
}
.right_chat li.offline .status {
  background: #ff9800;
}
.right_chat li.online .status {
  background: #8bc34a;
}
.right_chat li.me .status {
  background: #0498bd;
}
.right_chat .media .media-object {
  width: 40px;
  height: 40px;
  border-radius: 40px;
  border: 2px solid transparent;
  transition: all 0.3s ease-in-out;
}
.right_chat .media .name {
  color: #444;
  margin: 0px;
  font-size: 15px;
}
.right_chat .media .message {
  font-size: 13px;
  color: #bdbdbd;
}
.right_chat .media .status {
  position: absolute;
  left: 23px;
  top: 30px;
  width: 11px;
  height: 11px;
  min-width: inherit;
  border: 2px solid #fff;
  border-radius: 50px;
}
.table-custom.table {
  border-collapse: collapse !important;
}
.table-custom.table thead th {
  border-top: 0;
}
.table-custom.table tfoot th {
  border-bottom: 0;
}
.table-custom.table tbody tr:hover {
  background-color: #f4f7f6;
}
.dt-bootstrap4 .dt-buttons,
.dt-bootstrap4 .dataTables_info {
  margin-bottom: 10px;
}
.dt-bootstrap4 .dt-buttons + .dataTables_filter,
.dt-bootstrap4 .dt-buttons + .dataTables_paginate,
.dt-bootstrap4 .dataTables_info + .dataTables_filter,
.dt-bootstrap4 .dataTables_info + .dataTables_paginate {
  float: right;
}
.social_media_table .table tbody tr td {
  vertical-align: middle;
}
.social_media_table .list-name {
  text-transform: uppercase;
}
.social_icon {
  border-radius: 50px;
  color: #fff;
  line-height: 40px;
  width: 40px;
  height: 40px;
}
.social_icon.linkedin {
  background: #007bb5;
}
.social_icon.twitter-table {
  background: #55acee;
}
.social_icon.facebook {
  background: #007bb5;
}
.social_icon.google {
  background: #dd4b39;
}
.social_icon.youtube {
  background: #bb0000;
}
.profile_state i {
  font-size: 25px;
}
.testimonials .body {
  padding: 40px;
}
.testimonials .body i {
  font-size: 50px;
  color: #bdbdbd;
}
.testimonials .body p {
  font-size: 15px;
}
.testimonial2 .testimonial-section {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  border: 1px solid #fff;
}
.testimonial2 .testimonial-section::after {
  top: 100%;
  left: 50px;
  border: solid transparent;
  content: " ";
  position: absolute;
  border-top-color: #fff;
  border-width: 15px;
  margin-left: -15px;
}
.testimonial2 .testimonial-desc {
  margin-top: 20px;
  text-align: left;
  padding-left: 15px;
}
.testimonial2 .testimonial-desc img {
  width: 70px;
}
.testimonial2 .testimonial-desc .testimonial-writer {
  display: inline-block;
  vertical-align: top;
  padding-left: 10px;
}
.testimonial2.default .testimonial-section {
  border-color: #777;
}
.testimonial2.default .testimonial-section::after {
  border-top-color: #777;
}
.testimonial2.primary .testimonial-section {
  border-color: #00bcd2;
}
.testimonial2.primary .testimonial-section::after {
  border-top-color: #00bcd2;
}
.testimonial3 .testimonial-section {
  width: 100%;
  height: auto;
  padding: 15px;
  border-radius: 5px;
  position: relative;
  border: 1px solid #fff;
}
.testimonial3 .testimonial-section::after {
  top: 100%;
  right: 50px;
  border: solid transparent;
  content: " ";
  position: absolute;
  border-top-color: #fff;
  border-width: 15px;
  margin-right: -15px;
}
.testimonial3 .testimonial-desc {
  margin-top: 20px;
  text-align: right;
  padding-right: 15px;
}
.testimonial3 .testimonial-desc img {
  width: 70px;
  float: right;
}
.testimonial3 .testimonial-desc .testimonial-writer {
  display: inline-block;
  vertical-align: top;
  padding-right: 10px;
}
.testimonial3.default .testimonial-section {
  border-color: #777;
}
.testimonial3.default .testimonial-section::after {
  border-top-color: #777;
}
.testimonial3.primary .testimonial-section {
  border-color: #00bcd2;
}
.testimonial3.primary .testimonial-section::after {
  border-top-color: #00bcd2;
}
.testimonial3.warning .testimonial-section {
  border-color: #f3ad06;
}
.testimonial3.warning .testimonial-section::after {
  border-top-color: #f3ad06;
}
.testimonial3.danger .testimonial-section {
  border-color: #de4848;
}
.testimonial3.danger .testimonial-section::after {
  border-top-color: #de4848;
}
.testimonial4 blockquote {
  background: #f7f7f7 none repeat scroll 0 0;
  border: medium none;
  color: #777;
  line-height: 20px;
  padding: 15px;
  position: relative;
}
.testimonial4 blockquote:before {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  border-bottom: 20px solid #fff;
  border-right: 0 solid transparent;
  border-left: 15px solid transparent;
  border-left-style: inset;
  border-bottom-style: inset;
}
.testimonial4 blockquote:after {
  width: 0;
  height: 0;
  right: 0;
  bottom: 0;
  content: " ";
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0 0;
}
.testimonial4 blockquote.danger:after {
  border-color: #de4848 transparent transparent transparent;
}
.testimonial4 blockquote.warning:after {
  border-color: #f3ad06 transparent transparent transparent;
}
.testimonial4 blockquote.info:after {
  border-color: #3c89da transparent transparent transparent;
}
.testimonial4 blockquote.primary:after {
  border-color: #00bcd2 transparent transparent transparent;
}
.testimonial4 .carousel-info {
  overflow: hidden;
}
.accordion.custom .card-header {
  background: transparent;
  border-color: #f0f0f0;
}
.accordion.custom .card-header .btn {
  color: #666;
}
.accordion.custom .card-header .btn:hover,
.accordion.custom .card-header .btn:focus {
  text-decoration: none;
}
.card-header {
  font-weight: bold;
}
.card-header {
  padding: 0.25em 0em;
  background-color: #ffffff;
  color: #00bcd2;
  font-weight: bold;
  border-bottom: 2px solid #00bcd2;
}
.accordion.custom .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}
.morris-hover.morris-default-style {
  border-radius: 0;
}
.flot-chart {
  width: 100%;
  height: 320px;
}
.panel-switch-btn {
  position: relative;
  right: 20px;
  z-index: 9;
}
.panel-switch-btn label {
  font-weight: bold !important;
}
.legendLabel {
  width: 85px !important;
  position: relative;
  left: 3px;
}
#multiple_axis_chart .legendLabel {
  width: 160px !important;
}
.sparkline {
  text-align: center;
}
.dashboard-donut-chart {
  width: 280px;
  margin: 0 auto;
}
@media screen and (max-width: 767px) {
  #Categories_Statistics .ct-label.ct-horizontal.ct-end {
    display: none;
  }
}
.member-card .header {
  min-height: 150px;
}
.member-card .member-img {
  position: relative;
  margin-top: -70px;
}
.member-card .member-img img {
  width: 150px;
  border: 3px solid #fff;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}
.social-links li a {
  padding: 5px 10px;
}
.w_social2 .pw_img {
  position: relative;
  overflow: hidden;
}
.w_social2 .pw_img:before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  content: "";
  width: 50%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  transform: skewX(-25deg);
}
.w_social2 .pw_img:hover::before {
  animation: shine 0.75s;
}
@keyframes shine {
  100% {
    left: 125%;
  }
}
.w_social2 .pw_img img {
  border-radius: 3px 3px 0 0;
}
.w_social2 .pw_content .pw_header {
  padding: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.w_social2 .pw_content .pw_header small {
  font-size: 12px;
}
.w_social2 .pw_content .pw_meta {
  padding: 20px;
}
.w_social2 .pw_content .pw_meta span {
  font-weight: 500;
}
.w_user img {
  height: 72px;
  float: left;
}
.w_user .wid-u-info {
  margin-left: 90px;
}
.w_feed {
  border-radius: 0.55rem;
  color: #fff;
}
.w_feed.facebook {
  background: #3b5998;
}
.w_feed.twitter {
  background: #00aced;
}
.w_feed.google {
  background: #d34836;
}
.w_feed .carousel-inner {
  box-shadow: none;
}
.w_feed .carousel-inner .carousel-item {
  padding: 20px;
}
.w_feed .carousel-inner h4 {
  font-weight: 300;
}
.w_feed .carousel-inner h4 span {
  font-weight: 700;
}
.w_followers a:hover .btn {
  opacity: 1;
}
.w_followers .btn {
  position: absolute;
  right: 0;
  top: 4px;
  opacity: 0;
}
.w_social3 .card {
  padding: 25px;
}
.w_social3 .card .fa {
  font-size: 30px;
}
.w_social3 .text {
  margin-top: 0 !important;
}
.w_social3 .facebook-widget .icon i {
  color: #3b5998;
}
.w_social3 .google-widget .icon i {
  color: #d34836;
}
.w_social3 .twitter-widget .icon i {
  color: #0084b4;
}
.w_social3 .instagram-widget .icon i {
  color: #fb3958;
}
.w_social3 .linkedin-widget .icon i {
  color: #0077b5;
}
.w_social3 .behance-widget .icon i {
  color: #053eff;
}
.w_profile {
  position: relative;
}
.w_profile .profile-image img {
  border-radius: 50%;
  width: 180px;
  border: 3px solid #fff;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
.w_profile .social-icon a {
  margin: 0 5px;
}
@media screen and (max-width: 640px) {
  .w_profile {
    text-align: center;
  }
  .w_profile .profile-image {
    margin-bottom: 20px;
  }
}
.ms-container {
  width: auto !important;
}
.todo_list .fancy-checkbox input[type="checkbox"]:checked + span {
  text-decoration: line-through;
}
.w_bitcoin .body {
  position: absolute;
}
.w_bitcoin #sparkline16 {
  margin-bottom: -2px;
}
.feeds_widget li a {
  display: block;
  padding: 12px 15px;
  border: 1px solid #f0f0f0;
  margin: 4px 0;
}
.feeds_widget li a:hover {
  background: #f0f0f0;
}
.feeds_widget li .feeds-left {
  float: left;
  width: 33px;
  font-size: 18px;
}
.feeds_widget li .feeds-body {
  width: auto;
}
.feeds_widget li .feeds-body .title {
  font-size: 15px;
  font-weight: 600;
}
.feeds_widget li .feeds-body > small {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: calc(100% - 60px);
  font-size: 13px;
  color: #777;
}
.timeline-item {
  padding: 3em 2em 2em;
  position: relative;
  border-left: 1px solid #eee;
}
.timeline-item:before {
  content: attr(date-is);
  position: absolute;
  left: 2em;
  top: 1em;
  color: #777;
}
.timeline-item:after {
  width: 15px;
  height: 15px;
  top: 1em;
  position: absolute;
  left: -8px;
  border-radius: 10px;
  content: "";
  background: #fff;
  border: 3px solid;
}
.timeline-item.green:after {
  border-color: #86c541;
}
.timeline-item.blue:after {
  border-color: #1d8ecd;
}
.timeline-item.warning:after {
  border-color: #f5a81d;
}
.timeline-item.red:after {
  border-color: #ee2558;
}
.timeline-item.dark:after {
  border-color: #17191c;
}
.timeline-item .msg {
  margin: 15px 0;
}
.chat-app .people-list {
  transition: all 0.3s ease-in-out;
  width: 280px;
  position: absolute;
  right: 0;
  top: 0;
  padding: 20px;
  z-index: 7;
}
.chat-app .chat {
  margin-right: 280px;
  border-right: 1px solid #f0f0f0;
}
.chat-app .chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f0f0f0;
}
.chat-app .chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px;
}
.chat-app .chat .chat-header .chat-about {
  float: left;
}
.chat-app .chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff;
}
.chat-app .chat .chat-history ul li {
  list-style: none;
}
.chat-app .chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px;
}
.chat-app .chat .chat-history .message-data-time {
  color: #444;
  font-size: 12px;
}
.chat-app .chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  position: relative;
}
.chat-app .chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px;
}
.chat-app .chat .chat-history .my-message {
  background: #f7f7f7;
}
.chat-app .chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #f7f7f7;
  border-width: 10px;
  margin-left: -10px;
}
.chat-app .chat .chat-history .other-message {
  background: #eee;
}
.chat-app .chat .chat-history .other-message:after {
  border-bottom-color: #eee;
  left: 93%;
}
.chat-app .chat .chat-message {
  padding: 20px;
}
.float-right {
  float: right;
}
@media screen and (max-width: 767px) {
  .chat-app .people-list {
    height: 465px;
    width: 100%;
    overflow-x: auto;
    background: #fff;
    left: -400px;
    display: none;
  }
  .chat-app .people-list.open {
    left: 0;
  }
  .chat-app .chat {
    margin: 0;
  }
  .chat-app .chat .chat-header {
    border-radius: 0.55rem 0.55rem 0 0;
  }
  .chat-app .chat-history {
    height: 300px;
    overflow-x: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
    height: 650px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: 600px;
    overflow-x: auto;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
    height: 480px;
    overflow-x: auto;
  }
  .chat-app .chat-history {
    height: calc(100vh - 350px);
    overflow-x: auto;
  }
}
.page-calendar .event-name h6 {
  text-transform: capitalize;
}
.page-calendar .event-name h4 {
  line-height: 24px;
}
.page-calendar .event-name h4 span {
  line-height: 16px;
}
.page-calendar .event-name p {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}
.event-control {
  background-color: #f0f0f0;
  cursor: pointer;
}
.event-control:hover {
  background-color: #fff;
}
.event-control a {
  color: #444;
  cursor: pointer;
  opacity: 0.5;
}
.event-control a:hover {
  opacity: 1;
}
.event-control[class*="bg-"]:not(.bg-default):not(.bg-white) a {
  color: #444 !important;
  opacity: 1;
}
.event-control[class*="bg-"]:not(.bg-default):not(.bg-white) a:hover {
  color: #fff !important;
}
.fc-overlay {
  display: none;
  position: absolute;
  min-width: 260px;
  padding: 15px 0;
  top: auto;
  left: 50%;
  margin-left: -150px;
  z-index: 1000;
  color: #444 !important;
}
.fc-overlay.left {
  left: 100%;
  top: -15px;
  padding: 0 10px;
  margin: 0;
}
.fc-overlay.right {
  left: auto;
  right: 100%;
  top: -15px;
  padding: 0 10px;
}
.fc-overlay .panel {
  padding: 10px;
}
.fc .fc-toolbar {
  border-radius: 0;
  color: #444;
  background: transparent;
}
.fc .fc-toolbar h2 {
  text-transform: uppercase;
}
.fc .fc-toolbar .fc-today-button,
.fc .fc-toolbar .fc-state-default {
  box-shadow: none;
  background: #444;
  color: #bdbdbd;
  padding: 8px 18px;
  height: auto;
  border: none;
  text-shadow: none;
}
.fc .fc-view-container .fc-event {
  border-radius: 0.1875rem;
  color: #f7f7f7;
  border: 0;
  padding: 3px 5px;
}
.fc .fc-view-container .fc-event.fc-event-end:not(.fc-event-start) {
  border: 0 !important;
}
.fc .fc-view-container .fc-event:focus,
.fc .fc-view-container .fc-event:hover,
.fc .fc-view-container .fc-event:active {
  color: #fff;
  z-index: 1000;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  td.fc-widget-header,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > thead
  tr
  th.fc-widget-header,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > thead
  tr
  td.fc-widget-header {
  color: #666;
  border: 1px solid #eee;
  padding: 10px;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  tbody
  tr
  td.fc-widget-content,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  tbody
  tr
  td.fc-widget-content {
  border: 0;
  border-bottom: 1px solid #eee;
  border-right: 1px solid #eee;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  tbody
  tr
  td.fc-widget-content.fc-state-highlight,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  tbody
  tr
  td.fc-widget-content.fc-state-highlight {
  background: #fff;
}
.fc .fc-view-container .fc-view.fc-basic-view > table tbody tr td.fc-day-number,
.fc .fc-view-container .fc-view.fc-agenda > table tbody tr td.fc-day-number {
  color: #444;
  padding: 5px 10px;
  margin: 5px;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  tbody
  tr
  td.fc-day-number.fc-state-highlight,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  tbody
  tr
  td.fc-day-number.fc-state-highlight {
  border-radius: 50%;
  background-color: #ee2558;
  color: #fff;
  padding: 4px 6px;
  float: right;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  tbody
  tr
  td:first-child.fc-widget-content,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  tbody
  tr
  td:first-child.fc-widget-content {
  border-left: 1px solid #eee;
}
.fc .fc-view-container .fc-view.fc-basic-view .fc-body .fc-row .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row .fc-bg {
  border-bottom: 1px solid #eee;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-body
  .fc-row:last-of-type
  .fc-bg,
.fc .fc-view-container .fc-view.fc-agenda .fc-body .fc-row:last-of-type .fc-bg {
  border-bottom: 0;
}
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-allday .fc-agenda-axis,
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-allday
  .fc-agenda-gutter,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-axis,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-allday .fc-agenda-gutter {
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  background-color: #f0f0f0;
  color: #444;
  font-weight: 300;
  padding: 10px 5px;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > tbody
  > tr
  > td.fc-state-highlight,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  > table
  > tbody
  > tr
  > td.fc-state-highlight {
  background: #f0f0f0;
}
.fc .fc-view-container .fc-view.fc-basic-view > table > tbody > tr > td.fc-sat,
.fc .fc-view-container .fc-view.fc-agenda > table > tbody > tr > td.fc-sat {
  border-right: 0;
}
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr {
  line-height: 35px;
}
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-slots tr th,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-slots tr th {
  background-color: #f0f0f0;
  color: #444;
  font-weight: 300;
  border: 0;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
  padding-top: 10px;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-slots
  tr
  td.fc-widget-content,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  .fc-agenda-slots
  tr
  td.fc-widget-content {
  border-bottom: 1px solid #eee;
  line-height: 50px;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-slots
  tr
  td.fc-widget-content:last-child,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  .fc-agenda-slots
  tr
  td.fc-widget-content:last-child {
  border-right: 0;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-slots
  tr.fc-minor
  td.fc-widget-content,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  .fc-agenda-slots
  tr.fc-minor
  td.fc-widget-content {
  border-bottom: 1px solid #eee;
}
.fc .fc-view-container .fc-view.fc-basic-view .fc-agenda-divider,
.fc .fc-view-container .fc-view.fc-agenda .fc-agenda-divider {
  border: 1px solid #eee;
}
.fc
  .fc-view-container
  .fc-view.fc-basic-view
  .fc-agenda-divider
  .fc-agenda-divider-inner,
.fc
  .fc-view-container
  .fc-view.fc-agenda
  .fc-agenda-divider
  .fc-agenda-divider-inner {
  background-color: #f0f0f0;
  height: 3px;
}
.fc .fc-view-container .fc-list-view .fc-list-item {
  color: #f0f0f0;
}
.fc .fc-view-container .fc-other-month {
  background: #f7f7f7;
}
.fc .fc-view-container .fc-basic-view .fc-day-number,
.fc .fc-view-container .fc-basic-view .fc-week-number {
  float: left;
  padding: 10px 0 5px 10px;
}
.fc .fc-popover {
  border-radius: 0.55rem;
  box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  border: none;
  padding: 10px;
}
.fc .fc-popover .fc-event-container {
  padding: 0;
}
.fc .fc-popover .fc-event {
  padding: 4px 8px;
  margin: 2px 0px;
}
.fc .fc-agendaWeek-view .fc-head .fc-widget-header span,
.fc .fc-agendaDay-view .fc-head .fc-widget-header span,
.fc .fc-listWeek-view .fc-head .fc-widget-header span {
  padding: 10px;
  display: inline-block;
}
.fc .fc-agendaWeek-view .fc-unthemed td,
.fc .fc-agendaDay-view .fc-unthemed td,
.fc .fc-listWeek-view .fc-unthemed td {
  border-color: #f4f7f6;
}
@media only screen and (max-width: 420px) {
  .fc-toolbar .fc-right {
    display: none;
  }
  .fc-toolbar .fc-left {
    width: 100%;
    margin-bottom: 15px;
  }
  .fc .fc-toolbar .fc-today-button {
    float: right;
  }
}
.pricing {
  color: #777;
}
.pricing li {
  list-style: none;
  padding: 13px;
}
.pricing li:first-child,
.pricing li:last-child {
  padding: 20px 13px;
}
.pricing li:nth-last-child(2) {
  padding: 30px 13px;
}
.pricing li + li {
  border-top: 1px solid #f0f0f0;
}
.pricing big {
  font-size: 32px;
}
.pricing h3 {
  font-size: 36px;
}
.pricing span {
  color: #bdbdbd;
  font-weight: normal;
}
.pricing.active big,
.pricing.active h3 {
  color: #1d8ecd;
}
.pricing2 .pricing-plan {
  border-bottom: none;
  flex-basis: 100%;
  padding: 25px 0;
}
.pricing2 .pricing-plan .pricing-img {
  max-width: 100%;
}
.pricing2 .pricing-plan .pricing-header {
  color: #777;
  font-weight: 600;
  letter-spacing: 1px;
}
.pricing2 .pricing-plan .pricing-features {
  letter-spacing: 1px;
  margin: 50px 0 25px;
  line-height: 30px;
  list-style: none;
}
.pricing2 .pricing-plan .pricing-price {
  display: block;
  font-size: 32px;
  font-weight: 700;
}
.pricing3 .pricing-option {
  padding: 20px;
}
.pricing3 .pricing-option i {
  font-size: 60px;
}
.pricing3 .pricing-option .price {
  font-size: 2rem;
  text-transform: uppercase;
  position: relative;
}
.pricing3 .pricing-option .price b {
  position: absolute;
  font-size: 1rem;
  font-weight: 600;
}
.invoice1 .logo,
.invoice1 .clientlogo {
  float: left;
  height: 70px;
  width: 70px;
}
.invoice1 .info {
  float: left;
}
.invoice1 .invoice-top .title {
  float: right;
}
.invoice1 .invoice-mid .info {
  width: calc(100% - 90px);
}
.invoice .table tr td {
  vertical-align: middle;
}
.btn-primary {
  background-color: #00bcd2;
}
.header {
  border-bottom: 1px solid #ccc;
  min-height: 42px;
}
.formbuttonalign {
  height: 64px;
  display: flex;
  align-items: flex-end;
}
.floatright {
  float: right;
}
.cardheading {
  float: left;
}
.margintop-5 {
  margin-top: -5px;
}
.formbuttonalign {
  height: 64px;
  display: flex;
  align-items: flex-end;
}
.formloginres {
  width: 70%;
}
.LoginPageRightDiv {
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

@media (min-width: 575.98px) and (max-width: 991.98px) {
  .loginleftpicture {
    display: none;
  }
  .formloginres {
    width: 85%;
    margin-top: 156px;
  }
}
@media (max-width: 575.98px) {
  .loginleftpicture {
    display: none;
  }
  .formloginres {
    width: 95%;
    margin-top: 156px;
  }
}
.modal-header {
  background-color: var(--primaryColor);
  color: white;
  padding: 10px 3px 0px 3px;
}
.modal-footer {
  padding: 1.5em;
}
.modal-body > .body {
  padding: 0.25em 1em;
}
.modalCloseButtonHeader {
  color:white;
  position: absolute;
  border: 0.5px sold grey;
  right: 11px;
  border-radius: 45%;
  border: 0;
  font-size: 18px;
  top: 4px;
}
.modalCloseButtonHeader:active,
.modalCloseButtonHeader:hover {
  cursor: pointer;
  color: white;
  border: 0;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  outline: 0;
  overflow: auto;
}
.modal-footer {
  padding: 0.5em;
}
.modal-body {
  padding: 1rem 1rem 0 1rem;
}
.modal-body label {
  padding-top: 9px;
}
.no-bo {
  background-color: #000;
  height: 699px;
}
.pagination {
  margin-right: 10px;
  margin-top: 5px;
  justify-content: flex-end;
}

.headinglineright {
  position: absolute;
  right: 0;
  bottom: 0;
}
.bluelink {
  color: #046eb4;
  cursor: pointer;
}
.bluelink:hover {
  color: #046eb4;
  text-decoration: underline;
}
.textLink {
  color: #5a5a5a;
  cursor: pointer;
}
.textLink:hover {
  color: #046eb4;
  text-decoration: underline;
}
.edit_hover_class a{
  visibility:hidden;
}
.edit_hover_class:hover a {
 visibility:visible;
}
label.required::after {
  content: "*";
  position: absolute;
  top: 12px;
  color: #dc3545;
  padding-left: 2px !important;
}
/* new Table Style */
.newTableStyle .table thead th {
  border-bottom: 2px solid #00bcd2;
  border-top: none;
}
.newTableStyle .table tbody tr td {
  border-top: none;
  padding: 0 0 0.65rem 0;
}
.newTableStyle .table tbody tr:first-child td {
  padding-top: 0.55rem;
}
.newTableStyle .blue-txt {
  color: #00bcd2 !important;
  font-weight: 600;
}
.tdMaxWidth {
  max-width: 24px;
}
.greentxt {
  color: green;
  font-weight: 600;
}
.tabFullWidth {
  width: 100%;
}
.tabFullWidth {
  border-bottom: 1px solid #ddd !important;
}
.newTableStyle button,
.newTableStyle button:focus {
  box-shadow: none;
  background-color: transparent;
  border: none;
  border-color: transparent;
  border-bottom: transparent;
  outline: none;
}
.table-btn {
  font-size: 17px;
}
@media (max-width: 1200px) {
  #left-sidebar .btn-toggle-offcanvas {
    display: block;
  }
  .layout-fullwidth #wrapper #main-content {
    width: 100%;
  }
  .layout-fullwidth #wrapper .navbar-fixed-top {
    width: 100%;
  }
}
.passwordEyeonIdleScreen {
  float: right;
  left: 94%;
  top: 42%;
  position: absolute;
  font-size: 13px;
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .passwordEyeonIdleScreen {
    float: right;
    left: 86%;
    top: 75%;
    position: absolute;
    font-size: 13px;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .passwordEyeonIdleScreen {
    float: right;
    left: 94%;
    top: 75%;
    position: absolute;
    font-size: 13px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .passwordEyeonIdleScreen {
    float: right;
    left: 88%;
    top: 74%;
    position: absolute;
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .passwordEyeonIdleScreen {
    float: right;
    left: 75%;
    top: 75%;
    position: absolute;
    font-size: 13px;
  }
}

/* width */
*::-webkit-scrollbar {
  width: 4px;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
*::-webkit-scrollbar-thumb {
  color: #fff;
  background: var(--primaryColor);
  border-radius: 10px;
}

/* Handle on hover */
*::-webkit-scrollbar-thumb:hover {
  background: #666666;
  width: 15px;
}
.new-rule-created:hover {
  background-color: #e3f2ff;
  color: var(--primary-color);
  border-bottom: 1px solid #ccc;
  padding: 6px;
}
.rolesAndRights {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 38px);
  margin-bottom: 10px;
}
.height100vh {
  height: 100vh;
}
.billingheightcalcCCDAtaskLogReports {
  max-height: calc(100vh - 421px) !important;
  height: auto !important;
  min-height: 330px !important;
}
.billingheightTeamUserSetup {
  max-height: calc(100vh - 381px) !important;
  height: auto !important;
  min-height: 128px !important;
}

/* @supports (display: grid) {
  .heightPer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0;
  }
  .billingheightTeamUserSetup {
    width: auto;
    min-height: auto;
    margin: 0;
  }
} */

/* @supports (display: grid) {
  .heightPer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    margin: 0;
  }
  .billingheightTeamUserSetup {
    width: auto;
    margin: 0;
  }
} */



.heightPer {
  height: 100%;
}

/* css my danish--- */


.login__container {
  /* width: 100%; */
  max-width: 100vw;
  overflow: hidden;
  height: 100vh;
  background: url(/static/media/homebg.e5bb1615.jpg);
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: center;
  background-size: cover;
  
}
.login__logo {
  top: 30px;
  left: 30px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.183));
  width: 200px;
}
.pharmacy__logo{
    bottom: -130px;
    right: -130px;
    
    opacity: .1;
}
.login__heading > h1 {
  font-size: 3rem;
  color: white;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.316));
}
.login__form{
    min-width: 400px;
background-color: rgba(255, 255, 255, 0.45);
box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.093);
padding: 10px;
border-radius: 10px;

/* min-height: 500px; */
}
.login__text{
    position: absolute;
    bottom: 10px;
    opacity: .2;
    left: 30px;
    pointer-events: none;
    color: white;
    font-size: 3rem;
    font-weight: bold;
}


/* bootstrap btn modificaiton */
.btn-primary{
    border: none;
    background-color:var(--primaryColor) ;
}
.btn-primary:hover{
    background-color: var(--primaryColorHovered);
}


.panel.card.shadow{
 border-radius: 10px; padding: 10px 5px;
 box-shadow:  32px 32px 65px #8b8b8b,
               -32px -32px 65px #ffffff;

}



#left-sidebar{
  /* background:red !important; */
}
#main-content{
  /* background: linear-gradient(to bottom right,white,var(--primaryColor)); */
/* background-color: var(--primaryLightColorThree); */
}
.theme-purple ::selection {
  color: #fff;
  background: #6462ef;
}
.theme-purple .page-loader-wrapper {
  background: #6462ef;
}
.theme-purple .navbar-fixed-top {
  background: #6462ef;
}
.theme-purple .navbar-fixed-top .form-control {
  border: 0;
}
.theme-purple .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-purple .sidebar-nav .metismenu > li.active > a {
  background: #6462ef;
  color: #fff;
}
.theme-purple .sidebar-nav .metismenu a {
  color: #292c3f;
}
.theme-purple .sidebar-nav .metismenu a:hover,
.theme-purple .sidebar-nav .metismenu a:focus {
  background: #292c3f;
  color: #e8e8e8;
}
.theme-purple #left-sidebar {
  background: #fff;
}
.theme-purple #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-purple #left-sidebar .navbar-brand span {
  color: #6462ef;
}
.theme-purple #left-sidebar .user-account {
  color: #292c3f;
}
.theme-purple #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-purple #left-sidebar .user-account .user_div .user-photo {
  border-color: #6462ef;
}
.theme-purple #left-sidebar .user-account .dropdown-menu {
  background: #6462ef;
}
.theme-purple #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-purple .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #6462ef;
  border-color: #6462ef;
}
.theme-purple .chat-widget li.right .chat-info {
  background: #6462ef;
  color: #fff;
}
.theme-purple .chat-widget li.right .chat-info:before {
  border-left: 10px solid #6462ef;
}
.theme-purple .right_chat li a:hover .media-object {
  border-color: #6462ef;
}
.theme-purple .auth-main:after {
  background: #6462ef;
}
.theme-purple .auth-main:before {
  background: #17191c;
}
.theme-purple .wizard > .steps .current a {
  background: #6462ef;
}
.theme-purple .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-purple .wizard .actions a {
  background: #00bcd2;
}
.theme-purple
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #6462ef;
  border-color: #6462ef;
  color: #fff;
}
.theme-purple .maintenance {
  background: #6462ef;
}
.theme-purple .maintenance > div {
  background: #fff;
}
.theme-purple .noUi-connect {
  background: #6462ef;
}
.theme-purple .blog-page .single_post .footer .stats li a:hover {
  color: #6462ef;
}
.theme-purple .ct-series-a .ct-line,
.theme-purple .ct-series-a .ct-bar,
.theme-purple .ct-series-a .ct-point {
  stroke: #292c3f;
}
.theme-purple .ct-series-a .ct-area,
.theme-purple .ct-series-a .ct-slice-donut-solid,
.theme-purple .ct-series-a .ct-slice-pie,
.theme-purple .ct-series-a .ct-bar {
  fill: #292c3f;
}
.theme-purple .ct-series-b .ct-line,
.theme-purple .ct-series-b .ct-bar,
.theme-purple .ct-series-b .ct-point {
  stroke: #6462ef;
}
.theme-purple .ct-series-b .ct-area,
.theme-purple .ct-series-b .ct-slice-donut-solid,
.theme-purple .ct-series-b .ct-slice-pie,
.theme-purple .ct-series-b .ct-bar {
  fill: #6462ef;
}
.theme-purple .ct-series-c .ct-line,
.theme-purple .ct-series-c .ct-bar,
.theme-purple .ct-series-c .ct-point {
  stroke: #8f8de7;
}
.theme-purple .ct-series-c .ct-area,
.theme-purple .ct-series-c .ct-slice-donut-solid,
.theme-purple .ct-series-c .ct-slice-pie,
.theme-purple .ct-series-c .ct-bar {
  fill: #8f8de7;
}
.theme-purple .ct-series-d .ct-line,
.theme-purple .ct-series-d .ct-bar,
.theme-purple .ct-series-d .ct-point {
  stroke: #b4b3e4;
}
.theme-purple .ct-series-d .ct-area,
.theme-purple .ct-series-d .ct-slice-donut-solid,
.theme-purple .ct-series-d .ct-slice-pie,
.theme-purple .ct-series-d .ct-bar {
  fill: #b4b3e4;
}
.theme-purple .ct-series-e .ct-line,
.theme-purple .ct-series-e .ct-bar,
.theme-purple .ct-series-e .ct-point {
  stroke: #00bcd2;
}
.theme-purple .ct-series-e .ct-area,
.theme-purple .ct-series-e .ct-slice-donut-solid,
.theme-purple .ct-series-e .ct-slice-pie,
.theme-purple .ct-series-e .ct-bar {
  fill: #00bcd2;
}
.theme-purple .ct-series-f .ct-line,
.theme-purple .ct-series-f .ct-bar,
.theme-purple .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-purple .ct-series-f .ct-area,
.theme-purple .ct-series-f .ct-slice-donut-solid,
.theme-purple .ct-series-f .ct-slice-pie,
.theme-purple .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-purple .ct-legend .ct-series-0:before {
  background-color: #292c3f;
  border-color: #292c3f;
}
.theme-purple .ct-legend .ct-series-1:before {
  background-color: #6462ef;
  border-color: #6462ef;
}
.theme-purple .ct-legend .ct-series-2:before {
  background-color: #8f8de7;
  border-color: #8f8de7;
}
.theme-purple .ct-legend .ct-series-3:before {
  background-color: #b4b3e4;
  border-color: #b4b3e4;
}
.theme-purple .ct-legend .ct-series-4:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-purple .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-blue ::selection {
  color: #fff;
  background: #0e9be2;
}
.theme-blue .page-loader-wrapper {
  background: #0e9be2;
}
.theme-blue .navbar-fixed-top {
  background: #0e9be2;
}
.theme-blue .navbar-fixed-top .form-control {
  border: 0;
}
.theme-blue .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li.active > a {
  background: #ffaf1d;
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-blue .sidebar-nav .metismenu > li i {
  color: #9da1a7;
}
.theme-blue .sidebar-nav .metismenu > li ul li.active a {
  color: #0e9be2;
}
.theme-blue .sidebar-nav .metismenu a {
  color: #9da1a7;
}
.theme-blue .sidebar-nav .metismenu a:hover,
.theme-blue .sidebar-nav .metismenu a:focus {
  background: #2d3435;
}
.theme-blue .sidebar-nav .metismenu .has-arrow:after {
  color: #ffaf1d;
}
.theme-blue #left-sidebar {
  background: #303844;
}
.theme-blue #left-sidebar .user-account .user_div .user-photo {
  border-color: #ffaf1d;
}
.theme-blue .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #0e9be2;
  border-color: #0e9be2;
}
.theme-blue .chat-widget li.right .chat-info {
  background: #0e9be2;
  color: #fff;
}
.theme-blue .chat-widget li.right .chat-info:before {
  border-left: 10px solid #0e9be2;
}
.theme-blue .right_chat li a:hover .media-object {
  border-color: #0e9be2;
}
.theme-blue
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #0e9be2;
  border-color: #0e9be2;
  color: #fff;
}
.theme-blue .auth-main:after {
  background: #0e9be2;
}
.theme-blue .auth-main:before {
  background: #303844;
}
.theme-blue .wizard > .steps .current a {
  background: #0e9be2;
}
.theme-blue .wizard > .steps .done a {
  background: #9da1a7;
}
.theme-blue .wizard .actions a {
  background: #ffaf1d;
}
.theme-blue .maintenance {
  background: #0e9be2;
}
.theme-blue .maintenance > div {
  background: #fff;
}
.theme-blue .noUi-connect {
  background: #0e9be2;
}
.theme-blue .blog-page .single_post .footer .stats li a:hover {
  color: #0e9be2;
}
.theme-blue .ct-series-a .ct-line,
.theme-blue .ct-series-a .ct-bar,
.theme-blue .ct-series-a .ct-point {
  stroke: #303844;
}
.theme-blue .ct-series-a .ct-area,
.theme-blue .ct-series-a .ct-slice-donut-solid,
.theme-blue .ct-series-a .ct-slice-pie,
.theme-blue .ct-series-a .ct-bar {
  fill: #303844;
}
.theme-blue .ct-series-b .ct-line,
.theme-blue .ct-series-b .ct-bar,
.theme-blue .ct-series-b .ct-point {
  stroke: #0e9be2;
}
.theme-blue .ct-series-b .ct-area,
.theme-blue .ct-series-b .ct-slice-donut-solid,
.theme-blue .ct-series-b .ct-slice-pie,
.theme-blue .ct-series-b .ct-bar {
  fill: #0e9be2;
}
.theme-blue .ct-series-c .ct-line,
.theme-blue .ct-series-c .ct-bar,
.theme-blue .ct-series-c .ct-point {
  stroke: #ffaf1d;
}
.theme-blue .ct-series-c .ct-area,
.theme-blue .ct-series-c .ct-slice-donut-solid,
.theme-blue .ct-series-c .ct-slice-pie,
.theme-blue .ct-series-c .ct-bar {
  fill: #ffaf1d;
}
.theme-blue .ct-series-d .ct-line,
.theme-blue .ct-series-d .ct-bar,
.theme-blue .ct-series-d .ct-point {
  stroke: #02b5b2;
}
.theme-blue .ct-series-d .ct-area,
.theme-blue .ct-series-d .ct-slice-donut-solid,
.theme-blue .ct-series-d .ct-slice-pie,
.theme-blue .ct-series-d .ct-bar {
  fill: #02b5b2;
}
.theme-blue .ct-series-e .ct-line,
.theme-blue .ct-series-e .ct-bar,
.theme-blue .ct-series-e .ct-point {
  stroke: #767ee9;
}
.theme-blue .ct-series-e .ct-area,
.theme-blue .ct-series-e .ct-slice-donut-solid,
.theme-blue .ct-series-e .ct-slice-pie,
.theme-blue .ct-series-e .ct-bar {
  fill: #767ee9;
}
.theme-blue .ct-series-f .ct-line,
.theme-blue .ct-series-f .ct-bar,
.theme-blue .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-blue .ct-series-f .ct-area,
.theme-blue .ct-series-f .ct-slice-donut-solid,
.theme-blue .ct-series-f .ct-slice-pie,
.theme-blue .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-blue .ct-legend .ct-series-0:before {
  background-color: #303844;
  border-color: #303844;
}
.theme-blue .ct-legend .ct-series-1:before {
  background-color: #0e9be2;
  border-color: #0e9be2;
}
.theme-blue .ct-legend .ct-series-2:before {
  background-color: #ffaf1d;
  border-color: #ffaf1d;
}
.theme-blue .ct-legend .ct-series-3:before {
  background-color: #02b5b2;
  border-color: #02b5b2;
}
.theme-blue .ct-legend .ct-series-4:before {
  background-color: #767ee9;
  border-color: #767ee9;
}
.theme-blue .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-cyan ::selection {
  color: #fff;
  background: #00bcd2;
}
.theme-cyan .page-loader-wrapper {
  background: #00bcd2;
}
.theme-cyan .navbar-fixed-top {
  background: #00bcd2;
}
.theme-cyan .navbar-fixed-top .form-control {
  border: 0;
}
.theme-cyan .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a {
  background: #00bcd2;
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-cyan .sidebar-nav .metismenu > li i {
  color: #6f7b7d;
}
.theme-cyan .sidebar-nav .metismenu > li ul li.active a {
  color: #00bcd2;
}
.theme-cyan .sidebar-nav .metismenu a {
  color: #6f7b7d;
}
.theme-cyan .sidebar-nav .metismenu a:hover,
.theme-cyan .sidebar-nav .metismenu a:focus {
  background: #2d3435;
}
.theme-cyan #left-sidebar {
  background: #fff;
}
.theme-cyan #left-sidebar .user-account .user_div .user-photo {
  border-color: #00bcd2;
}
.theme-cyan .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #00bcd2;
  border-color: #00bcd2;
}
.theme-cyan .chat-widget li.right .chat-info {
  background: #00bcd2;
  color: #fff;
}
.theme-cyan .chat-widget li.right .chat-info:before {
  border-left: 10px solid #00bcd2;
}
.theme-cyan .right_chat li a:hover .media-object {
  border-color: #00bcd2;
}
.theme-cyan
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #00bcd2;
  border-color: #00bcd2;
  color: #fff;
}
.theme-cyan .auth-main:after {
  background: #00bcd2;
}
.theme-cyan .auth-main:before {
  background: #242a2b;
}
.theme-cyan .wizard > .steps .current a {
  background: #00bcd2;
}
.theme-cyan .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-cyan .wizard .actions a {
  background: #00bcd2;
}
.theme-cyan .maintenance {
  background: #00bcd2;
}
.theme-cyan .maintenance > div {
  background: #fff;
}
.theme-cyan .noUi-connect {
  background: #00bcd2;
}
.theme-cyan .blog-page .single_post .footer .stats li a:hover {
  color: #00bcd2;
}
.theme-cyan .ct-series-a .ct-line,
.theme-cyan .ct-series-a .ct-bar,
.theme-cyan .ct-series-a .ct-point {
  stroke: #394344;
}
.theme-cyan .ct-series-a .ct-area,
.theme-cyan .ct-series-a .ct-slice-donut-solid,
.theme-cyan .ct-series-a .ct-slice-pie,
.theme-cyan .ct-series-a .ct-bar {
  fill: #394344;
}
.theme-cyan .ct-series-b .ct-line,
.theme-cyan .ct-series-b .ct-bar,
.theme-cyan .ct-series-b .ct-point {
  stroke: #00bcd2;
}
.theme-cyan .ct-series-b .ct-area,
.theme-cyan .ct-series-b .ct-slice-donut-solid,
.theme-cyan .ct-series-b .ct-slice-pie,
.theme-cyan .ct-series-b .ct-bar {
  fill: #00bcd2;
}
.theme-cyan .ct-series-c .ct-line,
.theme-cyan .ct-series-c .ct-bar,
.theme-cyan .ct-series-c .ct-point {
  stroke: #5ed3e1;
}
.theme-cyan .ct-series-c .ct-area,
.theme-cyan .ct-series-c .ct-slice-donut-solid,
.theme-cyan .ct-series-c .ct-slice-pie,
.theme-cyan .ct-series-c .ct-bar {
  fill: #5ed3e1;
}
.theme-cyan .ct-series-d .ct-line,
.theme-cyan .ct-series-d .ct-bar,
.theme-cyan .ct-series-d .ct-point {
  stroke: #97cad0;
}
.theme-cyan .ct-series-d .ct-area,
.theme-cyan .ct-series-d .ct-slice-donut-solid,
.theme-cyan .ct-series-d .ct-slice-pie,
.theme-cyan .ct-series-d .ct-bar {
  fill: #97cad0;
}
.theme-cyan .ct-series-e .ct-line,
.theme-cyan .ct-series-e .ct-bar,
.theme-cyan .ct-series-e .ct-point {
  stroke: #394344;
}
.theme-cyan .ct-series-e .ct-area,
.theme-cyan .ct-series-e .ct-slice-donut-solid,
.theme-cyan .ct-series-e .ct-slice-pie,
.theme-cyan .ct-series-e .ct-bar {
  fill: #394344;
}
.theme-cyan .ct-series-f .ct-line,
.theme-cyan .ct-series-f .ct-bar,
.theme-cyan .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-cyan .ct-series-f .ct-area,
.theme-cyan .ct-series-f .ct-slice-donut-solid,
.theme-cyan .ct-series-f .ct-slice-pie,
.theme-cyan .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-cyan .ct-legend .ct-series-0:before {
  background-color: #394344;
  border-color: #394344;
}
.theme-cyan .ct-legend .ct-series-1:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-cyan .ct-legend .ct-series-2:before {
  background-color: #5ed3e1;
  border-color: #5ed3e1;
}
.theme-cyan .ct-legend .ct-series-3:before {
  background-color: #97cad0;
  border-color: #97cad0;
}
.theme-cyan .ct-legend .ct-series-4:before {
  background-color: #394344;
  border-color: #394344;
}
.theme-cyan .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-green ::selection {
  color: #fff;
  background: #29bd73;
}
.theme-green .page-loader-wrapper {
  background: #29bd73;
}
.theme-green .navbar-fixed-top {
  background: #29bd73;
}
.theme-green .navbar-fixed-top .form-control {
  border: 0;
}
.theme-green .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li.active > a {
  background: #29bd73;
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu > li i {
  color: #182973;
}
.theme-green .sidebar-nav .metismenu a {
  color: #182973;
}
.theme-green .sidebar-nav .metismenu a:hover,
.theme-green .sidebar-nav .metismenu a:focus {
  background: #182973;
  color: #fff;
}
.theme-green .sidebar-nav .metismenu a:hover i,
.theme-green .sidebar-nav .metismenu a:focus i {
  color: #fff;
}
.theme-green .sidebar-nav .metismenu .has-arrow:after {
  color: #e8e8e8;
}
.theme-green #left-sidebar {
  background: #fff;
}
.theme-green #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-green #left-sidebar .navbar-brand span {
  color: #29bd73;
}
.theme-green #left-sidebar .user-account {
  color: #182973;
}
.theme-green #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-green #left-sidebar .user-account .user_div .user-photo {
  border-color: #29bd73;
}
.theme-green #left-sidebar .user-account .dropdown-menu {
  background: #182973;
}
.theme-green #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-green #left-sidebar .user-account .dropdown-menu a:hover {
  color: #29bd73;
}
.theme-green #left-sidebar .user-account .dropdown-menu a:hover i {
  color: #29bd73;
}
.theme-green .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #29bd73;
  border-color: #29bd73;
}
.theme-green .chat-widget li.right .chat-info {
  background: #29bd73;
  color: #fff;
}
.theme-green .chat-widget li.right .chat-info:before {
  border-left: 10px solid #29bd73;
}
.theme-green .right_chat li a:hover .media-object {
  border-color: #29bd73;
}
.theme-green
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #29bd73;
  border-color: #29bd73;
  color: #fff;
}
.theme-green .auth-main:after {
  background: #29bd73;
}
.theme-green .auth-main:before {
  background: #182973;
}
.theme-green .wizard > .steps .current a {
  background: #29bd73;
}
.theme-green .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-green .wizard .actions a {
  background: #182973;
}
.theme-green .maintenance {
  background: #29bd73;
}
.theme-green .maintenance > div {
  background: #fff;
}
.theme-green .noUi-connect {
  background: #29bd73;
}
.theme-green .blog-page .single_post .footer .stats li a:hover {
  color: #29bd73;
}
.theme-green .ct-series-a .ct-line,
.theme-green .ct-series-a .ct-bar,
.theme-green .ct-series-a .ct-point {
  stroke: #182973;
}
.theme-green .ct-series-a .ct-area,
.theme-green .ct-series-a .ct-slice-donut-solid,
.theme-green .ct-series-a .ct-slice-pie,
.theme-green .ct-series-a .ct-bar {
  fill: #182973;
}
.theme-green .ct-series-b .ct-line,
.theme-green .ct-series-b .ct-bar,
.theme-green .ct-series-b .ct-point {
  stroke: #29bd73;
}
.theme-green .ct-series-b .ct-area,
.theme-green .ct-series-b .ct-slice-donut-solid,
.theme-green .ct-series-b .ct-slice-pie,
.theme-green .ct-series-b .ct-bar {
  fill: #29bd73;
}
.theme-green .ct-series-c .ct-line,
.theme-green .ct-series-c .ct-bar,
.theme-green .ct-series-c .ct-point {
  stroke: #6bc196;
}
.theme-green .ct-series-c .ct-area,
.theme-green .ct-series-c .ct-slice-donut-solid,
.theme-green .ct-series-c .ct-slice-pie,
.theme-green .ct-series-c .ct-bar {
  fill: #6bc196;
}
.theme-green .ct-series-d .ct-line,
.theme-green .ct-series-d .ct-bar,
.theme-green .ct-series-d .ct-point {
  stroke: #a4d4bc;
}
.theme-green .ct-series-d .ct-area,
.theme-green .ct-series-d .ct-slice-donut-solid,
.theme-green .ct-series-d .ct-slice-pie,
.theme-green .ct-series-d .ct-bar {
  fill: #a4d4bc;
}
.theme-green .ct-series-e .ct-line,
.theme-green .ct-series-e .ct-bar,
.theme-green .ct-series-e .ct-point {
  stroke: #00bcd2;
}
.theme-green .ct-series-e .ct-area,
.theme-green .ct-series-e .ct-slice-donut-solid,
.theme-green .ct-series-e .ct-slice-pie,
.theme-green .ct-series-e .ct-bar {
  fill: #00bcd2;
}
.theme-green .ct-series-f .ct-line,
.theme-green .ct-series-f .ct-bar,
.theme-green .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-green .ct-series-f .ct-area,
.theme-green .ct-series-f .ct-slice-donut-solid,
.theme-green .ct-series-f .ct-slice-pie,
.theme-green .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-green .ct-legend .ct-series-0:before {
  background-color: #182973;
  border-color: #182973;
}
.theme-green .ct-legend .ct-series-1:before {
  background-color: #29bd73;
  border-color: #29bd73;
}
.theme-green .ct-legend .ct-series-2:before {
  background-color: #6bc196;
  border-color: #6bc196;
}
.theme-green .ct-legend .ct-series-3:before {
  background-color: #a4d4bc;
  border-color: #a4d4bc;
}
.theme-green .ct-legend .ct-series-4:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-green .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}
.theme-orange ::selection {
  color: #fff;
  background: #00bcd2;
}
.theme-orange .page-loader-wrapper {
  background: #00bcd2;
}
.theme-orange .navbar-fixed-top {
  background: #F45D48;
}
.theme-orange .navbar-fixed-top .form-control {
  border: 0;
}
.theme-orange .navbar-fixed-top .icon-menu i {
  color: #fff;
}

.theme-orange .sidebar-nav .metismenu > li.active > a {
  background: #343a40;

  color:.theme-purple ::selection {
    color: #fff;
    background: #6462ef;
   
  }


  /* .theme-orange .sidebar-nav .metismenu > li.active > a {
    background: #343a40;
    color: #fff;
  }
  
  .theme-purple ::selection {
    color: #fff;
    background: #6462ef;
  } */
  
  
  .theme-purple .page-loader-wrapper {
    background: #6462ef;
  }
  .theme-purple .navbar-fixed-top {
    background: #6462ef;
  }
  .theme-purple .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-purple .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-purple .sidebar-nav .metismenu > li.active > a {
    background: #6462ef;
    color: #fff;
  }
  .theme-purple .sidebar-nav .metismenu a {
    color: #292c3f;
  }
  .theme-purple .sidebar-nav .metismenu a:hover,
  .theme-purple .sidebar-nav .metismenu a:focus {
    background: #292c3f;
    color: #e8e8e8;
  }
  .theme-purple #left-sidebar {
    background: #fff;
  }
  .theme-purple #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-purple #left-sidebar .navbar-brand span {
    color: #6462ef;
  }
  .theme-purple #left-sidebar .user-account {
    color: #292c3f;
  }
  .theme-purple #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-purple #left-sidebar .user-account .user_div .user-photo {
    border-color: #6462ef;
  }
  .theme-purple #left-sidebar .user-account .dropdown-menu {
    background: #6462ef;
  }
  .theme-purple #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-purple .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #6462ef;
    border-color: #6462ef;
  }
  .theme-purple .chat-widget li.right .chat-info {
    background: #6462ef;
    color: #fff;
  }
  .theme-purple .chat-widget li.right .chat-info:before {
    border-left: 10px solid #6462ef;
  }
  .theme-purple .right_chat li a:hover .media-object {
    border-color: #6462ef;
  }
  .theme-purple .auth-main:after {
    background: #6462ef;
  }
  .theme-purple .auth-main:before {
    background: #17191c;
  }
  .theme-purple .wizard > .steps .current a {
    background: #6462ef;
  }
  .theme-purple .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-purple .wizard .actions a {
    background: #00bcd2;
  }
  .theme-purple
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #6462ef;
    border-color: #6462ef;
    color: #fff;
  }
  .theme-purple .maintenance {
    background: #6462ef;
  }
  .theme-purple .maintenance > div {
    background: #fff;
  }
  .theme-purple .noUi-connect {
    background: #6462ef;
  }
  .theme-purple .blog-page .single_post .footer .stats li a:hover {
    color: #6462ef;
  }
  .theme-purple .ct-series-a .ct-line,
  .theme-purple .ct-series-a .ct-bar,
  .theme-purple .ct-series-a .ct-point {
    stroke: #292c3f;
  }
  .theme-purple .ct-series-a .ct-area,
  .theme-purple .ct-series-a .ct-slice-donut-solid,
  .theme-purple .ct-series-a .ct-slice-pie,
  .theme-purple .ct-series-a .ct-bar {
    fill: #292c3f;
  }
  .theme-purple .ct-series-b .ct-line,
  .theme-purple .ct-series-b .ct-bar,
  .theme-purple .ct-series-b .ct-point {
    stroke: #6462ef;
  }
  .theme-purple .ct-series-b .ct-area,
  .theme-purple .ct-series-b .ct-slice-donut-solid,
  .theme-purple .ct-series-b .ct-slice-pie,
  .theme-purple .ct-series-b .ct-bar {
    fill: #6462ef;
  }
  .theme-purple .ct-series-c .ct-line,
  .theme-purple .ct-series-c .ct-bar,
  .theme-purple .ct-series-c .ct-point {
    stroke: #8f8de7;
  }
  .theme-purple .ct-series-c .ct-area,
  .theme-purple .ct-series-c .ct-slice-donut-solid,
  .theme-purple .ct-series-c .ct-slice-pie,
  .theme-purple .ct-series-c .ct-bar {
    fill: #8f8de7;
  }
  .theme-purple .ct-series-d .ct-line,
  .theme-purple .ct-series-d .ct-bar,
  .theme-purple .ct-series-d .ct-point {
    stroke: #b4b3e4;
  }
  .theme-purple .ct-series-d .ct-area,
  .theme-purple .ct-series-d .ct-slice-donut-solid,
  .theme-purple .ct-series-d .ct-slice-pie,
  .theme-purple .ct-series-d .ct-bar {
    fill: #b4b3e4;
  }
  .theme-purple .ct-series-e .ct-line,
  .theme-purple .ct-series-e .ct-bar,
  .theme-purple .ct-series-e .ct-point {
    stroke: #00bcd2;
  }
  .theme-purple .ct-series-e .ct-area,
  .theme-purple .ct-series-e .ct-slice-donut-solid,
  .theme-purple .ct-series-e .ct-slice-pie,
  .theme-purple .ct-series-e .ct-bar {
    fill: #00bcd2;
  }
  .theme-purple .ct-series-f .ct-line,
  .theme-purple .ct-series-f .ct-bar,
  .theme-purple .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-purple .ct-series-f .ct-area,
  .theme-purple .ct-series-f .ct-slice-donut-solid,
  .theme-purple .ct-series-f .ct-slice-pie,
  .theme-purple .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-purple .ct-legend .ct-series-0:before {
    background-color: #292c3f;
    border-color: #292c3f;
  }
  .theme-purple .ct-legend .ct-series-1:before {
    background-color: #6462ef;
    border-color: #6462ef;
  }
  .theme-purple .ct-legend .ct-series-2:before {
    background-color: #8f8de7;
    border-color: #8f8de7;
  }
  .theme-purple .ct-legend .ct-series-3:before {
    background-color: #b4b3e4;
    border-color: #b4b3e4;
  }
  .theme-purple .ct-legend .ct-series-4:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-purple .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-blue ::selection {
    color: #fff;
    background: #0e9be2;
  }
  .theme-blue .page-loader-wrapper {
    background: #0e9be2;
  }
  .theme-blue .navbar-fixed-top {
    background: #0e9be2;
  }
  .theme-blue .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-blue .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li.active > a {
    background: #ffaf1d;
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-blue .sidebar-nav .metismenu > li i {
    color: #9da1a7;
  }
  .theme-blue .sidebar-nav .metismenu > li ul li.active a {
    color: #0e9be2;
  }
  .theme-blue .sidebar-nav .metismenu a {
    color: #9da1a7;
  }
  .theme-blue .sidebar-nav .metismenu a:hover,
  .theme-blue .sidebar-nav .metismenu a:focus {
    background: #2d3435;
  }
  .theme-blue .sidebar-nav .metismenu .has-arrow:after {
    color: #ffaf1d;
  }
  .theme-blue #left-sidebar {
    background: #303844;
  }
  .theme-blue #left-sidebar .user-account .user_div .user-photo {
    border-color: #ffaf1d;
  }
  .theme-blue .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #0e9be2;
    border-color: #0e9be2;
  }
  .theme-blue .chat-widget li.right .chat-info {
    background: #0e9be2;
    color: #fff;
  }
  .theme-blue .chat-widget li.right .chat-info:before {
    border-left: 10px solid #0e9be2;
  }
  .theme-blue .right_chat li a:hover .media-object {
    border-color: #0e9be2;
  }
  .theme-blue
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #0e9be2;
    border-color: #0e9be2;
    color: #fff;
  }
  .theme-blue .auth-main:after {
    background: #0e9be2;
  }
  .theme-blue .auth-main:before {
    background: #303844;
  }
  .theme-blue .wizard > .steps .current a {
    background: #0e9be2;
  }
  .theme-blue .wizard > .steps .done a {
    background: #9da1a7;
  }
  .theme-blue .wizard .actions a {
    background: #ffaf1d;
  }
  .theme-blue .maintenance {
    background: #0e9be2;
  }
  .theme-blue .maintenance > div {
    background: #fff;
  }
  .theme-blue .noUi-connect {
    background: #0e9be2;
  }
  .theme-blue .blog-page .single_post .footer .stats li a:hover {
    color: #0e9be2;
  }
  .theme-blue .ct-series-a .ct-line,
  .theme-blue .ct-series-a .ct-bar,
  .theme-blue .ct-series-a .ct-point {
    stroke: #303844;
  }
  .theme-blue .ct-series-a .ct-area,
  .theme-blue .ct-series-a .ct-slice-donut-solid,
  .theme-blue .ct-series-a .ct-slice-pie,
  .theme-blue .ct-series-a .ct-bar {
    fill: #303844;
  }
  .theme-blue .ct-series-b .ct-line,
  .theme-blue .ct-series-b .ct-bar,
  .theme-blue .ct-series-b .ct-point {
    stroke: #0e9be2;
  }
  .theme-blue .ct-series-b .ct-area,
  .theme-blue .ct-series-b .ct-slice-donut-solid,
  .theme-blue .ct-series-b .ct-slice-pie,
  .theme-blue .ct-series-b .ct-bar {
    fill: #0e9be2;
  }
  .theme-blue .ct-series-c .ct-line,
  .theme-blue .ct-series-c .ct-bar,
  .theme-blue .ct-series-c .ct-point {
    stroke: #ffaf1d;
  }
  .theme-blue .ct-series-c .ct-area,
  .theme-blue .ct-series-c .ct-slice-donut-solid,
  .theme-blue .ct-series-c .ct-slice-pie,
  .theme-blue .ct-series-c .ct-bar {
    fill: #ffaf1d;
  }
  .theme-blue .ct-series-d .ct-line,
  .theme-blue .ct-series-d .ct-bar,
  .theme-blue .ct-series-d .ct-point {
    stroke: #02b5b2;
  }
  .theme-blue .ct-series-d .ct-area,
  .theme-blue .ct-series-d .ct-slice-donut-solid,
  .theme-blue .ct-series-d .ct-slice-pie,
  .theme-blue .ct-series-d .ct-bar {
    fill: #02b5b2;
  }
  .theme-blue .ct-series-e .ct-line,
  .theme-blue .ct-series-e .ct-bar,
  .theme-blue .ct-series-e .ct-point {
    stroke: #767ee9;
  }
  .theme-blue .ct-series-e .ct-area,
  .theme-blue .ct-series-e .ct-slice-donut-solid,
  .theme-blue .ct-series-e .ct-slice-pie,
  .theme-blue .ct-series-e .ct-bar {
    fill: #767ee9;
  }
  .theme-blue .ct-series-f .ct-line,
  .theme-blue .ct-series-f .ct-bar,
  .theme-blue .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-blue .ct-series-f .ct-area,
  .theme-blue .ct-series-f .ct-slice-donut-solid,
  .theme-blue .ct-series-f .ct-slice-pie,
  .theme-blue .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-blue .ct-legend .ct-series-0:before {
    background-color: #303844;
    border-color: #303844;
  }
  .theme-blue .ct-legend .ct-series-1:before {
    background-color: #0e9be2;
    border-color: #0e9be2;
  }
  .theme-blue .ct-legend .ct-series-2:before {
    background-color: #ffaf1d;
    border-color: #ffaf1d;
  }
  .theme-blue .ct-legend .ct-series-3:before {
    background-color: #02b5b2;
    border-color: #02b5b2;
  }
  .theme-blue .ct-legend .ct-series-4:before {
    background-color: #767ee9;
    border-color: #767ee9;
  }
  .theme-blue .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-cyan ::selection {
    color: #fff;
    background: #00bcd2;
  }
  .theme-cyan .page-loader-wrapper {
    background: #00bcd2;
  }
  .theme-cyan .navbar-fixed-top {
    background: #00bcd2;
  }
  .theme-cyan .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-cyan .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li.active > a {
    background: #00bcd2;
    
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-cyan .sidebar-nav .metismenu > li i {
    color: #6f7b7d;
  }
  .theme-cyan .sidebar-nav .metismenu > li ul li.active a {
    color: #00bcd2;
   
  }
  .theme-cyan .sidebar-nav .metismenu a {
    color: #6f7b7d;
  }
  .theme-cyan .sidebar-nav .metismenu a:hover,
  .theme-cyan .sidebar-nav .metismenu a:focus {
    background: #2d3435;
  }
  .theme-cyan #left-sidebar {
    background: #fff;
  }
  .theme-cyan #left-sidebar .user-account .user_div .user-photo {
    border-color: #00bcd2;
  }
  .theme-cyan .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-cyan .chat-widget li.right .chat-info {
    background: #00bcd2;
    color: #fff;
  }
  .theme-cyan .chat-widget li.right .chat-info:before {
    border-left: 10px solid #00bcd2;
  }
  .theme-cyan .right_chat li a:hover .media-object {
    border-color: #00bcd2;
  }
  .theme-cyan
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #00bcd2;
    border-color: #00bcd2;
    color: #fff;
  }
  .theme-cyan .auth-main:after {
    background: #00bcd2;
  }
  .theme-cyan .auth-main:before {
    background: #242a2b;
  }
  .theme-cyan .wizard > .steps .current a {
    background: #00bcd2;
  }
  .theme-cyan .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-cyan .wizard .actions a {
    background: #00bcd2;
  }
  .theme-cyan .maintenance {
    background: #00bcd2;
  }
  .theme-cyan .maintenance > div {
    background: #fff;
  }
  .theme-cyan .noUi-connect {
    background: #00bcd2;
  }
  .theme-cyan .blog-page .single_post .footer .stats li a:hover {
    color: #00bcd2;
  }
  .theme-cyan .ct-series-a .ct-line,
  .theme-cyan .ct-series-a .ct-bar,
  .theme-cyan .ct-series-a .ct-point {
    stroke: #394344;
  }
  .theme-cyan .ct-series-a .ct-area,
  .theme-cyan .ct-series-a .ct-slice-donut-solid,
  .theme-cyan .ct-series-a .ct-slice-pie,
  .theme-cyan .ct-series-a .ct-bar {
    fill: #394344;
  }
  .theme-cyan .ct-series-b .ct-line,
  .theme-cyan .ct-series-b .ct-bar,
  .theme-cyan .ct-series-b .ct-point {
    stroke: #00bcd2;
  }
  .theme-cyan .ct-series-b .ct-area,
  .theme-cyan .ct-series-b .ct-slice-donut-solid,
  .theme-cyan .ct-series-b .ct-slice-pie,
  .theme-cyan .ct-series-b .ct-bar {
    fill: #00bcd2;
  }
  .theme-cyan .ct-series-c .ct-line,
  .theme-cyan .ct-series-c .ct-bar,
  .theme-cyan .ct-series-c .ct-point {
    stroke: #5ed3e1;
  }
  .theme-cyan .ct-series-c .ct-area,
  .theme-cyan .ct-series-c .ct-slice-donut-solid,
  .theme-cyan .ct-series-c .ct-slice-pie,
  .theme-cyan .ct-series-c .ct-bar {
    fill: #5ed3e1;
  }
  .theme-cyan .ct-series-d .ct-line,
  .theme-cyan .ct-series-d .ct-bar,
  .theme-cyan .ct-series-d .ct-point {
    stroke: #97cad0;
  }
  .theme-cyan .ct-series-d .ct-area,
  .theme-cyan .ct-series-d .ct-slice-donut-solid,
  .theme-cyan .ct-series-d .ct-slice-pie,
  .theme-cyan .ct-series-d .ct-bar {
    fill: #97cad0;
  }
  .theme-cyan .ct-series-e .ct-line,
  .theme-cyan .ct-series-e .ct-bar,
  .theme-cyan .ct-series-e .ct-point {
    stroke: #394344;
  }
  .theme-cyan .ct-series-e .ct-area,
  .theme-cyan .ct-series-e .ct-slice-donut-solid,
  .theme-cyan .ct-series-e .ct-slice-pie,
  .theme-cyan .ct-series-e .ct-bar {
    fill: #394344;
  }
  .theme-cyan .ct-series-f .ct-line,
  .theme-cyan .ct-series-f .ct-bar,
  .theme-cyan .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-cyan .ct-series-f .ct-area,
  .theme-cyan .ct-series-f .ct-slice-donut-solid,
  .theme-cyan .ct-series-f .ct-slice-pie,
  .theme-cyan .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-cyan .ct-legend .ct-series-0:before {
    background-color: #394344;
    border-color: #394344;
  }
  .theme-cyan .ct-legend .ct-series-1:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-cyan .ct-legend .ct-series-2:before {
    background-color: #5ed3e1;
    border-color: #5ed3e1;
  }
  .theme-cyan .ct-legend .ct-series-3:before {
    background-color: #97cad0;
    border-color: #97cad0;
  }
  .theme-cyan .ct-legend .ct-series-4:before {
    background-color: #394344;
    border-color: #394344;
  }
  .theme-cyan .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-green ::selection {
    color: #fff;
    background: #29bd73;
  }
  .theme-green .page-loader-wrapper {
    background: #29bd73;
  }
  .theme-green .navbar-fixed-top {
    background: #29bd73;
  }
  .theme-green .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-green .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li.active > a {
    background: #29bd73;
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu > li i {
    color: #182973;
  }
  .theme-green .sidebar-nav .metismenu a {
    color: #182973;
  }
  .theme-green .sidebar-nav .metismenu a:hover,
  .theme-green .sidebar-nav .metismenu a:focus {
    background: #182973;
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu a:hover i,
  .theme-green .sidebar-nav .metismenu a:focus i {
    color: #fff;
  }
  .theme-green .sidebar-nav .metismenu .has-arrow:after {
    color: #e8e8e8;
  }
  .theme-green #left-sidebar {
    background: #fff;
  }
  .theme-green #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-green #left-sidebar .navbar-brand span {
    color: #29bd73;
  }
  .theme-green #left-sidebar .user-account {
    color: #182973;
  }
  .theme-green #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-green #left-sidebar .user-account .user_div .user-photo {
    border-color: #29bd73;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu {
    background: #182973;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a:hover {
    color: #29bd73;
  }
  .theme-green #left-sidebar .user-account .dropdown-menu a:hover i {
    color: #29bd73;
  }
  .theme-green .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #29bd73;
    border-color: #29bd73;
  }
  .theme-green .chat-widget li.right .chat-info {
    background: #29bd73;
    color: #fff;
  }
  .theme-green .chat-widget li.right .chat-info:before {
    border-left: 10px solid #29bd73;
  }
  .theme-green .right_chat li a:hover .media-object {
    border-color: #29bd73;
  }
  .theme-green
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #29bd73;
    border-color: #29bd73;
    color: #fff;
  }
  .theme-green .auth-main:after {
    background: #29bd73;
  }
  .theme-green .auth-main:before {
    background: #182973;
  }
  .theme-green .wizard > .steps .current a {
    background: #29bd73;
  }
  .theme-green .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-green .wizard .actions a {
    background: #182973;
  }
  .theme-green .maintenance {
    background: #29bd73;
  }
  .theme-green .maintenance > div {
    background: #fff;
  }
  .theme-green .noUi-connect {
    background: #29bd73;
  }
  .theme-green .blog-page .single_post .footer .stats li a:hover {
    color: #29bd73;
  }
  .theme-green .ct-series-a .ct-line,
  .theme-green .ct-series-a .ct-bar,
  .theme-green .ct-series-a .ct-point {
    stroke: #182973;
  }
  .theme-green .ct-series-a .ct-area,
  .theme-green .ct-series-a .ct-slice-donut-solid,
  .theme-green .ct-series-a .ct-slice-pie,
  .theme-green .ct-series-a .ct-bar {
    fill: #182973;
  }
  .theme-green .ct-series-b .ct-line,
  .theme-green .ct-series-b .ct-bar,
  .theme-green .ct-series-b .ct-point {
    stroke: #29bd73;
  }
  .theme-green .ct-series-b .ct-area,
  .theme-green .ct-series-b .ct-slice-donut-solid,
  .theme-green .ct-series-b .ct-slice-pie,
  .theme-green .ct-series-b .ct-bar {
    fill: #29bd73;
  }
  .theme-green .ct-series-c .ct-line,
  .theme-green .ct-series-c .ct-bar,
  .theme-green .ct-series-c .ct-point {
    stroke: #6bc196;
  }
  .theme-green .ct-series-c .ct-area,
  .theme-green .ct-series-c .ct-slice-donut-solid,
  .theme-green .ct-series-c .ct-slice-pie,
  .theme-green .ct-series-c .ct-bar {
    fill: #6bc196;
  }
  .theme-green .ct-series-d .ct-line,
  .theme-green .ct-series-d .ct-bar,
  .theme-green .ct-series-d .ct-point {
    stroke: #a4d4bc;
  }
  .theme-green .ct-series-d .ct-area,
  .theme-green .ct-series-d .ct-slice-donut-solid,
  .theme-green .ct-series-d .ct-slice-pie,
  .theme-green .ct-series-d .ct-bar {
    fill: #a4d4bc;
  }
  .theme-green .ct-series-e .ct-line,
  .theme-green .ct-series-e .ct-bar,
  .theme-green .ct-series-e .ct-point {
    stroke: #00bcd2;
  }
  .theme-green .ct-series-e .ct-area,
  .theme-green .ct-series-e .ct-slice-donut-solid,
  .theme-green .ct-series-e .ct-slice-pie,
  .theme-green .ct-series-e .ct-bar {
    fill: #00bcd2;
  }
  .theme-green .ct-series-f .ct-line,
  .theme-green .ct-series-f .ct-bar,
  .theme-green .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-green .ct-series-f .ct-area,
  .theme-green .ct-series-f .ct-slice-donut-solid,
  .theme-green .ct-series-f .ct-slice-pie,
  .theme-green .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-green .ct-legend .ct-series-0:before {
    background-color: #182973;
    border-color: #182973;
  }
  .theme-green .ct-legend .ct-series-1:before {
    background-color: #29bd73;
    border-color: #29bd73;
  }
  .theme-green .ct-legend .ct-series-2:before {
    background-color: #6bc196;
    border-color: #6bc196;
  }
  .theme-green .ct-legend .ct-series-3:before {
    background-color: #a4d4bc;
    border-color: #a4d4bc;
  }
  .theme-green .ct-legend .ct-series-4:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-green .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
  .theme-orange ::selection {
    color: #fff;
    background: #00bcd2;
  }
  .theme-orange .page-loader-wrapper {
    background: #00bcd2;
  }
  .theme-orange .navbar-fixed-top {
    background: #00bcd2;
  }
  .theme-orange .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-orange .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li.active > a {
    background: #343a40;
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu > li i {
    color: #380e47;
  }
  .theme-orange .sidebar-nav .metismenu a {
    color: #380e47;
  }
  .theme-orange .sidebar-nav .metismenu a:hover,
  .theme-orange .sidebar-nav .metismenu a:focus {
    /* background: #00bcd2; */
    background-color: #F45D48;
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu a:hover i,
  .theme-orange .sidebar-nav .metismenu a:focus i {
    color: #fff;
  }
  .theme-orange .sidebar-nav .metismenu .has-arrow:after {
    color: #e8e8e8;
  }
  .theme-orange #left-sidebar {
    background: #fff;
  }
  .theme-orange #left-sidebar .navbar-brand {
    border-color: #e8e8e8;
  }
  .theme-orange #left-sidebar .navbar-brand span {
    /* color: #00bcd2; */
  }
  .theme-orange #left-sidebar .user-account {
    color: #380e47;
  }
  .theme-orange #left-sidebar .user-account .user_div {
    border-color: #e8e8e8;
  }
  .theme-orange #left-sidebar .user-account .user_div .user-photo {
    border-color: #00bcd2;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu {
    background: #343a40;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a {
    color: #e8e8e8;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a:hover {
    color: #00bcd2;
  }
  .theme-orange #left-sidebar .user-account .dropdown-menu a:hover i {
    color: #00bcd2;
  }
  .theme-orange .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-orange .chat-widget li.right .chat-info {
    background: #00bcd2;
    color: #fff;
  }
  .theme-orange .chat-widget li.right .chat-info:before {
    border-left: 10px solid #00bcd2;
  }
  .theme-orange .right_chat li a:hover .media-object {
    border-color: #00bcd2;
  }
  .theme-orange
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #00bcd2;
    border-color: #00bcd2;
    color: #fff;
  }
  .theme-orange .auth-main:after {
    background: #00bcd2;
  }
  .theme-orange .auth-main:before {
    background: #380e47;
  }
  .theme-orange .wizard > .steps .current a {
    background: #00bcd2;
  }
  .theme-orange .wizard > .steps .done a {
    background: #6f7b7d;
  }
  .theme-orange .wizard .actions a {
    background: #380e47;
  }
  .theme-orange .maintenance {
    background: #00bcd2;
  }
  .theme-orange .maintenance > div {
    background: #fff;
  }
  .theme-orange .noUi-connect {
    background: #00bcd2;
  }
  .theme-orange .blog-page .single_post .footer .stats li a:hover {
    color: #00bcd2;
  }
  .theme-orange .ct-series-a .ct-line,
  .theme-orange .ct-series-a .ct-bar,
  .theme-orange .ct-series-a .ct-point {
    stroke: #380e47;
  }
  .theme-orange .ct-series-a .ct-area,
  .theme-orange .ct-series-a .ct-slice-donut-solid,
  .theme-orange .ct-series-a .ct-slice-pie,
  .theme-orange .ct-series-a .ct-bar {
    fill: #380e47;
  }
  .theme-orange .ct-series-b .ct-line,
  .theme-orange .ct-series-b .ct-bar,
  .theme-orange .ct-series-b .ct-point {
    stroke: #00bcd2;
  }
  .theme-orange .ct-series-b .ct-area,
  .theme-orange .ct-series-b .ct-slice-donut-solid,
  .theme-orange .ct-series-b .ct-slice-pie,
  .theme-orange .ct-series-b .ct-bar {
    fill: #00bcd2;
  }
  .theme-orange .ct-series-c .ct-line,
  .theme-orange .ct-series-c .ct-bar,
  .theme-orange .ct-series-c .ct-point {
    stroke: #ffd974;
  }
  .theme-orange .ct-series-c .ct-area,
  .theme-orange .ct-series-c .ct-slice-donut-solid,
  .theme-orange .ct-series-c .ct-slice-pie,
  .theme-orange .ct-series-c .ct-bar {
    fill: #ffd974;
  }
  .theme-orange .ct-series-d .ct-line,
  .theme-orange .ct-series-d .ct-bar,
  .theme-orange .ct-series-d .ct-point {
    stroke: #e4dbc2;
  }
  .theme-orange .ct-series-d .ct-area,
  .theme-orange .ct-series-d .ct-slice-donut-solid,
  .theme-orange .ct-series-d .ct-slice-pie,
  .theme-orange .ct-series-d .ct-bar {
    fill: #e4dbc2;
  }
  .theme-orange .ct-series-e .ct-line,
  .theme-orange .ct-series-e .ct-bar,
  .theme-orange .ct-series-e .ct-point {
    stroke: #1a79ee;
  }
  .theme-orange .ct-series-e .ct-area,
  .theme-orange .ct-series-e .ct-slice-donut-solid,
  .theme-orange .ct-series-e .ct-slice-pie,
  .theme-orange .ct-series-e .ct-bar {
    fill: #1a79ee;
  }
  .theme-orange .ct-series-f .ct-line,
  .theme-orange .ct-series-f .ct-bar,
  .theme-orange .ct-series-f .ct-point {
    stroke: #2ac151;
  }
  .theme-orange .ct-series-f .ct-area,
  .theme-orange .ct-series-f .ct-slice-donut-solid,
  .theme-orange .ct-series-f .ct-slice-pie,
  .theme-orange .ct-series-f .ct-bar {
    fill: #2ac151;
  }
  .theme-orange .ct-legend .ct-series-0:before {
    background-color: #380e47;
    border-color: #380e47;
  }
  .theme-orange .ct-legend .ct-series-1:before {
    background-color: #00bcd2;
    border-color: #00bcd2;
  }
  .theme-orange .ct-legend .ct-series-2:before {
    background-color: #ffd974;
    border-color: #ffd974;
  }
  .theme-orange .ct-legend .ct-series-3:before {
    background-color: #e4dbc2;
    border-color: #e4dbc2;
  }
  .theme-orange .ct-legend .ct-series-4:before {
    background-color: #1a79ee;
    border-color: #1a79ee;
  }
  .theme-orange .ct-legend .ct-series-5:before {
    background-color: #2ac151;
    border-color: #2ac151;
  }
  .theme-blush ::selection {
    color: #fff;
    background: #e25985;
  }
  .theme-blush .page-loader-wrapper {
    background: #e25985;
  }
  .theme-blush .navbar-fixed-top {
    background: #e25985;
  }
  .theme-blush .navbar-fixed-top .form-control {
    border: 0;
  }
  .theme-blush .navbar-fixed-top .icon-menu i {
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li.active > a {
    background: #e25985;
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li.active > a i {
    color: #fff;
  }
  .theme-blush .sidebar-nav .metismenu > li i {
    color: #969696;
  }
  .theme-blush .sidebar-nav .metismenu > li ul li.active a {
    color: #e25985;
  }
  .theme-blush .sidebar-nav .metismenu a {
    color: #969696;
  }
  .theme-blush .sidebar-nav .metismenu a:hover,
  .theme-blush .sidebar-nav .metismenu a:focus {
    background: rgba(226, 89, 133, 0.1);
  }
  .theme-blush #left-sidebar {
    background: #1b1f23;
  }
  .theme-blush #left-sidebar .user-account .user_div .user-photo {
    border-color: #e25985;
  }
  .theme-blush .fancy-checkbox input[type="checkbox"]:checked + span:before {
    background: #e25985;
    border-color: #e25985;
  }
  .theme-blush .chat-widget li.right .chat-info {
    background: #e25985;
    color: #fff;
  }
  .theme-blush .chat-widget li.right .chat-info:before {
    border-left: 10px solid #e25985;
  }
  .theme-blush .right_chat li a:hover .media-object {
    border-color: #e25985;
  }
  .theme-blush
    .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header {
    background: #e25985;
    border-color: #e25985;
    color: #fff;
  }
  .theme-blush .auth-main:after {
    background: #e25985;
  }
  .theme-blush .auth-main:before {
    background: #1b1f23;
  }
  .theme-blush .wizard > .steps .current a {
    background: #e25985;
  }
  .theme-blush .wizard > .steps .done a {
    background: #969696;
  }
  .theme-blush .wizard .actions a {
    background: #1b1f23;
  }
  .theme-blush .maintenance {
    background: #e25985;
  }
  .theme-blush .maintenance > div {
    background: #fff;
  }
  .theme-blush .noUi-connect {
    background: #e25985;
  }
  .theme-blush .blog-page .single_post .footer .stats li a:hover {
    color: #e25985;
  }
  .theme-blush .ct-series-a .ct-line,
  .theme-blush .ct-series-a .ct-bar,
  .theme-blush .ct-series-a .ct-point {
    stroke: #1b1f23;
  }
  .theme-blush .ct-series-a .ct-area,
  .theme-blush .ct-series-a .ct-slice-donut-solid,
  .theme-blush .ct-series-a .ct-slice-pie,
  .theme-blush .ct-series-a .ct-bar {
    fill: #1b1f23;
  }
  .theme-blush .ct-series-b .ct-line,
  .theme-blush .ct-series-b .ct-bar,
  .theme-blush .ct-series-b .ct-point {
    stroke: #e25985;
  }
  .theme-blush .ct-series-b .ct-area,
  .theme-blush .ct-series-b .ct-slice-donut-solid,
  .theme-blush .ct-series-b .ct-slice-pie,
  .theme-blush .ct-series-b .ct-bar {
    fill: #e25985;
  }
  .theme-blush .ct-series-c .ct-line,
  .theme-blush .ct-series-c .ct-bar,
  .theme-blush .ct-series-c .ct-point {
    stroke: #de7f9e;
  }
  .theme-blush .ct-series-c .ct-area,
  .theme-blush .ct-series-c .ct-slice-donut-solid,
  .theme-blush .ct-series-c .ct-slice-pie,
  .theme-blush .ct-series-c .ct-bar {
    fill: #de7f9e;
  }
  .theme-blush .ct-series-d .ct-line,
  .theme-blush .ct-series-d .ct-bar,
  .theme-blush .ct-series-d .ct-point {
    stroke: #debac6;
  }
  .theme-blush .ct-series-d .ct-area,
  .theme-blush .ct-series-d .ct-slice-donut-solid,
  .theme-blush .ct-series-d .ct-slice-pie,
  .theme-blush .ct-series-d .ct-bar {
    fill: #debac6;
  }
  .theme-blush .ct-series-e .ct-line,
  .theme-blush .ct-series-e .ct-bar,
  .theme-blush .ct-series-e .ct-point {
    stroke: #767ee9;
  }
  .theme-blush .ct-series-e .ct-area,
  .theme-blush .ct-series-e .ct-slice-donut-solid,
  .theme-blush .ct-series-e .ct-slice-pie,
  .theme-blush .ct-series-e .ct-bar {
    fill: #767ee9;
  }
  .theme-blush .ct-series-f .ct-line,
  .theme-blush .ct-series-f .ct-bar,
  .theme-blush .ct-series-f .ct-point {
    stroke: #d17905;
  }
  .theme-blush .ct-series-f .ct-area,
  .theme-blush .ct-series-f .ct-slice-donut-solid,
  .theme-blush .ct-series-f .ct-slice-pie,
  .theme-blush .ct-series-f .ct-bar {
    fill: #d17905;
  }
  .theme-blush .ct-legend .ct-series-0:before {
    background-color: #1b1f23;
    border-color: #1b1f23;
  }
  .theme-blush .ct-legend .ct-series-1:before {
    background-color: #e25985;
    border-color: #e25985;
  }
  .theme-blush .ct-legend .ct-series-2:before {
    background-color: #de7f9e;
    border-color: #de7f9e;
  }
  .theme-blush .ct-legend .ct-series-3:before {
    background-color: #debac6;
    border-color: #debac6;
  }
  .theme-blush .ct-legend .ct-series-4:before {
    background-color: #767ee9;
    border-color: #767ee9;
  }
  .theme-blush .ct-legend .ct-series-5:before {
    background-color: #d17905;
    border-color: #d17905;
  }
}
.theme-orange .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-orange .sidebar-nav .metismenu > li i {
  color: #380e47;
}
.theme-orange .sidebar-nav .metismenu a {
  color: #380e47;
}
/* changes to be done */
.theme-orange .sidebar-nav .metismenu a:hover,  
.theme-orange .sidebar-nav .metismenu a:focus {
  /* background: #F45D48; 
  color: #fff; */
  background-color: #fff;
  color: #F45D48;
}
.theme-orange .sidebar-nav .metismenu a:hover i,
.theme-orange .sidebar-nav .metismenu a:focus i {
  color: #fff;
}
.theme-orange .sidebar-nav .metismenu .has-arrow:after {
  color: #e8e8e8;
}
.theme-orange #left-sidebar {
  background: #fff;
}
.theme-orange #left-sidebar .navbar-brand {
  border-color: #e8e8e8;
}
.theme-orange #left-sidebar .navbar-brand span {
  color: #00bcd2;
}
.theme-orange #left-sidebar .user-account {
  color: #380e47;
}
.theme-orange #left-sidebar .user-account .user_div {
  border-color: #e8e8e8;
}
.theme-orange #left-sidebar .user-account .user_div .user-photo {
  border-color: #00bcd2;
  border-radius: 90px;
}
.theme-orange #left-sidebar .user-account .dropdown-menu {
  background: #343a40;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a {
  color: #e8e8e8;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a:hover {
  color: #00bcd2;
}
.theme-orange #left-sidebar .user-account .dropdown-menu a:hover i {
  color: #00bcd2;
}
.theme-orange .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #00bcd2;
  border-color: #00bcd2;
}
.theme-orange .chat-widget li.right .chat-info {
  background: #00bcd2;
  color: #fff;
}
.theme-orange .chat-widget li.right .chat-info:before {
  border-left: 10px solid #00bcd2;
}
.theme-orange .right_chat li a:hover .media-object {
  border-color: #00bcd2;
}
.theme-orange
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #00bcd2;
  border-color: #00bcd2;
  color: #fff;
}
.theme-orange .auth-main:after {
  background: #00bcd2;
}
.theme-orange .auth-main:before {
  background: #380e47;
}
.theme-orange .wizard > .steps .current a {
  background: #00bcd2;
}
.theme-orange .wizard > .steps .done a {
  background: #6f7b7d;
}
.theme-orange .wizard .actions a {
  background: #380e47;
}
.theme-orange .maintenance {
  background: #00bcd2;
}
.theme-orange .maintenance > div {
  background: #fff;
}
.theme-orange .noUi-connect {
  background: #00bcd2;
}
.theme-orange .blog-page .single_post .footer .stats li a:hover {
  color: #00bcd2;
}
.theme-orange .ct-series-a .ct-line,
.theme-orange .ct-series-a .ct-bar,
.theme-orange .ct-series-a .ct-point {
  stroke: #380e47;
}
.theme-orange .ct-series-a .ct-area,
.theme-orange .ct-series-a .ct-slice-donut-solid,
.theme-orange .ct-series-a .ct-slice-pie,
.theme-orange .ct-series-a .ct-bar {
  fill: #380e47;
}
.theme-orange .ct-series-b .ct-line,
.theme-orange .ct-series-b .ct-bar,
.theme-orange .ct-series-b .ct-point {
  stroke: #00bcd2;
}
.theme-orange .ct-series-b .ct-area,
.theme-orange .ct-series-b .ct-slice-donut-solid,
.theme-orange .ct-series-b .ct-slice-pie,
.theme-orange .ct-series-b .ct-bar {
  fill: #00bcd2;
}
.theme-orange .ct-series-c .ct-line,
.theme-orange .ct-series-c .ct-bar,
.theme-orange .ct-series-c .ct-point {
  stroke: #ffd974;
}
.theme-orange .ct-series-c .ct-area,
.theme-orange .ct-series-c .ct-slice-donut-solid,
.theme-orange .ct-series-c .ct-slice-pie,
.theme-orange .ct-series-c .ct-bar {
  fill: #ffd974;
}
.theme-orange .ct-series-d .ct-line,
.theme-orange .ct-series-d .ct-bar,
.theme-orange .ct-series-d .ct-point {
  stroke: #e4dbc2;
}
.theme-orange .ct-series-d .ct-area,
.theme-orange .ct-series-d .ct-slice-donut-solid,
.theme-orange .ct-series-d .ct-slice-pie,
.theme-orange .ct-series-d .ct-bar {
  fill: #e4dbc2;
}
.theme-orange .ct-series-e .ct-line,
.theme-orange .ct-series-e .ct-bar,
.theme-orange .ct-series-e .ct-point {
  stroke: #1a79ee;
}
.theme-orange .ct-series-e .ct-area,
.theme-orange .ct-series-e .ct-slice-donut-solid,
.theme-orange .ct-series-e .ct-slice-pie,
.theme-orange .ct-series-e .ct-bar {
  fill: #1a79ee;
}
.theme-orange .ct-series-f .ct-line,
.theme-orange .ct-series-f .ct-bar,
.theme-orange .ct-series-f .ct-point {
  stroke: #2ac151;
}
.theme-orange .ct-series-f .ct-area,
.theme-orange .ct-series-f .ct-slice-donut-solid,
.theme-orange .ct-series-f .ct-slice-pie,
.theme-orange .ct-series-f .ct-bar {
  fill: #2ac151;
}
.theme-orange .ct-legend .ct-series-0:before {
  background-color: #380e47;
  border-color: #380e47;
}
.theme-orange .ct-legend .ct-series-1:before {
  background-color: #00bcd2;
  border-color: #00bcd2;
}
.theme-orange .ct-legend .ct-series-2:before {
  background-color: #ffd974;
  border-color: #ffd974;
}
.theme-orange .ct-legend .ct-series-3:before {
  background-color: #e4dbc2;
  border-color: #e4dbc2;
}
.theme-orange .ct-legend .ct-series-4:before {
  background-color: #1a79ee;
  border-color: #1a79ee;
}
.theme-orange .ct-legend .ct-series-5:before {
  background-color: #2ac151;
  border-color: #2ac151;
}
.theme-blush ::selection {
  color: #fff;
  background: #e25985;
}
.theme-blush .page-loader-wrapper {
  background: #e25985;
}
.theme-blush .navbar-fixed-top {
  background: #e25985;
}
.theme-blush .navbar-fixed-top .form-control {
  border: 0;
}
.theme-blush .navbar-fixed-top .icon-menu i {
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li.active > a {
  background: #e25985;
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li.active > a i {
  color: #fff;
}
.theme-blush .sidebar-nav .metismenu > li i {
  color: #969696;
}
.theme-blush .sidebar-nav .metismenu > li ul li.active a {
  color: #e25985;
}
.theme-blush .sidebar-nav .metismenu a {
  color: #969696;
}
.theme-blush .sidebar-nav .metismenu a:hover,
.theme-blush .sidebar-nav .metismenu a:focus {
  background: rgba(226, 89, 133, 0.1);
}
.theme-blush #left-sidebar {
  background: #1b1f23;
}
.theme-blush #left-sidebar .user-account .user_div .user-photo {
  border-color: #e25985;
}
.theme-blush .fancy-checkbox input[type="checkbox"]:checked + span:before {
  background: #e25985;
  border-color: #e25985;
}
.theme-blush .chat-widget li.right .chat-info {
  background: #e25985;
  color: #fff;
}
.theme-blush .chat-widget li.right .chat-info:before {
  border-left: 10px solid #e25985;
}
.theme-blush .right_chat li a:hover .media-object {
  border-color: #e25985;
}
.theme-blush
  .fc
  .fc-view-container
  .fc-view.fc-basic-view
  > table
  > thead
  tr
  th.fc-widget-header {
  background: #e25985;
  border-color: #e25985;
  color: #fff;
}
.theme-blush .auth-main:after {
  background: #e25985;
}
.theme-blush .auth-main:before {
  background: #1b1f23;
}
.theme-blush .wizard > .steps .current a {
  background: #e25985;
}
.theme-blush .wizard > .steps .done a {
  background: #969696;
}
.theme-blush .wizard .actions a {
  background: #1b1f23;
}
.theme-blush .maintenance {
  background: #e25985;
}
.theme-blush .maintenance > div {
  background: #fff;
}
.theme-blush .noUi-connect {
  background: #e25985;
}
.theme-blush .blog-page .single_post .footer .stats li a:hover {
  color: #e25985;
}
.theme-blush .ct-series-a .ct-line,
.theme-blush .ct-series-a .ct-bar,
.theme-blush .ct-series-a .ct-point {
  stroke: #1b1f23;
}
.theme-blush .ct-series-a .ct-area,
.theme-blush .ct-series-a .ct-slice-donut-solid,
.theme-blush .ct-series-a .ct-slice-pie,
.theme-blush .ct-series-a .ct-bar {
  fill: #1b1f23;
}
.theme-blush .ct-series-b .ct-line,
.theme-blush .ct-series-b .ct-bar,
.theme-blush .ct-series-b .ct-point {
  stroke: #e25985;
}
.theme-blush .ct-series-b .ct-area,
.theme-blush .ct-series-b .ct-slice-donut-solid,
.theme-blush .ct-series-b .ct-slice-pie,
.theme-blush .ct-series-b .ct-bar {
  fill: #e25985;
}
.theme-blush .ct-series-c .ct-line,
.theme-blush .ct-series-c .ct-bar,
.theme-blush .ct-series-c .ct-point {
  stroke: #de7f9e;
}
.theme-blush .ct-series-c .ct-area,
.theme-blush .ct-series-c .ct-slice-donut-solid,
.theme-blush .ct-series-c .ct-slice-pie,
.theme-blush .ct-series-c .ct-bar {
  fill: #de7f9e;
}
.theme-blush .ct-series-d .ct-line,
.theme-blush .ct-series-d .ct-bar,
.theme-blush .ct-series-d .ct-point {
  stroke: #debac6;
}
.theme-blush .ct-series-d .ct-area,
.theme-blush .ct-series-d .ct-slice-donut-solid,
.theme-blush .ct-series-d .ct-slice-pie,
.theme-blush .ct-series-d .ct-bar {
  fill: #debac6;
}
.theme-blush .ct-series-e .ct-line,
.theme-blush .ct-series-e .ct-bar,
.theme-blush .ct-series-e .ct-point {
  stroke: #767ee9;
}
.theme-blush .ct-series-e .ct-area,
.theme-blush .ct-series-e .ct-slice-donut-solid,
.theme-blush .ct-series-e .ct-slice-pie,
.theme-blush .ct-series-e .ct-bar {
  fill: #767ee9;
}
.theme-blush .ct-series-f .ct-line,
.theme-blush .ct-series-f .ct-bar,
.theme-blush .ct-series-f .ct-point {
  stroke: #d17905;
}
.theme-blush .ct-series-f .ct-area,
.theme-blush .ct-series-f .ct-slice-donut-solid,
.theme-blush .ct-series-f .ct-slice-pie,
.theme-blush .ct-series-f .ct-bar {
  fill: #d17905;
}
.theme-blush .ct-legend .ct-series-0:before {
  background-color: #1b1f23;
  border-color: #1b1f23;
}
.theme-blush .ct-legend .ct-series-1:before {
  background-color: #e25985;
  border-color: #e25985;
}
.theme-blush .ct-legend .ct-series-2:before {
  background-color: #de7f9e;
  border-color: #de7f9e;
}
.theme-blush .ct-legend .ct-series-3:before {
  background-color: #debac6;
  border-color: #debac6;
}
.theme-blush .ct-legend .ct-series-4:before {
  background-color: #767ee9;
  border-color: #767ee9;
}
.theme-blush .ct-legend .ct-series-5:before {
  background-color: #d17905;
  border-color: #d17905;
}

/* changes */
.theme-orange .sidebar-nav .metismenu > li.active > a {
  background: #ffff;
  color: #F45D48;
  font-weight: bold;
}

:root {
    --primary-gray: #f7f7f7;
    --primaryColor: #f45d48;
    --primaryColorHovered: #fd7867;
    --secondaryColor: #0a8080;
    --primaryLightColor: #f8f5f2;
    --primaryLightColorTwo: #feefe8;
    --primaryLightColorThree: #fff2df;
    --textColor: #363636;
  }
  
