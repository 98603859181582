:root {
    --primary-gray: #f7f7f7;
    --primaryColor: #f45d48;
    --primaryColorHovered: #fd7867;
    --secondaryColor: #0a8080;
    --primaryLightColor: #f8f5f2;
    --primaryLightColorTwo: #feefe8;
    --primaryLightColorThree: #fff2df;
    --textColor: #363636;
  }
  