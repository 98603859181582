.popBackground {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  z-index: 8;
  display: none;
}

.text-red {
  color: red;
}
.invalid-feedback {
  display: block !important;
  width: 100%;
  margin-top: 0rem !important;
  /* font-size: 80%; */
  font-size: 0.75rem !important;
  color: #e74a3b;
  padding-left: 1% !important;
  text-align: left;
}

.anchor-tag {
  color: var(--primaryColor);
}
.anchor-tag:hover {
  text-decoration: underline;
  color: var(--primaryColorHovered);
  cursor: pointer;
}
.required:after {
  content:" *";
  font-size: 10px;
  color: rgb(163, 163, 163);
}