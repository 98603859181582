.varriant1 {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  width: 100%;
}

.varriant1_input {
  border: 1px solid #d9d9d9;
  padding: 16px 10px;
  border-right: none;
  font-size: 0.7rem;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 97%;
  height: 26px;
}

.varriant1_input:focus {
  outline: none;
}

.varriant1_icon {
  min-width: 28px;
  border: 1px solid #d9d9d9;
  border-left: none;
  /* padding: 3px 8px; */
  border-radius: 5px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  color: white;
  width: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.varriant1_icon img {
  width: 13px;
  height: 13px;
}
