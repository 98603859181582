.readonly {
    background-color: #dbd7d8;
    border: 1px solid #8facc2;
    padding-left: 5px;
  }
  
  /* .my-autocomplete-search {
      border: 1px solid #000;
      background: none;
    } */
  
  /* .fa-plus,
  .fa-search,
  .fa-pencil-alt {
    font-size: 24px;
    position: relative;
    top: 3px;
    margin-left: 5px;
  } */
  /* .fa-plus {
    color: #518197;
  }
  
  .fa-search {
    color: #56849a;
  }
  
  .fas {
    cursor: pointer;
  } */
  
  /* .myAutocomplete {
      position: absolute;
      display: inline-block;
    } */
  
  
  #myAutocompleteID {
    min-height: 35px;
    font-size: 13px;
    width: 100% !important;
    color: #495057;
    background-color: #ffdd;
    background-clip: padding-box;
    border: 1px solid #e0e0ad;
    border-radius: 0.25rem;
    padding-left: 16px;
  }
  #myAutocompleteID :focus {
    border: 1px solid #eb7890 !important;
  }
  
  .myAutocomplete-items {
    position: absolute;
    border: 1px solid #d4d4d4;
    border-bottom: none;
    border-top: none;
    z-index: 99999;
    /*position the autocomplete items to be the same width as the container:*/
    /* top: 100%;
      left: 0;
      right: 0; */
    width: 100%;
    box-shadow: 1px 19px 12px rgb(217 217 217); 
  }
  
  .myAutocomplete-items div,
  .myAutocomplete-items span {
    text-align: left;
    padding: 10px;
    cursor: pointer;
    color: black !important; /* inst visit */
    background-color: #fff;
    border-bottom: 1px solid #d4d4d4;
  }
  
  /*when hovering an item:*/
  .myAutocomplete-items div:hover,
  .myAutocomplete-items span:hover {
    background-color: #e9e9e9;
  }
  
  /*when navigating through the items using the arrow keys:*/
  .myAutocomplete-active {
    background-color: #e9e9e9 !important;
  }
  
  #my-autocomplete-cross-icon {
    color: #858585;
    font-size: 16px;
    cursor: pointer;
    vertical-align: super;
  }
  
  /* .my-autocomplete-search-icon {
    font-weight: bold;
    padding: 15px;
    background: url(../../../asserts/images/my-autocomplete-searchicon.png)
      no-repeat right;
    border: none;
    cursor: pointer;
    vertical-align: super;
  } */
  .provider-form {
    padding-left: 0px 0px 0px 0px;
  }
  .provider-form input {
    width: 98%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #7d8086;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  
  .provider-form {
    padding-left: 0px 0px 0px 0px;
  }
  .provider-form label:hover {
    background: none;
  }
  
  .provider-form input {
    width: 98%;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #7d8086;
  }
  .provider-form .address {
    width: 99%;
    height: 36px;
    border-radius: 5px;
    border: 1px solid #8facc2;
  }
  .provider-form h3 {
    margin: 0 auto;
    color: #d8526b;
    background: #c5e7f4;
    padding: 10px;
    font-size: 17px;
    font-family: "Lato";
    border-bottom: 0px solid #03658c;
    font-weight: bold;
    float: left;
  }
  
  .provider-form h2 {
    /* ADDED BY KHIZER*/
    margin: 0 auto;
    color: #d8526b;
    background: #c5e7f4;
    padding: 10px;
    font-size: 25px;
    font-family: "Lato";
    border-bottom: 0px solid #03658c;
    font-weight: bold;
    float: left;
  }
  
  .provider-form .form-control-user {
    font-size: 0.8rem;
    border-radius: 5px;
    padding: 10px;
  }
  
  .provider-form:focus {
    /* color: #6e707e; */
    color: rgb(0 0 0) !important;
    background-color: #fff;
    border-color: #bac8f3;
    outline: 0;
    box-shadow: 0 0 0 0.08rem rgba(242, 121, 144);
  }
  
  .text-danger {
    color: #e74a3b !important;
  }
  
  a.text-danger:focus,
  a.text-danger:hover {
    color: #be2617 !important;
  }
  