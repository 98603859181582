/* bootstrap css */
.tp_row {
  display: flex;
  flex-wrap: wrap;
}
.tp_col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}
.tp_col-3 {
  flex: 0 0 auto;
  width: 25%;
}
.tp_col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}
.tp_col-6 {
  flex: 0 0 auto;
  width: 50%;
}
.tp_position-relative {
  position: relative !important;
}
.tp_d-flex {
  display: flex !important;
}

.tp_h-100 {
  height: 100% !important;
}
.tp_w-100 {
  width: 100% !important;
}
.tp_text-center {
  text-align: center;
}
.tp_align-items-center {
  align-items: center !important;
}
.tp_justify-content-center {
  justify-content: center !important;
}
.tp_justify-content-end {
  justify-content: flex-end !important;
}
.tp_justify-content-evenly {
  justify-content: space-evenly !important;
}
/* bootstrap css ENDDDDDDDDDDDDDDDDDDDDD */

/*  HOUR AND MINUTES INPUT CSS */

.hours_minute_input {
  width: 40px;
    height: 37px;
    min-height: 20px;
    border: none;
    padding: 0px;
    margin: 0px;
    color: black;
    text-align: center;
    border-radius: 4px;
    background: white;
    font-weight: 300;
    line-height: 1.2;
    transition: all 0.5s;
    vertical-align: middle;
    font-size: 24px !important;
}
.hours_minute_input:read-only {
  cursor: pointer;
  background: transparent;
  color: #036bb4;
}
.hours_minute_input:hover {
  background: #036bb4;
  color: #fff;
}
.hours_minute_input:focus-visible {
  outline: none;
}

/* HOUR AND MINUTES INPUT ******** END ****** */

.form-outline {
  position: relative;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
}
.timepicker-input {
  min-height: auto;
  padding: 0.33em 0.75em;
  border: 0;
  background: transparent;
  transition: all 0.2s linear;
  display: block;
  width: -webkit-fill-available;
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.6;
  color: #4f4f4f;
  appearance: none;
  border-radius: 0.25rem;
}

.timepicker-toggle-button:hover {
  color: #2979ff;
}
.timepicker-toggle-button {
  position: absolute;
  outline: none;
  border: none;
  background-color: transparent;
  right: -10px;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.3s ease;
  cursor: pointer;
}
.timepicker-wrapper {
  padding: 3px 2px;
  position: absolute;
  touch-action: none;
  z-index: 1065;
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  color: #036bb4;
  justify-content: center;
  align-items: center;
  margin-top: 3px;
  box-sizing: border-box;
  height: 75px !important;
  border: 1px solid #036bb4;
}

.timepicker-current {
  font-weight: 300;
  line-height: 1.2;
  color: #000;
  border: none;
  background: transparent;
  padding: 0;
  font-size: 24px !important;
}
.timepicker_wrapper_icon {
  /* transition: all 0.2s ease;
  cursor: pointer;
  transform: translate(-50%, -50%); */
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 1.2rem;
  line-height: 8px;
  display: flex;
  justify-content: center;
}
.timepicker_wrapper_icon_up {
  top: -7px;
}
.timepicker_wrapper_icon_down {
  bottom: -7px;
}

.timepicker_icon {
  opacity: 1;
  cursor: pointer;
  transition: all 0.35s;
}
.timepicker_icon:hover {
  opacity: 1;
}

.timepicker-dots {
  margin: 0;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: -0.00833em;
}
.ok_btn {
  font-weight: 500;
  color: #fff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  background: #036bb4;
  font-size: 18px !important;
    padding: 5px !important;
}
.ok_btn:hover {
  background-color: rgba(0, 0, 0, 0.08);
}
.chevroncolor
{
  color: #cceaff;
}
.chevroncolor:hover
{
  color: #036bb4;
}
.errordiv {
  position: absolute;
  color: #f93154;
  font-size: 0.875rem;
  z-index: 1064;
  background: white;
  width: 100%;
}
