.practicetitle {
    font-size: 18px;
    font-weight: bold;
}

.overlay01 {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 111111;
}

.overlaycontent01 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    height: auto;
    min-height: 300px;
    background-color: #fff;
    color: #fff;
    margin: 220px auto;
}

.overlayinner {
    width: 100%;
    padding: 20px;
    color: var(--font-color);
}

.bluenewheading {
    font-size: 20px;
    font-weight: bold;
    color: #036bb4;
}

.lockiconcenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon150 {
    height: 140px;
}

.icon100 {
    height: 100px;
}

.icontxtsize {
    font-size: 14px;
}

.loaderbgpopup {
    position: fixed;
    top: 0px;
    right: 0px;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999999999999999;
}

.loaderdivpopup {
    border: 2px solid #ebeef0;
    border-radius: 50%;
    border-top: 2px solid #00a7a9;
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
    position: relative;
    margin: auto;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}