/* css my danish--- */


.login__container {
  /* width: 100%; */
  max-width: 100vw;
  overflow: hidden;
  height: 100vh;
  background: url(../images/homebg.jpg);
  background-repeat: no-repeat;
  background-origin: border-box;
  background-position: center;
  background-size: cover;
  
}
.login__logo {
  top: 30px;
  left: 30px;
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.183));
  width: 200px;
}
.pharmacy__logo{
    bottom: -130px;
    right: -130px;
    
    opacity: .1;
}
.login__heading > h1 {
  font-size: 3rem;
  color: white;
  filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.316));
}
.login__form{
    min-width: 400px;
background-color: rgba(255, 255, 255, 0.45);
box-shadow: 1px 1px 10px 2px rgba(0, 0, 0, 0.093);
padding: 10px;
border-radius: 10px;

/* min-height: 500px; */
}
.login__text{
    position: absolute;
    bottom: 10px;
    opacity: .2;
    left: 30px;
    pointer-events: none;
    color: white;
    font-size: 3rem;
    font-weight: bold;
}


/* bootstrap btn modificaiton */
.btn-primary{
    border: none;
    background-color:var(--primaryColor) ;
}
.btn-primary:hover{
    background-color: var(--primaryColorHovered);
}


.panel.card.shadow{
 border-radius: 10px; padding: 10px 5px;
 box-shadow:  32px 32px 65px #8b8b8b,
               -32px -32px 65px #ffffff;

}



#left-sidebar{
  /* background:red !important; */
}
#main-content{
  /* background: linear-gradient(to bottom right,white,var(--primaryColor)); */
/* background-color: var(--primaryLightColorThree); */
}